<template>
  <div class="question-form pb-16">
      <h2 class="font-bold text-3xl md:text-4xl mb-4">Tell us a little bit about your business's customer communications management.</h2>
      <p class="text-xl mb-4">This form was generated by {{ q.admin.firstname }} {{ q.admin.last }} from Ricoh Consulting Services. If you need any assistance regarding this form, feel free to forward your questions to {{ q.admin.email }}. For your security, this form will expire in 14 days.</p>
      <p class="text-xl mb-4">Please provide as much detail as possible. It is not necessary to fill in all of the data but the more details that you provide will allow us to perform a more thorough analysis.</p>
      <p class="text-xl mb-12">For areas that are not applicable, please enter a number 0 or leave blank</p>

      <section>
        <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
          <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">1</span></div>
          <h2>Organization Profile</h2>
        </div>
        <div class="group">
            <div class="question">
              <label>Organization Name</label>
              <input type="text" v-model="q.customerProfile.organizationName" />
            </div>

          <div class="question">
            <label>Contact Name</label>
            <input type="text" v-model="q.customerProfile.customerContact" />
          </div>

          <div class="question">
            <label>Street Address</label>
            <input type="text" v-model="q.customerProfile.address" />
          </div>

          <div class="question flex">
            <div class="w-1/2 pr-4">
              <label>City</label>
              <input type="text" v-model="q.customerProfile.city" />
            </div>
            <div class="w-1/2 pl-4">
              <label>State</label>
              <input type="text" v-model="q.customerProfile.state" />
            </div>

          </div>


          <div class="question row flex">
            <div class="w-1/2 pr-4">
              <label>Postal Code</label>
              <input type="text" v-model="q.customerProfile.postalcode" />
            </div>
            <div class="w-1/2 pl-4">
              <label>Country</label>
              <input type="text" v-model="q.customerProfile.country" />
            </div>

          </div>

        </div>

      </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">2</span></div>
        <h2>Organization Statistics</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>Total Quantity of Templates Developed Annually</label>
          <number-input precision="0" v-model="q.interview.X107.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Total Quantity of Template Changes Annually</label>
          <number-input precision="0" v-model="q.interview.X108.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Total Quantity of Page Images per Template</label>
          <number-input precision="0" v-model="q.interview.X109.final"></number-input>
        </div>

        <div class="question">
          <label>Total Quantity of Monthly Communications Processed thru Templates:</label>
          <div class="row flex">
            <div class="w-48 pr-4">
              <span class="text-lg text-gray-500">Monthly Print</span>
              <number-input precision="0" v-model="q.interview.X110.final"></number-input>
            </div>
            <div class="w-48 pl-4">
              <span class="text-lg text-gray-500">Monthly Email</span>
              <number-input precision="0" v-model="q.interview.X111.final"></number-input>
            </div>
          </div>
        </div>

        <div class="question">
          <label>Total Quantity of Monthly Impressions Produced on Digital Cut-Sheet Equipment:</label>
          <div class="row flex">
            <div class="w-48 pr-4">
              <span class="text-lg text-gray-500">Color</span>
              <number-input precision="0" v-model="q.interview.X118.final"></number-input>
            </div>
            <div class="w-48 pl-4">
              <span class="text-lg text-gray-500">Monochrome</span>
              <number-input precision="0" v-model="q.interview.X119.final"></number-input>
            </div>
          </div>
        </div>




      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">3</span></div>
        <h2>Employees <small class="text-base tracking-wide">  (Account for All Employees in the Following Categories)</small></h2>
      </div>
      <div class="group">
        <div class="question right-input">
          <label>Total # of Employees who Process Communications</label>
          <number-input precision="2" v-model="q.interview.X112.final"></number-input>
        </div>
        <div class="question right-input">
          <label>Print Communications Developers</label>
          <number-input precision="2" v-model="q.interview.X113.final"></number-input>
        </div>
        <div class="question right-input">
          <label>Email Communications Developers</label>
          <number-input precision="2" v-model="q.interview.X114.final"></number-input>
        </div>
        <div class="question right-input">
          <label>Automation or Processing Staff</label>
          <number-input precision="2" v-model="q.interview.X115.final"></number-input>
        </div>
        <div class="question right-input">
          <label>Administrative/Project Managers</label>
          <number-input precision="2" v-model="q.interview.X116.final"></number-input>
        </div>

      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">4</span></div>
        <h2>Software & Platforms</h2>
      </div>
      <div class="group">
        <div>
          <div class="question question-multi-choice">
            <label>Software</label>
            <assessment-interview-question-multi-choice :question="questions.X120" :answer="q.interview.X120"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>Platforms</label>
            <assessment-interview-question-multi-choice :question="questions.X121" :answer="q.interview.X121"></assessment-interview-question-multi-choice>
          </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Integration and Cloud Strategies</h4>
        <div class="question question-multi-choice">
          <label>Management views cloud platforms...</label>
          <assessment-interview-question-multi-choice :question="questions.X161" :answer="q.interview.X161"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>CCM cloud integration is viewed as...</label>
          <assessment-interview-question-multi-choice :question="questions.X162" :answer="q.interview.X162"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>Cloud platform integration is...</label>
          <assessment-interview-question-multi-choice :question="questions.X163" :answer="q.interview.X163"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Additional Integration and Cloud Strategy Comments</label>
          <textarea v-model="q.interview.X164.notes"/>
        </div>
      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Customer Journey</h2>
      </div>

      <div class="group">

        <h4 class=" mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Customer Journey Mapping</h4>

        <div>
        <div class="question question-multi-choice">
          <label>Customer Journey Mapping...</label>
          <assessment-interview-question-multi-choice :question="questions.X124" :answer="q.interview.X124"></assessment-interview-question-multi-choice>
        </div>

          <div class="question question-multi-choice" v-if="(q.interview.X124.notes === 'has been implemented')">
            <label>Customer Journey Mapping is processed with...</label>
            <assessment-interview-question-multi-choice :question="questions.X124A" :answer="q.interview.X124A"></assessment-interview-question-multi-choice>
          </div>

        <div class="question">
          <label>Additional Customer Journey Mapping Comments</label>
          <textarea v-model="q.interview.X164.notes"/>
        </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Preference Management</h4>
        <div>
          <div class="question question-multi-choice">
            <label>Is there a Preference Management database?</label>
            <assessment-interview-question-multi-choice :question="questions.X154" :answer="q.interview.X154"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X154.notes === 'Yes'">
            <label>If so, is it self-service through a customer portal? manually managed internally? Other?</label>
            <assessment-interview-question-multi-choice :question="questions.X155" :answer="q.interview.X155"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X154.notes === 'No'">
            <label>Is Preference Management planned to be implemented in the next 12 months?</label>
            <assessment-interview-question-multi-choice :question="questions.X156" :answer="q.interview.X156"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Preference Management Comments</label>
            <textarea v-model="q.interview.X157.notes"/>
          </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Campaign Management</h4>


        <div>
          <div class="question question-multi-choice">
            <label>How are campaigns tracked and triggered?</label>
            <assessment-interview-question-multi-choice :question="questions.X158" :answer="q.interview.X158"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>Is there capability to produce reports or dashboards based on campaign data?</label>
            <assessment-interview-question-multi-choice :question="questions.X159" :answer="q.interview.X159"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Campaign Management Comments</label>
            <textarea v-model="q.interview.X160.notes"/>
          </div>

        </div>

      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">6</span></div>
        <h2>Communication Development</h2>
      </div>
      <div class="group">

        <div>
        <div class="question question-multi-choice">
          <label>Communications are designed for...</label>
          <assessment-interview-question-multi-choice :question="questions.X126" :answer="q.interview.X126"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Print pieces are programmed with...</label>
          <assessment-interview-question-multi-choice :question="questions.X127" :answer="q.interview.X127"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Electronic pieces are programmed with...</label>
          <assessment-interview-question-multi-choice :question="questions.X128" :answer="q.interview.X128"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Please provide any detail on requirements to redesign your statements or rebrand in the near future.</label>
          <textarea v-model="q.interview.X129.notes"/>
        </div>

        <div class="question">
          <label>Provide information on your organization's sustainability goals, green initiatives or digital transformation.</label>
          <textarea v-model="q.interview.X130.notes"/>
        </div>

        <div class="question">
          <label>Additional Communications Development Comments</label>
          <textarea v-model="q.interview.X131.notes"/>
        </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Design Requests</h4>
        <div>

          <div class="question question-multi-choice">
            <label>Design Request Software</label>
            <assessment-interview-question-multi-choice :question="questions.X122" :answer="q.interview.X122"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>Prioritization and daily status are managed actively by management using:</label>
            <assessment-interview-question-multi-choice :question="questions.X123" :answer="q.interview.X123"></assessment-interview-question-multi-choice>
          </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Approval Processes</h4>
        <div>

          <div class="question question-multi-choice">
            <label>Approvals are managed via:</label>
            <assessment-interview-question-multi-choice :question="questions.X141" :answer="q.interview.X141"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Approval Processes Comments</label>
            <textarea v-model="q.interview.X142.notes"/>
          </div>
        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Business User Enablement</h4>
        <div>

          <div class="question question-multi-choice">
            <label>Ways for line-of-business user to directly modify content in communications...</label>
            <assessment-interview-question-multi-choice :question="questions.X138" :answer="q.interview.X138"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>The platform for Business User enablement is...</label>
            <assessment-interview-question-multi-choice :question="questions.X139" :answer="q.interview.X139"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Business User Enablement Comments</label>
            <textarea v-model="q.interview.X140.notes"/>
          </div>



        </div>



      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">7</span></div>
        <h2>Content and Data Management</h2>
      </div>
      <div class="group">
        <div class="question question-multi-choice">
          <label>Content is stored...</label>
          <assessment-interview-question-multi-choice :question="questions.X132" :answer="q.interview.X132"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice" v-if="q.interview.X132.notes === 'ECM System'">
          <label>ECM Software Platform is...</label>
          <assessment-interview-question-multi-choice :question="questions.X133" :answer="q.interview.X133"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Content is categorized, tagged, and organized via a consistent methodology.</label>
          <assessment-interview-question-multi-choice :question="questions.X134" :answer="q.interview.X134"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Data is stored in...</label>
          <assessment-interview-question-multi-choice :question="questions.X135" :answer="q.interview.X135"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Data is stored using...</label>
          <assessment-interview-question-multi-choice :question="questions.X136" :answer="q.interview.X136"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Additional Content and Data Management Comments</label>
          <textarea v-model="q.interview.X137.notes"/>
        </div>


      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">8</span></div>
        <h2>Communications Delivery</h2>
      </div>
      <div class="group">

        <h4 class=" mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Communications Output Processing and Delivery</h4>
        <div>

          <div class="question question-multi-choice">
            <label>Communications are produced for...</label>
            <assessment-interview-question-multi-choice :question="questions.X143" :answer="q.interview.X143"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>Production is managed via...</label>
            <assessment-interview-question-multi-choice :question="questions.X144" :answer="q.interview.X144"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice">
            <label>Communications are produced via...</label>
            <assessment-interview-question-multi-choice :question="questions.X145" :answer="q.interview.X145"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>How do you track SLA’s to ensure they are met? SLAs are tracked through...</label>
            <textarea v-model="q.interview.X168.notes"/>
          </div>

          <div class="question question-multi-choice">
            <label>Is your current outbound mail regulated?</label>
            <assessment-interview-question-multi-choice :question="questions.X169" :answer="q.interview.X169"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Requirements to track and report outbound mail from production completion through delivery include ...</label>
            <textarea v-model="q.interview.X170.notes"/>
          </div>

          <div class="question right-input">
            <label>The % of printed materials that contain Personal Health Information/HIPAA is...</label>
            <number-input precision="1" format="%" v-model="q.interview.X171.final"></number-input>
          </div>

          <div class="question">
            <label>Additional Communications Processing and Delivery Comments</label>
            <textarea v-model="q.interview.X146.notes"/>
          </div>

        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Communication Auditing/Tracking</h4>
        <div>

          <div class="question question-multi-choice">
            <label>End to end tracking system in place?</label>
            <assessment-interview-question-multi-choice :question="questions.X147" :answer="q.interview.X147"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X147.notes === 'Yes'">
            <label>If there is an end to end tracking system in place (Yes above), what is the system used for tracking?</label>
            <assessment-interview-question-multi-choice :question="questions.X148" :answer="q.interview.X148"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X147.notes !== 'Yes'">
            <label>There is no comprehensive tracking system in place. The tracking that is available is...</label>
            <assessment-interview-question-multi-choice :question="questions.X149" :answer="q.interview.X149"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Communication Auditing/Tracking Comments</label>
            <textarea v-model="q.interview.X149A.notes"/>
          </div>

        </div>

        <h4 class="mt-12 mb-4 font-bold text-2xl sub-header border-b-2 pb-1 border-black">Automation Processes and Platforms</h4>
        <div>

          <div class="question question-multi-choice">
            <label>Is workflow automation in place?</label>
            <assessment-interview-question-multi-choice :question="questions.X150" :answer="q.interview.X150"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X150.notes === 'Yes'">
            <label>The system that is used is:</label>
            <assessment-interview-question-multi-choice :question="questions.X151" :answer="q.interview.X151"></assessment-interview-question-multi-choice>
          </div>

          <div class="question question-multi-choice" v-if="q.interview.X150.notes === 'Yes'">
            <label>Is this workflow automation system used as a single platform for all automation?</label>
            <assessment-interview-question-multi-choice :question="questions.X152" :answer="q.interview.X152"></assessment-interview-question-multi-choice>
          </div>

          <div class="question">
            <label>Additional Automation Processes and Platforms Comments</label>
            <textarea v-model="q.interview.X153.notes"/>
          </div>

        </div>

      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">9</span></div>
        <h2>Disaster Recovery</h2>
      </div>
      <div class="group">

        <div class="question">
          <label>The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes...</label>
          <textarea v-model="q.interview.X165.notes"/>
        </div>

        <div class="question right-input">
          <label>The Disaster Recovery production site is located xx miles from your current operation.</label>
          <number-input precision="1" v-model="q.interview.X166.final"></number-input>
        </div>

        <div class="question">
          <label>Describe the SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster.</label>
          <textarea v-model="q.interview.X167.notes"/>
        </div>

      </div>
    </section>

    <div class="text-center py-12">
      <button class="text-xl font-bold button button-blue" @click="submitForm">SUBMIT QUESTIONNAIRE</button>
    </div>

  </div>
</template>

<script>


import ccm from "@/schema/ccm"
import NumberInput from "@/components/NumberInput";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";

export default {
  name: "question-form-ccm",
  components: {AssessmentInterviewQuestionMultiChoice, NumberInput},
  props: {},
  mounted: function(){
    this.$store.state.schema = ccm;
    this.$store.state.pageTitle = "Customer Communications Management"
  },
  data: function () {
    return {

    }
  },
  methods: {
    submitForm: function(){
      this.$emit("submit");

    },

    onChange: function(){

    }
  },
  computed: {
    q: function(){
      return this.$store.state.q;
    },

    questions: function(){
      return ccm.questions
    }
  }
}
</script>

<style scoped>


.question-form {

}

textarea {
  @apply px-2 py-1 border border-gray-500 w-full block rounded text-lg font-bold font-input;
}

.group {
  @apply bg-gray-100 p-8 shadow;
}

.group label {
  @apply text-xl mb-2 block;
}

.group .question-multi-choice label {
  @apply text-xl mb-0 block;
}

.question {
  @apply mb-4 pb-6 border-b;
}

.question:last-child {
  @apply mb-4 pb-0 border-none;
}


.row {

}

.right-input {
  @apply flex justify-between items-center;
}

.right-input label {
  @apply pr-4 mb-0;
}

.right-input input {
  @apply w-48;
}

.qcol3 {
  @apply flex -mx-4;
}

.qcol3 .qcol {
  @apply w-1/3 px-4;
}

.qcol3 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


.qcol2 {
  @apply flex;
}

.qcol2 .qcol {
  @apply mr-8;
}

.qcol2 .qcol input {
  @apply w-48;
}


.qcol2 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


</style>
