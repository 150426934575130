


<template>
  <div class="multi-choice text-lg">
    <p class="text-sm opacity-50" v-if="question.selectOne">(Select one)</p>
    <p class="text-sm opacity-50" v-else>(Select all that apply)</p>
      <multi-choice-input class="mt-2 p-4 border bg-white rounded" v-model="answer.notes" :choices="question.choices" @change="onChange" :select-one="question.selectOne"></multi-choice-input>
      <input type="text" v-model="answer.final" :placeholder="question.otherPlaceholder?question.otherPlaceholder:'Enter other items here...'" class="other mt-2 w-full block px-4 py-2 border bg-white rounded" v-if="hasOther" @change="onChange"/>
  </div>
</template>

<script>

import MultiChoiceInput from "@/components/MultiChoiceInput";
export default {
    name: "assessment-interview-question-multi-choice",
  components: {MultiChoiceInput},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onChange: function(e){
        //this.answer.answer.final = e;
        this.$emit("change", e);
        if(!this.hasOther){
          this.answer.final = "";
        }
      }
    },
    computed: {

      enabled: function(){
        if(this.question.enabled){
          return this.question.enabled[0]
        }

        return true;
      },

      hasOther: function(){
        if(this.question.allowOther){
          if(this.answer.notes.includes(this.question.choices[this.question.choices.length - 1])){
            console.log("Other value");
              return true;
          }
          console.log("Other value inactive");
        }

        return false;
      }



    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

.question[data-inactive="true"] .col-input {
  color: rgba(0,0,0,0.2);
}

.question[data-inactive="true"] .col-input::placeholder {
  color: rgba(0,0,0,0.2);
}

input.other {

}

</style>