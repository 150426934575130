<template>
  <div class="question-form pb-16">
      <h2 class="font-bold text-3xl md:text-4xl mb-4">Tell us a little bit about your business's Marketing Automation Platform.</h2>
      <p class="text-xl mb-4">This form was generated by {{ q.admin.firstname }} {{ q.admin.last }} from Ricoh Consulting Services. If you need any assistance regarding this form, feel free to forward your questions to {{ q.admin.email }}. For your security, this form will expire in 14 days.</p>
      <p class="text-xl mb-4">Please provide as much detail as possible. It is not necessary to fill in all of the data but the more details that you provide will allow us to perform a more thorough analysis.</p>
      <p class="text-xl mb-12">For areas that are not applicable, please enter a number 0 or leave blank</p>

      <section>
        <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
          <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">1</span></div>
          <h2>Organization Profile</h2>
        </div>
        <div class="group">
            <div class="question">
              <label>Organization Name</label>
              <input type="text" v-model="q.customerProfile.organizationName" />
            </div>

          <div class="question">
            <label>Contact Name</label>
            <input type="text" v-model="q.customerProfile.customerContact" />
          </div>

          <div class="question">
            <label>Street Address</label>
            <input type="text" v-model="q.customerProfile.address" />
          </div>

          <div class="question flex">
            <div class="w-1/2 pr-4">
              <label>City</label>
              <input type="text" v-model="q.customerProfile.city" />
            </div>
            <div class="w-1/2 pl-4">
              <label>State</label>
              <input type="text" v-model="q.customerProfile.state" />
            </div>

          </div>


          <div class="question row flex">
            <div class="w-1/2 pr-4">
              <label>Postal Code</label>
              <input type="text" v-model="q.customerProfile.postalcode" />
            </div>
            <div class="w-1/2 pl-4">
              <label>Country</label>
              <input type="text" v-model="q.customerProfile.country" />
            </div>

          </div>

        </div>

      </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">2</span></div>
        <h2>Organization Data</h2>
      </div>
      <div class="group">

        <div class="question question-multi-choice">
          <label>The marketing team’s goals and objectives are _____ aligned with the organizations strategic and revenue goals:</label>
          <assessment-interview-question-multi-choice :question="questions.X120" :answer="q.interview.X120"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Describe organizations marketing objectives: (i.e. Is the primary focus is on brand awareness, sales growth, revenue growth, or customer retention?):</label>
          <textarea v-model="q.interview.X121.notes"/>
        </div>

        <div class="question">
          <label>What are your sales revenue goals:</label>
          <textarea v-model="q.interview.X122.notes"/>
        </div>

        <div class="question">
          <label>Key performance indicators (KPIs):</label>
          <textarea v-model="q.interview.X123.notes"/>
        </div>

        <div class="question right-input">
          <label>Marketing Qualified Lead volume (MQL):</label>
          <number-input precision="0" v-model="q.interview.X124.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Sales Qualified Lead (SQL) Volume:</label>
          <number-input precision="0" v-model="q.interview.X125.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Cost per lead:</label>
          <number-input format="$"  precision="0" v-model="q.interview.X126.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Sold revenue attributable to Marketing Leads:</label>
          <number-input format="$"  precision="0" v-model="q.interview.X127.final"></number-input>
        </div>

      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">3</span></div>
        <h2>Software, Platforms and Integration</h2>
      </div>
      <div class="group">

        <div class="question question-multi-choice">
          <label>Creative and Publishing Software used:</label>
          <assessment-interview-question-multi-choice :question="questions.X221" :answer="q.interview.X221"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Project or Work Management Platforms used:</label>
          <assessment-interview-question-multi-choice :question="questions.X222" :answer="q.interview.X222"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Customer Relationship Management Platforms used:</label>
          <assessment-interview-question-multi-choice :question="questions.X224" :answer="q.interview.X224"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Digital Asset Management Platforms used:</label>
          <assessment-interview-question-multi-choice :question="questions.X225" :answer="q.interview.X225"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Sales Enablement or Marketing Asset Distribution Platforms used:</label>
          <assessment-interview-question-multi-choice :question="questions.X226" :answer="q.interview.X226"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Website Authoring and Content Management Platforms used:</label>
          <assessment-interview-question-multi-choice :question="questions.X227" :answer="q.interview.X227"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>The marketing automation platform is integrated with these platforms:</label>
          <textarea v-model="q.interview.X228.notes"/>
        </div>

        <div class="question">
          <label>The marketing automation platform provides integration via:</label>
          <assessment-interview-question-multi-choice :question="questions.X229" :answer="q.interview.X229"></assessment-interview-question-multi-choice>
        </div>

      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">4</span></div>
        <h2>Marketing Automation Platforms and Processes</h2>
      </div>
      <div class="group">

        <div class="question question-multi-choice">
          <label>Is the marketing automation platform an enterprise deployed solution?</label>
          <assessment-interview-question-multi-choice :question="questions.X3101" :answer="q.interview.X3101"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>The marketing automation platform was selected by:</label>
          <assessment-interview-question-multi-choice :question="questions.X3102" :answer="q.interview.X3102"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Marketing participated in the evaluation and selection of the platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3103" :answer="q.interview.X3103"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Does the platform provide the functionality that addresses marketing goals and targeting of primary lines of business and audiences?</label>
          <assessment-interview-question-multi-choice :question="questions.X3104" :answer="q.interview.X3104"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Are other platforms in use?</label>
          <assessment-interview-question-multi-choice :question="questions.X3106A" :answer="q.interview.X3106A"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3106A.notes === 'Yes'">
          <label>Other platforms in use:</label>
          <textarea v-model="q.interview.X3106.notes"/>
        </div>

        <div class="question" v-if="q.interview.X3106A.notes === 'Yes'">
          <label>They are used for:</label>
          <textarea v-model="q.interview.X3107.notes"/>
        </div>

        <div class="question" v-if="q.interview.X3106A.notes === 'Yes'">
          <label>They are used by:</label>
          <textarea v-model="q.interview.X3108.notes"/>
        </div>

        <div class="question right-input">
          <label>Annual contract cost for primary PMP:</label>
          <number-input precision="0" format="$" v-model="q.interview.X3109.final"></number-input>
        </div>

        <div class="question">
          <label>Primary platform is support by:</label>
          <assessment-interview-question-multi-choice :question="questions.X3110" :answer="q.interview.X3110"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Marketing teams and leaders satisfaction with MAP vendor support is...</label>
          <assessment-interview-question-multi-choice :question="questions.X3111" :answer="q.interview.X3111"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Would leaders recommend the platform to other marketers?</label>
          <assessment-interview-question-multi-choice :question="questions.X3112" :answer="q.interview.X3112"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>Is additional support available to build campaigns, review workflows, suggest changes/optimizations?</label>
          <assessment-interview-question-multi-choice :question="questions.X3113" :answer="q.interview.X3113"></assessment-interview-question-multi-choice>
        </div>

      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Adoption & Usage</h2>
      </div>
      <div class="group">

        <div class="question question-multi-choice">
          <label>The existing marketing automation platform is utilized by what portion of marketing teams within the organization?</label>
          <assessment-interview-question-multi-choice :question="questions.X3200" :answer="q.interview.X3200"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>The existing marketing automation platform is utilized by ...</label>
          <assessment-interview-question-multi-choice :question="questions.X3202" :answer="q.interview.X3202"></assessment-interview-question-multi-choice>
        </div>




        <label class="pb-4 font-bold">User types and counts (Including all levels i.e. Coordinator, Manager, Director, Sr Director, VP - can be partial headcount):</label>

          <div class="mb-8">
            <div class="question right-input">
              <label>Number of Campaign Employees</label>
              <number-input precision="2" v-model="q.interview.X3203.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Demand Gen Employees</label>
              <number-input precision="2" v-model="q.interview.X3204.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Content Creators</label>
              <number-input precision="2" v-model="q.interview.X3205.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Project/Program Employees</label>
              <number-input precision="2" v-model="q.interview.X3215.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Product/Line of Business Employees</label>
              <number-input precision="2" v-model="q.interview.X3216.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Sales Ops Employees</label>
              <number-input precision="2" v-model="q.interview.X3206.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Reporting Analysts</label>
              <number-input precision="2" v-model="q.interview.X3207.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Marketing Ops Employees</label>
              <number-input precision="2" v-model="q.interview.X3208.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Compliance Employees</label>
              <number-input precision="2" v-model="q.interview.X3220.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Senior Leaders (VP, SVP, CMO)</label>
              <number-input precision="2" v-model="q.interview.X3211.final" ></number-input>
            </div>
            <div class="question right-input">
              <label>Number of Other Employees</label>
              <number-input precision="2" v-model="q.interview.X3221.final" ></number-input>
            </div>

          </div>

        <div class="question">
          <label>Of these users and departments, who has primary responsibility for campaign development and execution?</label>
          <textarea v-model="q.interview.X3222.notes"/>
        </div>

        <div class="question">
          <label>If multiple platforms are used by different teams, document why?</label>
          <assessment-interview-question-multi-choice :question="questions.X3223" :answer="q.interview.X3223"></assessment-interview-question-multi-choice>
        </div>

        <div class="question">
          <label>If one platform cannot satisfy the requirements of all teams, explain why?</label>
          <textarea v-model="q.interview.X3224.notes"/>
        </div>

      </div>



    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">6</span></div>
        <h2>Customer Segmentation</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>Number of audiences or business unit segments managed within the MAP:</label>
          <number-input precision="0" v-model="q.interview.X3302.final"></number-input>
        </div>

        <div class="question question-multi-choice">
          <label>What criteria are important for your targeting?</label>
          <assessment-interview-question-multi-choice :question="questions.X3303" :answer="q.interview.X3303"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Are defined customer personas in use?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3310.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3310.final"/><label>NO</label>
          </div>
        </div>

        <div class="question">
          <label>Key characteristics based on your targeting are:</label>
          <textarea v-model="q.interview.X3311.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>How often are personas updated and reviewed?</label>
          <assessment-interview-question-multi-choice :question="questions.X3312" :answer="q.interview.X3312"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Do you utilize buyer journey mapping?</label>
          <assessment-interview-question-multi-choice :question="questions.X3313" :answer="q.interview.X3313"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3313.notes === 'Yes'">
          <label>Describe how journey mapping is done: (manually, automated, etc.):</label>
          <textarea v-model="q.interview.X3314.notes"/>
        </div>

        <div class="question" v-if="q.interview.X3313.notes === 'No'">
          <label>Why don't you utilize journey mapping?</label>
          <textarea v-model="q.interview.X3315.notes"/>
        </div>



        <div class="question question-multi-choice">
          <label>Do you have a process for processing and cleansing your prospect database?</label>
          <assessment-interview-question-multi-choice :question="questions.X3317" :answer="q.interview.X3317"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>How often is this data collected and processed?</label>
          <assessment-interview-question-multi-choice :question="questions.X3318" :answer="q.interview.X3318"></assessment-interview-question-multi-choice>
        </div>



      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">7</span></div>
        <h2>Campaign Execution</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>Number of campaigns managed within the MAP per year:</label>
          <number-input precision="0" v-model="q.interview.X3401.final"></number-input>
        </div>
        <div class="question right-input">
          <label># of Monthly Printed Direct Mailers</label>
          <number-input precision="0" v-model="q.interview.X109.final"></number-input>
        </div>
        <div class="question right-input">
          <label># of Monthly Digital Outbound Touches</label>
          <number-input precision="0" v-model="q.interview.X110.final"></number-input>
        </div>

        <div class="question question-multi-choice">
          <label>How are campaigns scheduled or timed?</label>
          <assessment-interview-question-multi-choice :question="questions.X3402" :answer="q.interview.X3402"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Percentage of campaigns that are managed in MAP:</label>
          <number-input precision="0" format="%"  v-model="q.interview.X3403.final"></number-input>
        </div>

        <div class="question question-multi-choice">
          <label>Types of campaigns that are managed using MAP:</label>
          <assessment-interview-question-multi-choice :question="questions.X3404" :answer="q.interview.X3404"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Tactics utilized in campaigns:</label>
          <assessment-interview-question-multi-choice :question="questions.X3406" :answer="q.interview.X3406"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Of the campaigns that are NOT managed in MAP, what are challenges or why not?</label>
          <assessment-interview-question-multi-choice :question="questions.X3409" :answer="q.interview.X3409"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Do campaigns include logic to change paths based on responses from prospective buyers?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3410.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3410.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Are nurture tracks available within your campaigns to move prospects through their buying journey?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3411.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3411.final"/><label>NO</label>
          </div>
        </div>

        <div class="question">
          <label>How is content utilized within campaigns?</label>
          <textarea v-model="q.interview.X3413.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>How often are personas updated and reviewed?</label>
          <assessment-interview-question-multi-choice :question="questions.X3312" :answer="q.interview.X3312"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>How is that content delivered within the campaign experience?</label>
          <assessment-interview-question-multi-choice :question="questions.X3414" :answer="q.interview.X3414"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Do you have sufficient marketing team resources to create this content?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3415.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3415.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Are you using outside resources to create this content?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3416.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3416.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Do your campaigns include A/B testing and tracking capabilities?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3418.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3418.final"/><label>NO</label>
          </div>
        </div>

        <div class="question">
          <label>How is tracking data utilized to improve results?</label>
          <textarea v-model="q.interview.X3419.notes"/>
        </div>

        <div class="question right-input">
          <label>Are campaign and sales enablement materials routed/linked with leads to enable easy follow-up by the sales rep?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3420.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3420.final"/><label>NO</label>
          </div>
        </div>

      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">8</span></div>
        <h2>Workflow Automation</h2>
      </div>
      <div class="group">



        <div class="question right-input">
          <label>Is the team using automated workflows and triggers to build campaigns that push prospects through the campaign journey?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3601.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3601.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Does the team automate repetitive campaign tasks (Follow-ups, Nurture campaigns, Drip campaigns, Lead routing)?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3602.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3602.final"/><label>NO</label>
          </div>
        </div>

        <div class="question">
          <label>How is content utilized within campaigns?</label>
          <textarea v-model="q.interview.X3603.notes"/>
        </div>

        <div class="question right-input">
          <label>Campaign Replication or Update - Can existing campaigns be easily replicated or updated with the platform?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3604.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3604.final"/><label>NO</label>
          </div>
        </div>


      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">9</span></div>
        <h2>Sales & Cross Functional Engagement</h2>
      </div>
      <div class="group">



        <div class="question right-input">
          <label>Are Sales leaders invested in the lead process and agree on the approach?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3501.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3501.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Are you working with the Sales Ops and Financial teams to collaborate on the lead process and attribution of sales to leads?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3502.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3502.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Are there regular meetings to review lead volume and status of lead conversion and sales results with front line sales managers, directors, VPs?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3503.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3503.final"/><label>NO</label>
          </div>
        </div>

        <div class="question question-multi-choice">
          <label>How often do you conduct lead reporting reviews with sales team?</label>
          <assessment-interview-question-multi-choice :question="questions.X3504" :answer="q.interview.X3504"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Has lead quality been validated by Marketing, Marketing Ops, Sales and Sales Ops?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3505.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3505.final"/><label>NO</label>
          </div>
        </div>

        <div class="question">
          <label>How are leads routed to individual sales teams and reps?</label>
          <textarea v-model="q.interview.X3506.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>How often is the sales rep and territory data updated?</label>
          <assessment-interview-question-multi-choice :question="questions.X3507" :answer="q.interview.X3507"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Are Sales teams actively opening and working leads?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3508.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3508.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Do sales reps and leaders have concerns about the quality or value of leads?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3509.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3509.final"/><label>NO</label>
          </div>
        </div>


      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">10</span></div>
        <h2>Performance, Reporting & Analytics</h2>
      </div>
      <div class="group">



        <div class="question question-multi-choice">
          <label>Are you achieving your lead goals and campaign KPIs?</label>
          <assessment-interview-question-multi-choice :question="questions.X3701" :answer="q.interview.X3701"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3701.notes === 'No'">
          <label>What are the challenges preventing you from achieving your lead goals and campaign KPIs?</label>
          <textarea v-model="q.interview.X3702.notes"/>
        </div>

        <div class="question right-input">
          <label>What are the current MQL to SQL conversion rates (percentage)?</label>
          <number-input precision="0" format="%" v-model="q.interview.X3703.final"></number-input>
        </div>

        <div class="question right-input">
          <label>What are the current MQL to SQL conversion rates (percentage)?</label>
          <number-input precision="0" format="%" v-model="q.interview.X3704.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Is sold revenue data being regularly or in real-time connected with marketing lead data?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3705.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3705.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Is there a consolidated data platform to aggregate the sales and marketing data?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3706.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3706.final"/><label>NO</label>
          </div>
        </div>

        <div class="question question-multi-choice">
          <label>How often are marketing lead reports and attributable sales reports published?</label>
          <assessment-interview-question-multi-choice :question="questions.X3707" :answer="q.interview.X3707"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>How often are lead reports reviewed with Sales leaders?</label>
          <assessment-interview-question-multi-choice :question="questions.X3708" :answer="q.interview.X3708"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Is marketing able to provide timely reports on directly attributable sales?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3709.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3709.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Is marketing able to provide timely reports on indirectly attributable sales?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3710.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3710.final"/><label>NO</label>
          </div>
        </div>

        <div class="question right-input">
          <label>Do you have cohort reporting for leads produced to view sales attributed to specific campaigns periods?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3711.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3711.final"/><label>NO</label>
          </div>
        </div>

        <div class="question question-multi-choice">
          <label>Is there an agreed ROI or ROAS calculation?</label>
          <assessment-interview-question-multi-choice :question="questions.X3712" :answer="q.interview.X3712"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3712.notes === 'Yes'">
          <label>Describe the ROI or ROAS calculation?</label>
          <textarea v-model="q.interview.X3713.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>What is the attribution approach?</label>
          <assessment-interview-question-multi-choice :question="questions.X3714" :answer="q.interview.X3714"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Is the attribution methodology accepted throughout the organization?</label>
          <assessment-interview-question-multi-choice :question="questions.X3715" :answer="q.interview.X3715"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3715.notes === 'No'">
          <label>Why isn't there a attribution methodology accepted throughout the organization?</label>
          <textarea v-model="q.interview.X3716.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>Long sales cycle attribution rules: What is the agreed time frame where indirectly attributable leads are credited to longer team sales?</label>
          <assessment-interview-question-multi-choice :question="questions.X3717" :answer="q.interview.X3717"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Is there a process for correlating direct mail delivery timing with digital touch points and response?</label>
          <assessment-interview-question-multi-choice :question="questions.X3718" :answer="q.interview.X3718"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3718.notes === 'Yes'">
          <label>Describe your process for correlating direct mail delivery timing with digital touch points and response?</label>
          <textarea v-model="q.interview.X3719.notes"/>
        </div>

        <div class="question">
          <label>Additional insights on attribution acquired from discussions:</label>
          <textarea v-model="q.interview.X3720.notes"/>
        </div>




      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">11</span></div>
        <h2>Team Skills, Training and Support</h2>
      </div>
      <div class="group">



        <div class="question question-multi-choice">
          <label>Does the campaign teams have the skills and expertise necessary to design and execute effective campaigns?</label>
          <assessment-interview-question-multi-choice :question="questions.X3801" :answer="q.interview.X3801"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3801.notes === 'No'">
          <label>Explain gaps and issues with the skills and expertise necessary to design and execute effective campaigns?</label>
          <textarea v-model="q.interview.X3802.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>Does the campaign team understand the platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3803" :answer="q.interview.X3803"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Is the team using industry best practices to drive performance?</label>
          <assessment-interview-question-multi-choice :question="questions.X3804" :answer="q.interview.X3804"></assessment-interview-question-multi-choice>
        </div>

        <div class="question question-multi-choice">
          <label>Does the team have the ability to leverage advanced features of the platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3805" :answer="q.interview.X3805"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="(q.interview.X3803.notes === 'No') || (q.interview.X3804.notes === 'No') || (q.interview.X3805.notes === 'No')">
          <label>Describe gaps or issues related to campaign teams not understanding the platform, using best practices, or leveraging the advanced features of the platform:</label>
          <textarea v-model="q.interview.X3806.notes"/>
        </div>

        <div class="question">
          <label>Identify training needs necessary to address gaps and issues:</label>
          <textarea v-model="q.interview.X3807.notes"/>
        </div>

        <div class="question question-multi-choice" >
          <label>Does the marketing operations team provide sufficient support to campaign team and other users to maximize effectiveness of the platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3808" :answer="q.interview.X3808"></assessment-interview-question-multi-choice>
        </div>

        <div class="question" v-if="q.interview.X3808.notes === 'No'">
          <label>Describe gaps or issues related to the marketing operations team not providing sufficient support to campaign team and other users to maximize effectiveness of the platform:</label>
          <textarea v-model="q.interview.X3809.notes"/>
        </div>

      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">12</span></div>
        <h2>Team Satisfaction with MAP</h2>
      </div>
      <div class="group">



        <div class="question question-multi-choice">
          <label>How satisfied are marketing teams with the marketing automation platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3901" :answer="q.interview.X3901"></assessment-interview-question-multi-choice>
        </div>

        <div class="question right-input">
          <label>Does the platform have the ability to address the specific requirements of the Campaign teams, their goals and the engagement and use of the leads by the Sales teams?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" :value="true" v-model="q.interview.X3902.final" /><label>YES</label>
            <input class="h-6" type="radio" :value="false" v-model="q.interview.X3902.final"/><label>NO</label>
          </div>
        </div>

        <div class="question question-multi-choice">
          <label>What concerns do users have with the existing platform?</label>
          <assessment-interview-question-multi-choice :question="questions.X3903" :answer="q.interview.X3903"></assessment-interview-question-multi-choice>
        </div>

      </div>

    </section>



    <div class="text-center py-12">
      <button class="text-xl font-bold button button-blue" @click="submitForm">SUBMIT QUESTIONNAIRE</button>
    </div>

  </div>
</template>

<script>

import marketingMap from "@/schema/marketingMap"
import NumberInput from "@/components/NumberInput";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";

export default {
  name: "question-form-map",
  components: {AssessmentInterviewQuestionMultiChoice, NumberInput},
  props: {},
  mounted: function(){
    this.$store.state.schema = marketingMap;
    this.$store.state.pageTitle = "Marketing Automation"
  },
  data: function () {
    return {

    }
  },
  methods: {
    submitForm: function(){
      this.$emit("submit");

    },

    onChange: function(){

    }
  },
  computed: {
    q: function(){
      return this.$store.state.q;
    },

    questions: function(){
      return marketingMap.questions
    }
  }
}
</script>

<style scoped>


.question-form {

}

textarea {
  @apply px-2 py-1 border border-gray-500 w-full block rounded text-lg font-bold font-input;
}

.group {
  @apply bg-gray-100 p-8 shadow;
}

.group label {
  @apply text-xl mb-2 block;
}

.group .question-multi-choice label {
  @apply text-xl mb-0 block;
}

.question {
  @apply mb-4 pb-6 border-b;
}

.question:last-child {
  @apply mb-4 pb-0 border-none;
}


.row {

}

.right-input {
  @apply flex justify-between items-center;
}

.right-input label {
  @apply pr-4 mb-0;
}

.right-input input {
  @apply w-48;
}

.qcol3 {
  @apply flex -mx-4;
}

.qcol3 .qcol {
  @apply w-1/3 px-4;
}

.qcol3 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


.qcol2 {
  @apply flex;
}

.qcol2 .qcol {
  @apply mr-8;
}

.qcol2 .qcol input {
  @apply w-48;
}


.qcol2 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


</style>
