<template>


      <div class="w-full max-w-2xl mx-auto mt-8 sm:mt-12 px-8 pb-24">



        <div>

          <h2 class="text-3xl sm:text-4xl sm:leading-snug font-bold mb-4">You have been invited to fill out a Customer Questionnaire.</h2>
          <p class="text-2xl leading-snug">Please enter the email address and access code you received from Ricoh Consulting Services.</p>

        </div>
        <form @submit.prevent="submitLogin" id="questions-panel">

          <section>


            <div class="bg-gray-100 p-8 rounded shadow my-8 sm:my-12">
              <div class="question pb-4 border-b">

                <input type="text" name="ueml" v-model="ueml" placeholder="Your Email" />
              </div>
              <div class="question pt-4">

                <input type="text" name="ucode" v-model="ucode" placeholder="Access Code"/>
              </div>

            </div>
          </section>

          <section class="mt-4">
            <p v-if="error" class="text-red-500 pb-2 text-center text-xl font-bold">{{ error }}</p>
            <input class="button button-blue w-full text-2xl uppercase font-bold" type="submit" name="submit" value="Begin Questionnaire">
          </section>
        </form>
      </div>

</template>

<script>
export default {
  name: "login",
  props: {},
  data: function () {
    return {
      ueml: undefined,
      ucode: undefined,
      error: "",
      q: undefined
    }
  },
  methods: {
    submitLogin: async function(){

      if(this.$route.params.recid){
        if(this.ueml && this.ucode){

          this.ueml = this.ueml.toLowerCase();

          const url = "/api/getRecord"
          const options = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(
                {
                  ucode: this.ucode,
                  ueml: this.ueml,
                  recId: this.$route.params.recid
                }
            )
          }

          console.log("authorizing");

          await fetch(url,options)
              .then(response => response.json())
              .then(data => {

                console.log("response",data);

                if(!data.data || data.error){
                  throw new Error("Unauthorized")
                }

                this.prepareQuestionnaire(data.data);
              })
              .catch((error) => {

                console.error("unuthorized", error);

              })

          return;


        }else{
          this.error = "Please fill in the email and access code sent to you."
          return;
        }
      }

      this.error = "Invalid credentials. Please contact your Ricoh representative."
    },
    prepareQuestionnaire: function(doc){
      console.log("prepare", doc);

      if(doc && doc.interview && doc.documentType === 'Production Workflow Questionnaire'){
        if(!doc.interview.V1000){
          doc.interview.V1000 = { final: "" }
          doc.interview.V1001 = { final: 0 }
          doc.interview.V1002 = { Current: 0 }
          doc.interview.V1004 = { Current: 0 }
          doc.interview.V1005 = { Current: 0 }
          doc.interview.V1006 = { Current: 0 }
          doc.interview.V1010 = { Current: 0 }
          doc.interview.V1011 = { Current: 0 }
        }
      }

      this.$store.state.q = doc;
    }
  },
  computed: {}
}
</script>

<style scoped>


.login {

}

</style>
