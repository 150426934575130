<template>
  <div class="question-form pb-16">
      <h2 class="font-bold text-3xl md:text-4xl mb-4">Tell us a little bit about your business's customer communications management.</h2>
      <p class="text-xl mb-4">This form was generated by {{ q.admin.firstname }} {{ q.admin.last }} from Ricoh Consulting Services. If you need any assistance regarding this form, feel free to forward your questions to {{ q.admin.email }}. For your security, this form will expire in 14 days.</p>
      <p class="text-xl mb-4">Please provide as much detail as possible. It is not necessary to fill in all of the data but the more details that you provide will allow us to perform a more thorough analysis.</p>
      <p class="text-xl mb-12">For areas that are not applicable, please enter a number 0 or leave blank</p>

      <section>
        <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
          <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">1</span></div>
          <h2>Organization Profile</h2>
        </div>
        <div class="group">
            <div class="question">
              <label>Organization Name</label>
              <input type="text" v-model="q.customerProfile.organizationName" />
            </div>

          <div class="question">
            <label>Contact Name</label>
            <input type="text" v-model="q.customerProfile.customerContact" />
          </div>

          <div class="question">
            <label>Street Address</label>
            <input type="text" v-model="q.customerProfile.address" />
          </div>

          <div class="question flex">
            <div class="w-1/2 pr-4">
              <label>City</label>
              <input type="text" v-model="q.customerProfile.city" />
            </div>
            <div class="w-1/2 pl-4">
              <label>State</label>
              <input type="text" v-model="q.customerProfile.state" />
            </div>

          </div>


          <div class="question row flex">
            <div class="w-1/2 pr-4">
              <label>Postal Code</label>
              <input type="text" v-model="q.customerProfile.postalcode" />
            </div>
            <div class="w-1/2 pl-4">
              <label>Country</label>
              <input type="text" v-model="q.customerProfile.country" />
            </div>

          </div>

        </div>

      </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">2</span></div>
        <h2>Organization Statistics</h2>
      </div>
      <div class="group">
        <div class="question right-input">
          <label>Annual Sales</label>
          <number-input format="$" precision="0" v-model="q.interview.I1001.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Total # of Employees</label>
          <number-input precision="2" v-model="q.interview.I1010.final"></number-input>
        </div>

        <div class="question right-input">
          <label>How many mailing permits are managed by the organization?</label>
          <number-input precision="0" v-model="q.interview.X3050.final"></number-input>
        </div>

      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">3</span></div>
        <h2>Production Volume</h2>
      </div>
      <div class="group">
        <div class="question right-input border-b pb-6 mb-4">
          <label>Total Number of Mail Pieces per Year</label>
          <number-input precision="0" v-model="q.interview.I1003.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>What is the volume of b/w 8.5 x 11 pages produced per year?</label>
          <number-input precision="0" v-model="q.interview.X3002B.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>What is the volume of color 8.5 x 11 pages produced per year?</label>
          <number-input precision="0" v-model="q.interview.X3002C.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>The total volume of envelopes processed annually?</label>
          <number-input precision="0" v-model="q.interview.X3065A.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>The total volume of flats processed annually?</label>
          <number-input precision="0" v-model="q.interview.X3065C.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>The total volume of cards processed annually?</label>
          <number-input precision="0" v-model="q.interview.X3065E.final"></number-input>
        </div>
        <div class="question right-input border-b pb-6 mb-4">
          <label>The total volume of parcels/packages processed annually?</label>
          <number-input precision="0" v-model="q.interview.X3065G.final"></number-input>
        </div>
      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">4</span></div>
        <h2>Mail Stats</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>The value of postage processed by the organization is estimated at?</label>
          <number-input format="$" precision="0" v-model="q.interview.X3010.final"></number-input>
        </div>

        <div class="question question-multi-choice">
          <label>The classifications of mail include:</label>
          <assessment-interview-question-multi-choice :question="questions.X3011" :answer="q.interview.X3011"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>Is First Class Mail pre-sorted?</label>
          <assessment-interview-question-multi-choice :question="questions.X3012" :answer="q.interview.X3012"></assessment-interview-question-multi-choice>
        </div>
      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Workflow Processes</h2>
      </div>
      <div class="group">

        <div class="question">
          <label>Orders are submitted through...</label>
          <textarea v-model="q.interview.X3016.notes"/>
        </div>
        <div class="question">
          <label>Job tickets are developed by entering information received in...</label>
          <textarea v-model="q.interview.X3017.notes"/>
        </div>

        <div class="question question-multi-choice">
          <label>Are you running any address resolution services?</label>
          <assessment-interview-question-multi-choice :question="questions.X3025" :answer="q.interview.X3025"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>Address resolution is provided in the following frequency...</label>
          <assessment-interview-question-multi-choice :question="questions.X3026" :answer="q.interview.X3026"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>The following software is used to provide address resolution:</label>
          <assessment-interview-question-multi-choice :question="questions.X3027" :answer="q.interview.X3027"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>The file types that are processed include:</label>
          <assessment-interview-question-multi-choice :question="questions.X3028" :answer="q.interview.X3028"></assessment-interview-question-multi-choice>
        </div>
        <div class="question">
          <label>The system in place for customers to indicate their preference of communications includes:</label>
          <textarea v-model="q.interview.X3038.notes"/>
        </div>
        <div class="question question-multi-choice">
          <label>The following methods of communication can be selected by customers:</label>
          <assessment-interview-question-multi-choice :question="questions.X3039" :answer="q.interview.X3039"></assessment-interview-question-multi-choice>
        </div>
        <div class="question question-multi-choice">
          <label>Communication content that customers can opt in for include:</label>
          <assessment-interview-question-multi-choice :question="questions.X3040" :answer="q.interview.X3040"></assessment-interview-question-multi-choice>
        </div>
      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Returned Mail</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>What is the monthly volume (# of mail pieces) that are Returned Mail?</label>
          <number-input precision="0" v-model="q.interview.X3087.final"></number-input>
        </div>
      </div>
    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Production Operations</h2>
      </div>
      <div class="group">

        <div class="question">
          <label>List the sizes of envelopes used:</label>
          <textarea v-model="q.interview.X3067.notes"/>
        </div>
      </div>
    </section>


    <div class="text-center py-12">
      <button class="text-xl font-bold button button-blue" @click="submitForm">SUBMIT QUESTIONNAIRE</button>
    </div>

  </div>
</template>

<script>


import mail from "@/schema/mail"
import NumberInput from "@/components/NumberInput";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";

export default {
  name: "question-form-mail",
  components: {AssessmentInterviewQuestionMultiChoice, NumberInput},
  props: {},
  mounted: function(){
    this.$store.state.schema = mail;
    this.$store.state.pageTitle = "Outbound Mail Workflow"
  },
  data: function () {
    return {

    }
  },
  methods: {
    submitForm: function(){
      this.$emit("submit");

    },

    onChange: function(){

    }
  },
  computed: {
    q: function(){
      return this.$store.state.q;
    },

    questions: function(){
      return mail.questions
    }
  }
}
</script>

<style scoped>


.question-form {

}

textarea {
  @apply px-2 py-1 border border-gray-500 w-full block rounded text-lg font-bold font-input;
}

.group {
  @apply bg-gray-100 p-8 shadow;
}

.group label {
  @apply text-xl mb-2 block;
}

.group .question-multi-choice label {
  @apply text-xl mb-0 block;
}

.question {
  @apply mb-4 pb-6 border-b;
}

.question:last-child {
  @apply mb-4 pb-0 border-none;
}


.row {

}

.right-input {
  @apply flex justify-between items-center;
}

.right-input label {
  @apply pr-4 mb-0;
}

.right-input input {
  @apply w-48;
}

.qcol3 {
  @apply flex -mx-4;
}

.qcol3 .qcol {
  @apply w-1/3 px-4;
}

.qcol3 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


.qcol2 {
  @apply flex;
}

.qcol2 .qcol {
  @apply mr-8;
}

.qcol2 .qcol input {
  @apply w-48;
}


.qcol2 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


</style>
