export default {
    "groups":{
        "Organization Data":{ "label":"Organization Data", "id":"org","questions":["X120","X121","X122","X123","X124","X125","X126","X127"]},
        "Software, Platforms and Integration":{ "label":"Software, Platforms and Integration", "id":"software","questions":["X221","X222","X223","X224","X225","X226","X227","X228","X229"]},
        "Marketing Automation Platforms and Processes":{ "label":"Marketing Automation Platforms and Processes", "id":"platform","questions":["X3101","X3102","X3103","X3104","X3106A","X3106","X3107","X3108","X3109","X3110","X3111","X3112","X3113"]},
        "Adoption & Usage":{ "label":"Adoption & Usage", "id":"adoption","questions":["X3200"]},
        "Team Members":{ "label":"Team members using the MAP platform", "id":"team","questions":["X3202","X3202A","X3203","X3204","X3205","X3215","X3216","X3206","X3207","X3208","X3220","X3211","X3221","X3225","X3222","X3223","X3224"]},
        "Customer Segmentation":{ "label":"Customer Segmentation", "id":"segmentation","questions":["X3302","X3303","X3310","X3311","X3312","X3313","X3314","X3315","X3317","X3318"]},
        "Campaign Execution":{ "label":"Campaign Execution", "id":"campaigns","questions":["X3401","X109","X110","X3402","X3403","X3404","X3406","X3409","X3410","X3411","X3413","X3414","X3415","X3416","X3418","X3419","X3420"]},
        "Sales & Cross Functional Engagement":{ "label":"Sales & Cross Functional Engagement", "id":"sales","questions":["X3501","X3502","X3503","X3504","X3505","X3506","X3507","X3508","X3509"]},
        "Workflow Automation":{ "label":"Workflow Automation", "id":"workflow","questions":["X3601","X3602","X3603","X3604"]},
        "Performance":{ "label":"Performance", "id":"performance","questions":["X3701","X3702","X3703","X3704","X3705","X3706"]},
        "Reporting":{ "label":"Reporting", "id":"reporting","questions":["X3707","X3708","X3709","X3710","X3711"]},
        "Analytics":{ "label":"Analytics", "id":"analytics","questions":["X3712","X3713","X3714","X3715","X3716","X3717","X3718","X3719","X3720"]},
        "Team Skills, Training and Support":{ "label":"Team Skills, Training and Support", "id":"support","questions":["X3801","X3802","X3803","X3804","X3805","X3806","X3807","X3808","X3809"]},
        "Team Satisfaction with MAP":{ "label":"Team Satisfaction with MAP", "id":"satisfaction","questions":["X3901","X3902","X3903"]},

    },

    "stages":{
        "Organization Data":{ "id": "org", "markerLabel": "Organization<br>Data", "label": "Organization Data", "sections":["org"],
            "instructions": "Please fill out the forms below from information provided by the Customer."
        },
        "Platform":{ "id": "platform", "markerLabel": "Platform", "label": "Platform", "sections":["software","platform"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Adoption & Usage":{ "id": "usage", "markerLabel": "Adoption & Usage", "label": "Adoption & Usage", "sections":["adoption","team"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Targeting & Campaign Execution":{ "id": "targeting", "markerLabel": "Targeting &<br> Campaign Execution", "label": "Targeting & Campaign Execution", "sections":["segmentation","campaigns","workflow"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Sales & Cross Functional Engagement":{ "id": "sales", "markerLabel": "Sales & Cross <br>Functional Engagement", "label": "Sales & Cross Functional Engagement", "sections":["sales"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Performance, Reporting & Analytics":{ "id": "reports", "markerLabel": "Performance, <br>Reporting & Analytics", "label": "Performance, Reporting & Analytics", "sections":["performance","reporting","analytics"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Support & Satisfaction":{ "id": "team", "markerLabel": "Support & <br>Satisfaction", "label": "Support & Satisfaction", "sections":["support","satisfaction"], "instructions": "Please fill out the forms below from information provided by the Customer."}
    },


    "questions": {

        "X107":{
            "id": "X107",
            "num": 1,
            "type":2,
            "question": "# of Marketing Campaigns Created Annually",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X108":{
            "id": "X108",
            "num": 1,
            "type":2,
            "question": "# of Marketing Campaigns Updated Annually",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X109":{
            "id": "X109",
            "num": 1,
            "type":2,
            "question": "# of Monthly Printed Direct Mailers",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X110":{
            "id": "X110",
            "num": 1,
            "type":2,
            "question": "# of Monthly  Digital Outbound Touches ",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X111":{
            "id": "X111",
            "num": 1,
            "type":7,
            "question": "# of Employees who contribute to the creative process",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X112":{
            "id": "X112",
            "num": 1,
            "type":2,
            "question": "Marketing Managers:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X113":{
            "id": "X113",
            "num": 1,
            "type":2,
            "question": "Campaign and Program Managers:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X114":{
            "id": "X114",
            "num": 1,
            "type":2,
            "question": "Content Creators and SMEs",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X115":{
            "id": "X115",
            "num": 1,
            "type":2,
            "question": "Sales Teams:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X116":{
            "id": "X116",
            "num": 1,
            "type":2,
            "question": "Marketing Operations:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X117":{
            "id": "X117",
            "num": 1,
            "type":2,
            "question": "IT Support Staff:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X118":{
            "id": "X118",
            "num": 1,
            "type":2,
            "question": "Analytics and BI Teams:",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X119":{
            "id": "X119",
            "num": 10,
            "type":2,
            "question": "Total # of Employees who contribute to the creative process",
            "caption": "#",
            "dataFormat":"0.0",
            "hint": null,
            "cols":[],
            "defaults":[],
            "enabled":[false],
            "calc":{"final":"$a + $b + $c + $d + $e + $f + $g"},
            "calcVars":{"a":"X112.final","b":"X113.final","c":"X114.final","d":"X115.final","e":"X116.final","f":"X117.final","g":"X118.final"}
        },
        "X120":{
            "id": "X120",
            "num": 1,
            "type":5,
            "question": "The marketing team’s goals and objectives are _____ aligned with the organizations strategic and revenue goals:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":["completely","well","somewhat","not"]
        },
        "X121": {
            "id": "X121",
            "num": 48,
            "question": "Marketing objectives: (i.e. Is the primary focus is on brand awareness, sales growth, revenue growth, or customer retention?): ",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X122": {
            "id": "X122",
            "num": 48,
            "question": "What are your sales revenue goals:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X123": {
            "id": "X123",
            "num": 48,
            "question": "Key performance indicators (KPIs):",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X124":{
            "id": "X124",
            "num": 3,
            "type":2,
            "question": "Marketing Qualified Lead volume (MQL):",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X125":{
            "id": "X125",
            "num": 3,
            "type":2,
            "question": "Sales Qualified Lead (SQL) Volume:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X126":{
            "id": "X126",
            "num": 1,
            "type":2,
            "question": "Cost per lead:",
            "caption": "$",
            "dataFormat":"$",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X127":{
            "id": "X127",
            "num": 1,
            "type":2,
            "question": "Sold revenue attributable to Marketing Leads:",
            "caption": "$",
            "dataFormat":"$",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X221":{
            "id": "X221",
            "num": 1,
            "type":8,
            "question": "Creative and Publishing Software:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":["Adobe Creative Cloud","AutoDesk","CorelDRAW","Final Cut Pro X","Microsoft Office 365","Other"]
        },
        "X222":{
            "id": "X222",
            "num": 1,
            "type":8,
            "question": "Project or Work Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Workfront",
                "Asana",
                "Atlassian Jira/Jira Align/Confluence",
                "Hive",
                "Jira",
                "Monday.com",
                "Slack",
                "Smartsheet",
                "Teams",
                "Wrike",
                "Other",
            ]
        },
        "X223":{
            "id": "X223",
            "num": 1,
            "type":8,
            "question": "Marketing Automation Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Marketo Engage",
                "Hubspot",
                "Mailchimp",
                "Oracle Eloqua",
                "Salesforce MC",
                "Salesforce Pardot",
                "Other",
            ]
        },
        "X224":{
            "id": "X224",
            "num": 1,
            "type":8,
            "question": "Customer Relationship Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Experience Cloud",
                "Freshworks CRM",
                "Hubspot",
                "MS Dynamics 365",
                "Oracle CRM",
                "Pega CRM",
                "SugarCRM",
                "Salesforce",
                "SAP CX",
                "Zoho CRM",
                "Other",
            ]
        },
        "X225":{
            "id": "X225",
            "num": 1,
            "type":8,
            "question": "Digital Asset Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Experience Manager Assets",
                "Aprimo",
                "Brandfolder",
                "Bynder",
                "Canto",
                "Cloudinary",
                "MediaBeacon",
                "Nuxeo",
                "OpenText Media Management",
                "Widen Collective",
                "Other"
            ]
        },
        "X226":{
            "id": "X226",
            "num": 1,
            "type":8,
            "question": "Sales Enablement or Marketing Asset Distribution Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Aprimo",
                "Brainshark",
                "Brandfolder",
                "Highspot",
                "MarcomCentral",
                "Outreach",
                "Salesforce Sales Cloud",
                "Seismic",
                "Showpad",
                "Other",
            ]
        },
        "X227":{
            "id": "X227",
            "num": 1,
            "type":8,
            "question": "Website Authoring and Content Management",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Commerce",
                "Drupal",
                "HubSpot CMS",
                "Shopify",
                "Sitecore",
                "Wordpress",
                "Other",
            ]
        },
        "X228":{
            "id": "X228",
            "num": 1,
            "type":3,
            "question": "The marketing automation platform is integrated with these platforms:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X229":{
            "id": "X229",
            "num": 1,
            "type":8,
            "question": "The marketing automation platform provides integration via ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "API",
                "Middleware",
                "Custom Coding",
                "Built-in Integration",]
        },

        "X3100":{
            "id": "X3100",
            "num": 1,
            "type":8,
            "question": "The existing primary marketing automation platform is:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Marketo Engage",
                "Hubspot",
                "Mailchimp",
                "Oracle Eloqua",
                "Salesforce MC",
                "Salesforce Pardot",
                "Other",
            ]
        },
        "X3101":{
            "id": "X3101",
            "num": 1,
            "type":5,
            "question": "Is the marketing automation platform an enterprise deployed solution?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3102":{
            "id": "X3102",
            "num": 1,
            "type":8,
            "question": "Selected by:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Marketing",
                "IT",
                "PMO",
                "Business Ops",
                "Finance",
            ]
        },
        "X3103":{
            "id": "X3103",
            "num": 1,
            "type":5,
            "question": "Marketing participated in the evaluation and selection of the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3104":{
            "id": "X3104",
            "num": 1,
            "type":5,
            "question": "Does the platform provide the functionality that addresses marketing goals and targeting of primary lines of business and audiences?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3106A": {
            "id": "X3106A",
            "num": 48,
            "question": "Are other platforms in use?",
            "type": 5,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": [],
            "selectOne": true,
            "choices": [
                "Yes",
                "No"
            ]
        },
        "X3106": {
            "id": "X3106",
            "num": 48,
            "question": "Other platforms in use:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3107": {
            "id": "X3107",
            "num": 48,
            "question": "They are used for:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3108": {
            "id": "X3108",
            "num": 48,
            "question": "They are used by:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3109":{
            "id": "X3109",
            "num": 1,
            "type":2,
            "question": "Annual contract cost for primary PMP:",
            "caption": "$",
            "dataFormat":"$",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X3110":{
            "id": "X3110",
            "num": 1,
            "type":8,
            "question": "Primary platform is support by:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Internal Marketing Ops",
                "IT",
                "Vendor",
                "External Consultants",
            ]
        },
        "X3111":{
            "id": "X3111",
            "num": 1,
            "type":5,
            "question": "Marketing teams and leaders satisfaction with MAP vendor support...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "extremely satisfied",
                "very satisfied",
                "satisfied",
                "not satisfied",
            ]
        },
        "X3112":{
            "id": "X3112",
            "num": 1,
            "type":5,
            "question": "Would leaders recommend the platform to other marketers?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3113":{
            "id": "X3113",
            "num": 1,
            "type":5,
            "question": "Is additional support available to build campaigns, review workflows, suggest changes/optimizations?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3200":{
            "id": "X3200",
            "num": 1,
            "type":5,
            "question": "The existing marketing automation platform is utilized by what portion of marketing teams within the organization?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "all",
                "some",
                "specific",
                "none"
            ]
        },
        "X3202":{
            "id": "X3202",
            "num": 1,
            "type":8,
            "question": "The existing marketing automation platform is utilized by ...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "no external teams",
                "contractors",
                "agencies",
                "freelancers",
                "consultants",
            ]
        },
        "X3202A":{
            "id": "X3202A",
            "num": 23,
            "type":7,
            "question": "User types and counts (Including all levels i.e. Coordinator, Manager, Director, Sr Director, VP - can be partial headcount): ",
            "caption": "",
            "hint": "",
            "cols":[],
            "defaults":[],
        },
        "X3203":{
            "id": "X3203",
            "num":29 ,
            "type":2,
            "question": "Campaign:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3204":{
            "id": "X3204",
            "num":29 ,
            "type":2,
            "question": "Demand Gen:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3205":{
            "id": "X3205",
            "num":29 ,
            "type":2,
            "question": "Content Creator:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3206":{
            "id": "X3206",
            "num":29 ,
            "type":2,
            "question": "Sales Ops",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3207":{
            "id": "X3207",
            "num":29 ,
            "type":2,
            "question": "Reporting Analyst",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3208":{
            "id": "X3208",
            "num":29 ,
            "type":2,
            "question": "Marketing Ops",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3211":{
            "id": "X3211",
            "num":29 ,
            "type":2,
            "question": "Sr Leader (VP, SVP, CMO):",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3213":{
            "id": "X3213",
            "num": 23,
            "type":7,
            "question": "User counts by department:",
            "caption": "",
            "hint": "",
            "cols":[],
            "defaults":[],
        },
        "X3214":{
            "id": "X3214",
            "num":29 ,
            "type":2,
            "question": "Marketing:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3215":{
            "id": "X3215",
            "num":29 ,
            "type":2,
            "question": "Project/Program:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3216":{
            "id": "X3216",
            "num":29 ,
            "type":2,
            "question": "Product/Line of Business:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3217":{
            "id": "X3217",
            "num":29 ,
            "type":2,
            "question": "Sales:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3218":{
            "id": "X3218",
            "num":29 ,
            "type":2,
            "question": "Sales Ops:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3219":{
            "id": "X3219",
            "num":29 ,
            "type":2,
            "question": "Legal:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3220":{
            "id": "X3220",
            "num":29 ,
            "type":2,
            "question": "Compliance:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3221":{
            "id": "X3221",
            "num":29 ,
            "type":2,
            "question": "Other:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },

        "X3222": {
            "id": "X3222",
            "num": 92,
            "question": "Of these users and departments, who has primary responsibility for campaign development and execution? ",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3223": {
            "id": "X3223",
            "num": 92,
            "question": "If multiple platforms are used by different teams, document why?",
            "type": 8,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": [],
            "allowOther": true,
            "choices": [
                "M&A",
                "Consolidation of internal teams/divisions",
                "Targeting and automation requirements",
                "Other"
            ]
        },

        "X3224": {
            "id": "X3224",
            "num": 92,
            "question": "If one platform cannot satisfy the requirements of all teams, explain why?",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3225":{
            "id": "X3225",
            "num": 10,
            "type":2,
            "question": "Total team members utilizing the platform:",
            "caption": "#",
            "dataFormat":"0.0",
            "hint": null,
            "cols":[],
            "defaults":[],
            "enabled":[false],
            "calc":{"final":"$a + $b + $c + $d + $e + $f + $g"},
            "calcVars":{"a":"X3203.final","b":"X3204.final","c":"X3205.final","d":"X3206.final","e":"X3207.final","f":"X3208.final","g":"X3211.final"}
        },
        "X3226":{
            "id": "X3226",
            "num": 10,
            "type":2,
            "question": "Total user count",
            "caption": "#",
            "dataFormat":"0.0",
            "hint": null,
            "cols":[],
            "defaults":[],
            "enabled":[false],
            "calc":{"final":"$a + $b + $c + $d + $e + $f + $g + $h"},
            "calcVars":{"a":"X3214.final","b":"X3215.final","c":"X3216.final","d":"X3217.final","e":"X3218.final","f":"X3219.final","g":"X3220.final","h":"X3221.final"}
        },

        "X3302":{
            "id": "X3302",
            "num":29 ,
            "type":2,
            "question": "Number of audiences or business unit segments managed within the MAP: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3303":{
            "id": "X3303",
            "num": 1,
            "type":8,
            "question": "What criteria are important for your targeting?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Demographic: Age / gender / income level",
                "Behavioral: Purchase history / product preferences / cart abandonment",
                "Geographic: Country / region / city",
                "Firmographic: Industry / company size / revenue",
                "Org Level: Manager / Director / Sr Director / VP / Sr VP / EVP / C-Level",
                "Technographic: Solutions or products currently used / technology adoption rate",
                "Response: Interaction with website content / demo requests / webinar attendance",
            ]
        },
        "X3310": {
            "id": "X3310",
            "num": 91,
            "question": "Are defined customer personas  in use?",
            "type": 6,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3311": {
            "id": "X3311",
            "num": 92,
            "question": "Key characteristics based on your targeting are: ",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3312":{
            "id": "X3312",
            "num": 1,
            "type":5,
            "question": "How often are personas updated and reviewed?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Real-time",
                "Weekly",
                "Monthly",
                "Quarterly",
                "Semi-annually",
                "Annually",
                "Never"
            ]
        },
        "X3313": {
            "id": "X3313",
            "num": 91,
            "question": "Do you utilize buyer journey mapping?",
            "type": 5,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": [],
            "selectOne": true,
            "choices": [
                "Yes",
                "No"
            ]
        },

        "X3314": {
            "id": "X3314",
            "num": 91,
            "question": "Describe how journey mapping is done: (manually, automated, etc.):",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3315": {
            "id": "X3315",
            "num": 91,
            "question": "Why don't you utilize journey mapping?",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3317":{
            "id": "X3317",
            "num": 1,
            "type":8,
            "question": "Do you have a process for processing and cleansing your prospect database?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Return mail",
                "Undeliverable or bounced email",
                "Opt outs",
            ]
        },
        "X3318":{
            "id": "X3318",
            "num": 1,
            "type":5,
            "question": "How often is this data collected and processed?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "real-time",
                "daily",
                "weekly",
                "monthly",
                "quarterly",
                "annually",
                "never",
            ]
        },

        "X3401":{
            "id": "X3401",
            "num":29 ,
            "type":2,
            "question": "Number of campaigns managed within the MAP per year: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X3402":{
            "id": "X3402",
            "num": 1,
            "type":5,
            "question": "How are campaigns scheduled or timed?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne":true,
            "choices":[
                "Monthly",
                "Seasonally",
                "Promotion based",
                "Always on",
            ]
        },
        "X3403":{
            "id": "X3403",
            "num":29 ,
            "type":2,
            "question": "Percentage of campaigns that are managed in MAP:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3404":{
            "id": "X3404",
            "num": 1,
            "type":8,
            "question": "Types of campaigns that are managed using MAP:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Brand",
                "Lead Gen",
                "Drip",
                "Nurture",
                "Product",
                "Rep Activated",
                "Segment or Industry",
                "Other",
            ]
        },
        "X3406":{
            "id": "X3406",
            "num": 1,
            "type":8,
            "question": "Tactics utilized in campaigns:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Direct mail",
                "Print",
                "Email",
                "Digital ad",
                "Social organic / owned",
                "Social paid",
                "Video",
                "Other"
            ]
        },
        "X3409":{
            "id": "X3409",
            "num": 1,
            "type":8,
            "question": "Of the campaigns that are NOT managed in MAP, what are challenges or why not?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Campaign/project is too complex to manage in MAP",
                "MAP support team does not have time to build and implement workflow",
                "Other",
            ]
        },

        "X3410":{
            "id": "X3410",
            "num": 1,
            "type":6,
            "question": "Do campaigns include logic to change paths based on responses from prospective buyers?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3411":{
            "id": "X3411",
            "num": 1,
            "type":6,
            "question": "Are nurture tracks available within your campaigns to move prospects through their buying journey?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3413":{
            "id": "X3413",
            "num": 1,
            "type":3,
            "question": "How is content utilized within campaigns?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3414":{
            "id": "X3414",
            "num": 1,
            "type":8,
            "question": "How is that content delivered within the campaign experience?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "PDF",
                "Emails",
                "Web pages",
                "Landing pages",
                "Social media posts",
                "Video",
                "Other",
            ]
        },

        "X3415":{
            "id": "X3415",
            "num": 1,
            "type":6,
            "question": "Do you have sufficient marketing team resources to create this content?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3416":{
            "id": "X3416",
            "num": 1,
            "type":6,
            "question": "Are you using outside resources to create this content?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3418":{
            "id": "X3418",
            "num": 1,
            "type":6,
            "question": "Do your campaigns include A/B testing and tracking capabilities?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3419":{
            "id": "X3419",
            "num": 1,
            "type":3,
            "question": "How is tracking data utilized to improve results?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },



        "X3420":{
            "id": "X3420",
            "num": 1,
            "type":6,
            "question": "Are campaign and sales enablement materials routed/linked with leads to enable easy follow-up by the sales rep?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3501":{
            "id": "X3501",
            "num": 1,
            "type":6,
            "question": "Are Sales leaders invested in the lead process and agree on the approach?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3502":{
            "id": "X3502",
            "num": 1,
            "type":6,
            "question": "Are you working with the Sales Ops and Financial teams to collaborate on the lead process and attribution of sales to leads?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3503":{
            "id": "X3503",
            "num": 1,
            "type":6,
            "question": "Are there regular meetings to review lead volume and status of lead conversion and sales results with front line sales managers, directors, VPs?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3504":{
            "id": "X3504",
            "num": 1,
            "type":5,
            "question": "How often do you conduct lead reporting reviews with sales team?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "daily",
                "weekly",
                "monthly",
                "quarterly",
                "annually",
                "never",
            ]
        },
        "X3505":{
            "id": "X3505",
            "num": 1,
            "type":6,
            "question": "Has lead quality been validated by Marketing, Marketing Ops, Sales and Sales Ops?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3506":{
            "id": "X3506",
            "num": 1,
            "type":3,
            "question": "How are leads routed to individual sales teams and reps?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
        },

        "X3507":{
            "id": "X3507",
            "num": 1,
            "type":5,
            "question": "How often is the sales rep and territory data updated?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "daily",
                "weekly",
                "monthly",
                "quarterly",
                "annually",
                "never",
            ]
        },
        "X3508":{
            "id": "X3508",
            "num": 1,
            "type":6,
            "question": "Are Sales teams actively opening and working leads?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3509":{
            "id": "X3509",
            "num": 1,
            "type":6,
            "question": "Do sales reps and leaders have concerns about the quality or value of leads?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3601":{
            "id": "X3601",
            "num": 1,
            "type":6,
            "question": "Is the team using automated workflows and triggers to build campaigns that push prospects through the campaign journey?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3602":{
            "id": "X3602",
            "num": 1,
            "type":6,
            "question": "Does the team automate repetitive campaign tasks (Follow-ups, Nurture campaigns, Drip campaigns, Lead routing)?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3603":{
            "id": "X3603",
            "num": 1,
            "type":3,
            "question": "Describe how workflows are automated to deliver content based on triggers and conditions?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3604":{
            "id": "X3604",
            "num": 1,
            "type":6,
            "question": "Campaign Replication or Update - Can existing campaigns be easily replicated or updated with the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3701":{
            "id": "X3701",
            "num": 1,
            "type":5,
            "question": "Are you achieving your lead goals and campaign KPIs?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne":true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3702":{
            "id": "X3702",
            "num": 1,
            "type":3,
            "question": "What are the challenges preventing you from achieving your lead goals and campaign KPIs??",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3703":{
            "id": "X3703",
            "num":29 ,
            "type":2,
            "question": "What are the current MQL to SQL conversion rates (percentage)?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3704":{
            "id": "X3704",
            "num":29 ,
            "type":2,
            "question": "What is the current close ratio for leads converted to SQLs?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X3705":{
            "id": "X3705",
            "num": 1,
            "type":6,
            "question": "Is sold revenue data being regularly or in real-time connected with marketing lead data?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3706":{
            "id": "X3706",
            "num": 1,
            "type":6,
            "question": "Is there a consolidated data platform to aggregate the sales and marketing data?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3707":{
            "id": "X3707",
            "num": 1,
            "type":5,
            "question": "How often are marketing lead reports and attributable sales reports published? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne":true,
            "choices":[
                "daily",
                "weekly",
                "monthly",
                "quarterly",
                "annually",
                "never"
            ]
        },

        "X3708":{
            "id": "X3708",
            "num": 1,
            "type":5,
            "question": "How often are lead reports reviewed with Sales leaders? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "daily",
                "weekly",
                "monthly",
                "quarterly",
                "annually",
                "never"
            ]
        },

        "X3709":{
            "id": "X3709",
            "num": 1,
            "type":6,
            "question": "Is marketing able to provide timely reports on directly attributable sales?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },
        "X3710":{
            "id": "X3710",
            "num": 1,
            "type":6,
            "question": "Is marketing able to provide timely reports on indirectly attributable sales?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },
        "X3711":{
            "id": "X3711",
            "num": 1,
            "type":6,
            "question": "Do you have cohort reporting for leads produced to view sales attributed to specific campaigns periods?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3712":{
            "id": "X3712",
            "num": 1,
            "type":5,
            "question": "Is there an agreed ROI or ROAS calculation?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3713":{
            "id": "X3713",
            "num":29 ,
            "type":3,
            "question": "Describe the ROI or ROAS calculation?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3714":{
            "id": "X3714",
            "num": 1,
            "type":5,
            "question": "What is the attribution approach?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Last touch",
                "Multi-touch",
                "Full end to end",
            ]
        },

        "X3715":{
            "id": "X3715",
            "num": 1,
            "type":5,
            "question": "Is the attribution methodology accepted throughout the organization?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3716":{
            "id": "X3716",
            "num":29 ,
            "type":3,
            "question": "Why isn't there a attribution methodology accepted throughout the organization? ",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3717":{
            "id": "X3717",
            "num": 1,
            "type":5,
            "question": "Long sales cycle attribution rules: What is the agreed time frame where indirectly attributable leads are credited to longer team sales?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "selectOne": true,
            "choices":[
                "30",
                "60",
                "90",
                "120",
                "180",
                "Other",
            ]
        },

        "X3718":{
            "id": "X3718",
            "num": 1,
            "type":5,
            "question": "Is there a process for correlating direct mail delivery timing with digital touch points and response?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3719":{
            "id": "X3719",
            "num":29 ,
            "type":3,
            "question": "Describe your process for correlating direct mail delivery timing with digital touch points and response?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3720":{
            "id": "X3720",
            "num":29 ,
            "type":3,
            "question": "Additional insights on attribution acquired from discussions:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3801":{
            "id": "X3801",
            "num": 1,
            "type":5,
            "question": "Does the campaign teams have the skills and expertise necessary to design and execute effective campaigns?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3802":{
            "id": "X3802",
            "num":29 ,
            "type":3,
            "question": "Explain gaps and issues with the skills and expertise necessary to design and execute effective campaigns?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3803":{
            "id": "X3803",
            "num": 1,
            "type":5,
            "question": "Does the campaign team understand the platform?  ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },
        "X3804":{
            "id": "X3804",
            "num": 1,
            "type":5,
            "question": "Is the team using industry best practices to drive performance?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },
        "X3805":{
            "id": "X3805",
            "num": 1,
            "type":5,
            "question": "Does the team have the ability to leverage advanced features of the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3806":{
            "id": "X3806",
            "num":29 ,
            "type":3,
            "question": "Describe gaps or issues related to campaign teams not understanding the platform, using best practices, or leveraging the advanced features of the platform:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3807":{
            "id": "X3807",
            "num":29 ,
            "type":3,
            "question": "Identify training needs necessary to address gaps and issues:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3808":{
            "id": "X3808",
            "num": 1,
            "type":5,
            "question": "Does the marketing operations team provide sufficient support to campaign team and other users to maximize effectiveness of the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3809":{
            "id": "X3809",
            "num":29 ,
            "type":3,
            "question": "Describe gaps or issues related to the marketing operations team not providing sufficient support to campaign team and other users to maximize effectiveness of the platform:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3901":{
            "id": "X3901",
            "num": 1,
            "type":5,
            "question": "How satisfied are marketing teams with the marketing automation platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Extremely satisfied",
                "Very satisfied",
                "Satisfied",
                "Somewhat satisfied",
                "Not satisfied",
            ]
        },

        "X3902":{
            "id": "X3902",
            "num": 1,
            "type":6,
            "question": "Does the platform have the ability to address the specific requirements of the Campaign teams, their goals and the engagement and use of the leads by the Sales teams?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3903":{
            "id": "X3903",
            "num": 1,
            "type":8,
            "question": "What concerns do users have with the existing platform? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Too complex to use",
                "No support to build workflows",
                "Tool does not support how we work",
                "Lack of training",
            ]
        },





    },

    "survey":{
        "S001":{
            "id": "S001",
            "num": 1,
            "type":1,
            "weight": 10.0,
            "question": "Marketing Automation Implementation/Usage",
            "shortLabel": "Implementation/Usage",
            "description": "Is a marketing automation platform in use? - Score the use of the platform to deploy and manage all campaigns.",
            "caption": "CMO, Senior Marketing (VPs, Directors, Managers) in Marketing Ops, Marketing, Creative Ops, Brand, Product, Corporate Comms, etc.",
            "criteria5": "<b>Fully automated and all  teams are using the marketing automation platform.</b> All direct response and digital campaigns are managed using the platform and all teams are fully capable of creating and deploying campaigns. We are leveraging all features of our marketing automation platform. Our team is trained and proficient with the tool and we continue to optimize our efforts.",
            "criteria4": "<b>Fully automated campaigns are available, adoption is not complete.</b>  Due to capacity or training issues, some teams do not use the platform.  Or not all campaigns are managed through the marketing automation platform. Special or adhoc campaigns are not managed by the platform. We use a portion of the features of our MAP and know the tool is more powerful than this. We are making our way through newer features and starting to optimize the efforts we have put into place.",
            "criteria3": "<b>Some automated campaign capabilities exist using the in-place platform.</b>  Primary campaigns are managed using the platform. Industry or segments are not yet managed.  Additional campaign complexity and sophistication is yet to be added.  We have marketing automation but mostly use it to execute one-off email campaigns. We know the tool is more powerful than this but we don't have the time, skillset or resources to leverage it.",
            "criteria2": "<b>Standalone email campaign platform (ie. Mailchimp) is used but integrated campaigns cannot be deployed.</b> Marketing has not been able to implement a complete marketing automation platform due to budget or resource constraints.",
            "criteria1": "<b>No marketing automation platform</b> in place and your campaigns are managed manually using spreadsheets, shared folders and email.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S002":{
            "id": "S002",
            "num": 2,
            "type":1,
            "weight": 5.0,
            "question": "Personas and Journey Mapping",
            "description": "Are individual personas and journeys defined for all target industries, segments and decision maker personas?",
            "shortLabel": "Personas & Mapping",
            "caption": "Senior manager/directors noted above. Also, Campaign Managers and Coordinators",
            "criteria5": "<b>Robust decision maker personas and journeys</b> have been defined and are in use for campaign targeting and development.  Campaigns follow decision makers through their journey and continue to push them to the next stage. ",
            "criteria4": "<b>Some automation created around persona and journey strategy, still more to create and optimize.</b>",
            "criteria3": "<b>Personas and Journey well defined but not well integrated into communication strategy.</b>",
            "criteria2": "<b>Vaguely defined personas and journeys</b>",
            "criteria1": "<b>Do not know who decision makers are</b> and have not development personas and journeys. Personas and journeys not yet defined",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },

        "S003":{
            "id": "S003",
            "num": 3,
            "type":1,
            "weight": 10.0,
            "question": "Campaign Development and Deployment Efficiency and Effectiveness",
            "description": "How long does it take to create, develop and deploy a marketing campaigns?",
            "shortLabel": "Campaign Deployment",
            "caption": "Senior manager/directors noted above. Also, Campaign Managers and Coordinators, Project Managers",
            "criteria5": "<b>30 days</b>",
            "criteria4": "<b>60 days</b>",
            "criteria3": "<b>90 days</b>",
            "criteria2": "<b>6 months</b>",
            "criteria1": "<b>More than 6 months</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S004":{
            "id": "S004",
            "num": 4,
            "type":1,
            "weight": 10.0,
            "question": "Campaign Cycles and Retargeting / Nurturing",
            "description": " Are you applying consistent marketing campaign tactics across the entire duration of the marketing and sales funnel. Specific tactics and content for the top, middle and bottom of the funnel must be sewn together to cover the full buyer's journey. Do nurturing campaigns exist to engage contacts and move them down the buying journey?  Yes, multiple campaign paths, some, none?",
            "shortLabel":"Campaign Cycles",
            "caption": "Senior manager/directors noted above. Also, Campaign Managers and Coordinators",
            "criteria5": "<b>Always on and nurturing campaigns in use</b> to effectively target and engaged decision makers and move them towards a buying decision.  Personas and journey maps are used to create campaigns. Personalized, close to one-to-one, automated communication - timed to align with interest from contacts/accounts.",
            "criteria4": "<b>Always on campaigns are in use using personas and journey maps to guide campaign design.</b>  Retargeting is being used to engage non-responders.  Nurturing campaigns are in limited use,  Automated nurtures are in place and have begun optimization for enhanced effectiveness.",
            "criteria3": "<b>Annual campaigns are in use.</b> Campaigns have a set time frame for start and end.  New customer and existing customer campaigns are being deployed. Retargeting is in use for new customers. Some automated nurtures in place (ie. Welcome, re-engagement, logistical communications).",
            "criteria2": "<b>Monthly, Quarterly or Seasonal campaigns are in use.</b>  Campaigns have a set time frame for start and end. New customer campaigns are being deployed. Retargeting is in use for new customers. ",
            "criteria1": "<b>Only new customer acquisition campaigns are used.</b>  Retargeting and nurturing have not been implemented. Batch and blast (one-off email) campaign execution. Automation not yet configured. ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S005":{
            "id": "S005",
            "num": 5,
            "type":1,
            "weight": 5.0,
            "question": "Content Usage",
            "description": "Is marketing content utilized effectively within campaigns to encourage prospect engagement and collect first party data? ",
            "caption": "Senior manager/directors noted above. Also, Campaign and Content Managers and Coordinators ",
            "criteria5": "<b>Content is a key component</b> of the campaign design and is continually refreshed and updated based on industry and decision maker problems and needs.",
            "criteria4": "<b>Content is used in campaigns and is created or versioned</b> by industry and decision maker problems and needs. Refresh of content is periodically or quarterly.",
            "criteria3": "<b>Content is used throughout campaigns and is regularly updated.  No vertical or audience specific content is created.</b>",
            "criteria2": "<b>General content is used occasionally</b> in campaigns. Updates are not on a regular schedule.",
            "criteria1": "<b>No content is used in campaigns.</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S006":{
            "id": "S006",
            "num": 6,
            "type":1,
            "weight": 10.0,
            "question": "Sales Satisfaction with Marketing Leads",
            "description": "Do Marketing and Sales agree on the quality and value of the leads that come from campaigns? Is Sales Leadership committed to the lead process and use of leads to drive sales?  Is there full alignment to the Sales Process?",
            "shortLabel":"Sales Satisfaction",
            "caption": "Senior manager/directors noted above. Also, Sales leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders ",
            "criteria5": "<b>Marketing and Sales Leadership are committed to the lead process</b> and use of leads to drive sales.  Marketing leads are part of the entire sales process and Sales Reps convert and attribute sales to marketing campaigns. ",
            "criteria4": "<b>Sales understands the process but does not always adhere to it. Some grumble about marketing not providing enough value to Sales.</b> Marketing and Sales Leadership are actively managing and tracking leads but individual Sales team members are not fully engaged.  Lead quality and conversion is not part of the regular sales meeting process and mgr and rep review cadence",
            "criteria3": "<b>Marketing communicates efforts to Sales. Some reps are good about follow-up and understanding the value of the leads and contextual data Marketing is passing to them and others don't use it at all.</b> Marketing is actively managing and tracking lead quality based on conversation and sales but Sales is not fully engaged.  Lead quality and conversion is not part of the regular Marketing and Sales conversation.",
            "criteria2": "<b>Marketing has run a few sessions to educate sales on the contextual data provided to them but Sales hasn't been motivated to adjust their process.</b> Lead queues are backed up while Sales still complains they aren't getting good leads. Marketing is reporting on lead volumes and gross conversion rates without any input from Sales.  Sales leaders and teams are skeptical about results and are not working or converting leads.",
            "criteria1": "<b>Marketing runs campaigns and passes leads to Sales.</b>  No collaboration is in place to ensure lead quality or align processes. Leads often receive no follow-up.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S007":{
            "id": "S007",
            "num": 7,
            "type":1,
            "weight": 10.0,
            "question": "Sales Enablement",
            "description": "Is your marketing automation platform connected to your CRM and are leads routed to sales teams with campaign information and assets that inform the rep about the prospects interest?  Are MAP sales insight tools connected to the CRM?",
            "caption": "Senior manager/directors noted above. Also, Sales leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders. Would also be good to talk with end users about how those integrations help/hurt/don't matter.",
            "criteria5": "<b>The MAP and  CRM are fully integrated with campaign information and assets delivered to the rep with leads.</b>  Reps use campaign information and content assets to engage prospects and initiate an informed conversation based on where the buyer is on their journey.  Lead scoring is in use and prospect insights are provided by Marketing",
            "criteria4": "<b>MAP and CRM are integrated with little to no errors. Lead scoring and routing are configured and used by most of Sales.</b> Sales insight data is provided in CRM and Sales uses it sometimes.",
            "criteria3": "<b>Lead Scoring is configured and a threshold has been set to pass higher quality leads to Sales but Sales doesn't fully understand the infromation Marketing is providing and therefore doesn't use it well.</b>",
            "criteria2": "<b>An integration between MAP and CRM exists but has some issues. Sales isn't familiar with tools Marketing has provided them to enhance Sales.</b>",
            "criteria1": "<b>MAP and CRM are distinct systems.  Leads are delivered by email and tracked manually.</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S008":{
            "id": "S008",
            "num": 8,
            "type":1,
            "weight": 10.0,
            "question": "Reporting and Analytics",
            "description": "Are reports available on a consistent, timely (if not real time) basis vs manual reporting more than one week or one month after the close of a sales month?",
            "caption": "CMO, Marketing Ops Leaders, Sales  Leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders ",
            "criteria5": "<b>Marketing campaign results are available real-time with dashboards available to Marketing and Sales leaders.</b>  Additional insights and trends are also available on a regular basis.  Marketing teams can use reports to forecast end of month/qtr/year results and make adjustments to ensure goal attainment.  ",
            "criteria4": "<b>Reporting is automated and still evolving. Analysis is used in decision making.</b> Types of reports being reviewed need to evolve in order to be most useful.",
            "criteria3": "<b>Reporting is reviewed at a regular cadence. Some of the reporting is automated and some is manual.</b> High-level decisions are made taking this analysis into consideration.",
            "criteria2": "<b>Manual only reporting.</b>  Reports are delivered after the close of the current sales period or on an ad hoc basis.",
            "criteria1": "<b>No consistent reporting.</b>  ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S009":{
            "id": "S009",
            "num": 9,
            "type":1,
            "weight": 10.0,
            "question": "Strategic Use of Campaign ROI/Directly Attributable Sales",
            "description": "Does the organization utilize and rely on MAP  and Sales Data generated from the MAP and analytics platforms and insights to drive the growth of the business? Are marketing budgets connected to sales goals and results?",
            "shortLabel":"Campaign Attribution",
            "caption": "CMO, Marketing Ops Leaders, Sales  Leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders",
            "criteria5": "<b>Marketing provides a reliable view of directly and indirectly attributable sales revenue driven by marketing spend and tactic.</b>  Marketing and Sales leadership agree on the contribution from marketing campaigns. If you want to say it simpler “Marketing knows exactly where their spend is resulting in revenue.”",
            "criteria4": "<b>We have attribution reporting. It is not fully accepted by all areas of the business.</b> Marketing is using it to drive decisions. We need many more views of the data and different logic to be applied in order to fully understand marketing's impact on the org.",
            "criteria3": "<b>Marketing reports on campaigns on a rolled up basis (by campaign type, channel, etc).</b> Marketing managers adjust their campaign approach based on attribution.",
            "criteria2": "<b>We know we need to report on marketing's attribution to revenue but we can't agree on the best logic.</b> We have a form of attribution reporting but there is not alignment across the org.",
            "criteria1": "<b>Marketing only reports on campaign results.</b> No attribution of sales results back to marketing campaigns. ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S010":{
            "id": "S010",
            "num": 10,
            "type":1,
            "weight": 5.0,
            "question": "Marketing Operations (MOps) Support",
            "description": "Can the Marketing Ops team or IT partners maintain the operational availability of the MAP and address ongoing issues efficiently?",
            "shortLabel": "MOps Support",
            "caption": "All users with a focus on day-to-day users (coordinators, creatives, system admins, etc.)",
            "criteria5": "<b>MOps is fully capable of supporting all aspects of the MarTech stack and MAP.</p>  SLAs are in-place and availability of platforms supports business operations. MOps has SLAs defined with vendors.",
            "criteria4": "<b>MOps and IT partner to support the MarTech stack and MAP.</p>  SLAs are in-place for the major components of the stack.  MarTech stack and MAP is generally available with few outages. I don't think the struggle is \"outages\" as these systems tend to have very low down time.  I think it's are they able to make system and process changes easily and at the pace the business moves.",
            "criteria3": "<b>MOps has limited ability to provide support.</p>  IT provides the majority of the support required to maintain availability.  Some issues may not be resolved quickly and cause periodic business outages. Mops is overwhelmed with delayed response times. IT struggles to meet our needs.",
            "criteria2": "<b>Only IT is available to provide support and resolve issues.</p>  No committed SLA for problem resolution. Vendor support is required to resolve some issues.",
            "criteria1": "<b>No marketing ops team exists and Marketing has no support from IT.</p>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S011":{
            "id": "S011",
            "num": 11,
            "type":1,
            "weight":10.0,
            "question": "Contact and Client Data Health and Hygiene",
            "description": "Number of contacts w/o email addresses, limits on MAP database",
            "shortLabel": "Contact & Client Data",
            "caption": "CMO, Marketing Ops Leaders, Sales  Leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders ",
            "criteria5": "<b>MAP contact database is fully managed and monitored to ensure only quality contacts are included in campaigns.</b>  Total quantity of contacts is optimized to ensure MAP costs are minimized.  Contact databases are continually improved (enhanced and cleansed) through 1st and 3rd party data sources.",
            "criteria4": "<b>We have automated cleansing and some data append but there is more required to improve our data health and hygiene.</b>",
            "criteria3": "<b>We have some automated cleansing efforts. No appending and our cleansing logic is not regularly monitored.</b>",
            "criteria2": "<b>Data is cleansed prior to adding it to our systems but no automated cleansing or appending exists.</b>",
            "criteria1": "<b>MAP contact database is not managed.</b> Contacts are added to systems without cleansing and not revisited at any cadence.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S012":{
            "id": "S012",
            "num": 12,
            "type":1,
            "weight": 5.0,
            "question": "Integration with MarTech Stack",
            "description": "Project Mgmt, Creative and media agency systems, Web2Print and Print vendor systems, DAM, Website management, CRM, Digital ad platforms, ",
            "shortLabel": "MarTech Integration",
            "caption": "CMO, Marketing Ops Leaders, Sales  Leaders, Sales Ops Leaders, BI and Marketing Analytics Leaders ",
            "criteria5": "<b>Full integration with MarTech stack and other systems:</b> Project Mgmt, Creative and media agency systems, Web2Print and Print vendor systems, DAM, Website management, CRM, Digital ad platforms.",
            "criteria4": "<b>Full integration with MarTech stack only:</b> Project Mgmt, Creative and media agency systems, Website management, CRM, Digital ad platforms.",
            "criteria3": "<b>A few systems are integrated but we still have gaps in full integration.</b>",
            "criteria2": "<b>Marketing automation and CRM are integrated but nothing else.</b>",
            "criteria1": "<b>No integration with other systems.</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        }
    }
}















