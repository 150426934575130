export default {
    "groups":{
        "Organization Data":{ "label":"Organization Data", "id":"org","questions":["X107","X108","X109","X110","X120","X121","X122","X123"]},
        "Software, Platforms and Integration":{ "label":"Software, Platforms and Integration", "id":"software","questions":["X221","X222","X223","X224","X225","X226","X227","X228","X229"]},
        "Project Management Platform and Processes":{ "label":"Project Management Platform and Processes", "id":"platform","questions":["X3100","X3101","X3102","X3106A","X3106","X3107","X3108","X3109","X3110","X3111","X3112"]},
        "Adoption and usage: Internal and External Teams":{ "label":"Adoption and usage: Internal and External Teams", "id":"adoption","questions":["X3200","X3201","X3202","X3202A","X3204","X3205","X3206","X3207","X3216","X3208","X3209","X3219","X3220","X3210","X3211","X3222","X3218","X3221","X3225"]},
        "Project or Campaign Types and Complexity":{ "label":"Project or Campaign Types and Complexity", "id":"types","questions":["X3301","X3302","X3304","X3305","X3307","X3308"]},
        "Request and Intake":{ "label":"Request and Intake", "id":"intake","questions":["X3401","X3402","X3403","X3405","X3406","X3407","X3408","X3409","X3410"]},
        "Marketing Specific Workflow Support - Creative App Integration":{ "label":"Marketing Specific Workflow Support - Creative App Integration", "id":"integration","questions":["X3501","X3502","X3503","X3503A","X3504","X3505","X3506","X3507"]},
        "Requestor, Collaborator, Approver Enablement":{ "label":"Requestor, Collaborator, Approver Enablement", "id":"enablement","questions":["X3601","X3602","X3603","X3604","X3605","X3606"]},
        "Project Replication or Update":{ "label":"Project Replication or Update", "id":"replication","questions":["X3701"]},
        "Marketing and Creative Resource Availability and Management ":{ "label":"Marketing and Creative Resource Availability and Management ", "id":"resources","questions":["X3801","X3802","X3803","X3804"]},
        "Audit Tracking of Approvals by SMEs, Execs and for Regulatory Requirements":{ "label":"Audit Tracking of Approvals by SMEs, Execs and for Regulatory Requirements", "id":"regulatory","questions":["X3901","X3902"]},
        "Cost tracking for client or internal billing":{ "label":"Cost tracking for client or internal billing", "id":"billing","questions":["X31001"]},
        "Team Satisfaction with PMP":{ "label":"Team Satisfaction with PMP", "id":"satisfaction","questions":["X31101","X31103"]},

    },

    "stages":{
        "Organization Data":{ "id": "org", "markerLabel": "Organization<br>Data", "label": "Organization Data", "sections":["org"],
            "instructions": "Please fill out the forms below from information provided by the Customer."
        },
        "Platform":{ "id": "platform", "markerLabel": "Platform", "label": "Platform", "sections":["software","platform"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Adoption & Usage":{ "id": "usage", "markerLabel": "Adoption & Usage", "label": "Adoption & Usage", "sections":["adoption","types","intake"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Enablement & Support":{ "id": "support", "markerLabel": "Enablement & Support", "label": "Enablement & Support", "sections":["integration","enablement","replication","resources"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Tracking":{ "id": "tracking", "markerLabel": "Tracking", "label": "Tracking", "sections":["regulatory","billing"], "instructions": "Please fill out the forms below from information provided by the Customer."},
        "Team Satisfaction":{ "id": "team", "markerLabel": "Team Satisfaction", "label": "Support & Satisfaction", "sections":["satisfaction"], "instructions": "Please fill out the forms below from information provided by the Customer."}
    },


    "questions": {

        "X107":{
            "id": "X107",
            "num": 1,
            "type":2,
            "question": "# of Marketing Campaigns Created Annually",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X108":{
            "id": "X108",
            "num": 1,
            "type":2,
            "question": "# of Marketing Campaigns Updated Annually",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X109":{
            "id": "X109",
            "num": 1,
            "type":2,
            "question": "# of Monthly Printed Marketing Units from Assets",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X110":{
            "id": "X110",
            "num": 1,
            "type":2,
            "question": "# of Monthly Digital Units from Assets",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X111":{
            "id": "X111",
            "num": 1,
            "type":7,
            "question": "Enter total # of Employees who contribute to the creative process",
            "caption": "#",
            "dataFormat":"#",
            "hint": "",
            "cols":[],
            "defaults":[]
        },

        "X120":{
            "id": "X120",
            "num": 1,
            "type":5,
            "question": "The marketing team’s goals and objectives are _____ aligned with the organizations strategic and revenue goals:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":["completely","well","somewhat","not"]
        },
        "X121": {
            "id": "X121",
            "num": 48,
            "question": "Marketing objectives: (i.e. Is the primary focus is on brand awareness, sales growth, revenue growth, or customer retention?): ",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X122": {
            "id": "X122",
            "num": 48,
            "question": "What are your sales revenue goals:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X123": {
            "id": "X123",
            "num": 48,
            "question": "Key performance indicators (KPIs):",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X221":{
            "id": "X221",
            "num": 1,
            "type":8,
            "question": "Creative and Publishing Software:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":["Adobe Creative Cloud","AutoDesk","CorelDRAW","Final Cut Pro X","Microsoft Office 365","Other"]
        },
        "X222":{
            "id": "X222",
            "num": 1,
            "type":8,
            "question": "Project or Work Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Workfront",
                "Asana",
                "Atlassian Jira/Jira Align/Confluence",
                "Hive",
                "Jira",
                "Monday.com",
                "Slack",
                "Smartsheet",
                "Teams",
                "Wrike",
                "Other",
            ]
        },
        "X223":{
            "id": "X223",
            "num": 1,
            "type":8,
            "question": "Marketing Automation Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Marketo Engage",
                "Hubspot",
                "Mailchimp",
                "Oracle Eloqua",
                "Salesforce MC",
                "Salesforce Pardot",
                "Other",
            ]
        },
        "X224":{
            "id": "X224",
            "num": 1,
            "type":8,
            "question": "Customer Relationship Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Experience Cloud",
                "Freshworks CRM",
                "Hubspot",
                "MS Dynamics 365",
                "Oracle CRM",
                "Pega CRM",
                "SugarCRM",
                "Salesforce",
                "SAP CX",
                "Zoho CRM",
                "Other",
            ]
        },
        "X225":{
            "id": "X225",
            "num": 1,
            "type":8,
            "question": "Digital Asset Management Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Experience Manager Assets",
                "Aprimo",
                "Brandfolder",
                "Bynder",
                "Canto",
                "Cloudinary",
                "MediaBeacon",
                "Nuxeo",
                "OpenText Media Management",
                "Widen Collective",
                "Other"
            ]
        },
        "X226":{
            "id": "X226",
            "num": 1,
            "type":8,
            "question": "Sales Enablement or Marketing Asset Distribution Platforms",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Aprimo",
                "Brainshark",
                "Brandfolder",
                "Highspot",
                "MarcomCentral",
                "Outreach",
                "Salesforce Sales Cloud",
                "Seismic",
                "Showpad",
                "Other",
            ]
        },
        "X227":{
            "id": "X227",
            "num": 1,
            "type":8,
            "question": "Website Authoring and Content Management",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Commerce",
                "Drupal",
                "HubSpot CMS",
                "Shopify",
                "Sitecore",
                "Wordpress",
                "Other",
            ]
        },
        "X228":{
            "id": "X228",
            "num": 1,
            "type":3,
            "question": "The  project management platform is integrated with these platforms:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },
        "X229":{
            "id": "X229",
            "num": 1,
            "type":8,
            "question": "The project management platform provides integration via ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "API",
                "Middleware",
                "Custom Coding",
                "Built-in Integration",]
        },

        "X3100":{
            "id": "X3100",
            "num": 1,
            "type":8,
            "question": "The existing primary project management platform is:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Adobe Workfront",
                "Asana",
                "Atlassian Jira, Jira Align, Confluence",
                "Hive",
                "Jira",
                "Monday.com",
                "Robohead",
                "Slack",
                "Smartsheet",
                "Teams",
                "Wrike",
                "Other"
            ]
        },
        "X3101":{
            "id": "X3101",
            "num": 1,
            "type":5,
            "question": "Is the project management platform an enterprise deployed solution?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3102":{
            "id": "X3102",
            "num": 1,
            "type":8,
            "question": "The project management platform was selected by:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Marketing",
                "IT",
                "PMO",
                "Business Ops",
                "Finance",
            ]
        },
        "X3103":{
            "id": "X3103",
            "num": 1,
            "type":5,
            "question": "Marketing participated in the evaluation and selection of the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3104":{
            "id": "X3104",
            "num": 1,
            "type":5,
            "question": "Does the platform provide the functionaly that addresses the marketing creative and collaborative process?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3106A": {
            "id": "X3106A",
            "num": 48,
            "question": "Are other platforms in use?",
            "type": 5,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": [],
            "selectOne": true,
            "choices": [
                "Yes",
                "No"
            ]
        },
        "X3106": {
            "id": "X3106",
            "num": 48,
            "question": "Other platforms in use:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3107": {
            "id": "X3107",
            "num": 48,
            "question": "They are used for:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },
        "X3108": {
            "id": "X3108",
            "num": 48,
            "question": "They are used by:",
            "type": 3,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3109":{
            "id": "X3109",
            "num": 1,
            "type":2,
            "question": "Annual contract cost for primary PMP:",
            "caption": "$",
            "dataFormat":"$",
            "hint": "",
            "cols":[],
            "defaults":[]
        },
        "X3110":{
            "id": "X3110",
            "num": 1,
            "type":8,
            "question": "Primary platform is support by:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Internal Marketing Ops",
                "IT",
                "Vendor",
                "External Consultants",
            ]
        },
        "X3111":{
            "id": "X3111",
            "num": 1,
            "type":5,
            "question": "Marketing teams and leaders satisfaction with PMP vendor support...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "extremely satisfied",
                "very satisfied",
                "satisfied",
                "not satisfied",
            ]
        },
        "X3112":{
            "id": "X3112",
            "num": 1,
            "type":5,
            "question": "Would leaders recommend the platform to other marketers?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3200":{
            "id": "X3200",
            "num": 1,
            "type":5,
            "question": "The existing project management platform is utilized by what portion of marketing teams within the organization?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "all",
                "some",
                "specific",
                "none"
            ]
        },

        "X3201":{
            "id": "X3201",
            "num":29 ,
            "type":5,
            "question": "Approximately what percentage of marketers use the platform",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[],
            "selectOne": true,
            "choices":[
                "0%",
                "25%",
                "50%",
                "75%",
                "100%",
            ]
        },

        "X3202":{
            "id": "X3202",
            "num": 1,
            "type":8,
            "question": "The existing project management platform is utilized by ...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "no external teams",
                "contractors",
                "agencies",
                "freelancers",
                "consultants",
            ]
        },
        "X3202A":{
            "id": "X3202A",
            "num": 23,
            "type":7,
            "question": "User types and counts (Including all levels i.e. Coordinator, Manager, Director, Sr Director, VP - can be partial headcount):",
            "caption": "",
            "hint": "",
            "cols":[],
            "defaults":[],
        },
        "X3204":{
            "id": "X3204",
            "num":29 ,
            "type":2,
            "question": "Project/Program:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3205":{
            "id": "X3205",
            "num":29 ,
            "type":2,
            "question": "Traffic: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3206":{
            "id": "X3206",
            "num":29 ,
            "type":2,
            "question": "Creative: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3207":{
            "id": "X3207",
            "num":29 ,
            "type":2,
            "question": "Campaign: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3208":{
            "id": "X3208",
            "num":29 ,
            "type":2,
            "question": "SME / Product:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3209":{
            "id": "X3209",
            "num":29 ,
            "type":2,
            "question": "Collaborator:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3210":{
            "id": "X3210",
            "num":29 ,
            "type":2,
            "question": "Approver:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },

        "X3211":{
            "id": "X3211",
            "num":29 ,
            "type":2,
            "question": "Sr Leader (VP, SVP, CMO):",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3225.final"}
        },
        "X3213":{
            "id": "X3213",
            "num": 23,
            "type":7,
            "question": "User counts by department:",
            "caption": "",
            "hint": "",
            "cols":[],
            "defaults":[],
        },
        "X3214":{
            "id": "X3214",
            "num":29 ,
            "type":2,
            "question": "Marketing:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3215":{
            "id": "X3215",
            "num":29 ,
            "type":2,
            "question": "Project/Program:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3216":{
            "id": "X3216",
            "num":29 ,
            "type":2,
            "question": "Product/Line of Business:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3217":{
            "id": "X3217",
            "num":29 ,
            "type":2,
            "question": "Sales:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3218":{
            "id": "X3218",
            "num":29 ,
            "type":2,
            "question": "Sales Ops:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3219":{
            "id": "X3219",
            "num":29 ,
            "type":2,
            "question": "Legal:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3220":{
            "id": "X3220",
            "num":29 ,
            "type":2,
            "question": "Compliance:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },
        "X3221":{
            "id": "X3221",
            "num":29 ,
            "type":2,
            "question": "Other:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },

        "X3222":{
            "id": "X3222",
            "num":29 ,
            "type":2,
            "question": "Marketing Ops:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "calc":{"final":"}$a"},
            "calcVars":{"a":"X3226.final"}
        },



        "X3225":{
            "id": "X3225",
            "num": 10,
            "type":2,
            "question": "Total team members utilizing the platform:",
            "caption": "#",
            "dataFormat":"0.0",
            "hint": null,
            "cols":[],
            "defaults":[],
            "enabled":[false],
            "calc":{"final":"$a + $b + $c + $d + $e + $f + $g"},
            "calcVars":{"a":"X3203.final","b":"X3204.final","c":"X3205.final","d":"X3206.final","e":"X3207.final","f":"X3208.final","g":"X3211.final"}
        },
        "X3226":{
            "id": "X3226",
            "num": 10,
            "type":2,
            "question": "Total user count",
            "caption": "#",
            "dataFormat":"0.0",
            "hint": null,
            "cols":[],
            "defaults":[],
            "enabled":[false],
            "calc":{"final":"$a + $b + $c + $d + $e + $f + $g + $h"},
            "calcVars":{"a":"X3214.final","b":"X3215.final","c":"X3216.final","d":"X3217.final","e":"X3218.final","f":"X3219.final","g":"X3220.final","h":"X3221.final"}
        },

        "X3301":{
            "id": "X3301",
            "num":29 ,
            "type":2,
            "question": "Number of projects per month:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3302":{
            "id": "X3302",
            "num":29 ,
            "type":8,
            "question": "Types of projects that are tracked using PMP: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Banners",
                "Brochures",
                "Campaigns",
                "Catalogs",
                "Content/Blogs",
                "Digital Ads",
                "Flyers",
                "POS Material",
                "Print Ads",
                "Sales Tools",
                "Social Ads",
                "Video",
                "Other"
            ]
        },

        "X3304":{
            "id": "X3304",
            "num":29 ,
            "type":5,
            "question": "Projects managed within the project management platform are  ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "single threaded",
                "multi-threaded",
                "both single and multi-threaded"
            ]
        },

        "X3305": {
            "id": "X3305",
            "num": 91,
            "question": "Are product or service launch projects with a wide variety of marketing deliverables and assets and schedule dependencies managed using the platform?",
            "type": 6,
            "hint": null,
            "caption": "",
            "cols": [],
            "defaults": []
        },

        "X3307":{
            "id": "X3307",
            "num":29 ,
            "type":2,
            "question": "Percentage of campaigns that are managed in PMP:",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3308":{
            "id": "X3308",
            "num":29 ,
            "type":8,
            "question": "Of the projects that are NOT managed in PMP, what are challenges or why not: ",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Work/project is too complex to manage in PMP",
                "Team that owns work doesn’t use PMP",
                "PMP support team doesn’t have time to build and implement workflow",
                "Other"
            ]
        },


        "X3401":{
            "id": "X3401",
            "num":29 ,
            "type":8,
            "question": "Marketing project requests are submitted by:",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "the entire organization",
                "the marketing team",
                "select members of the marketing team",
                "Other"
            ]
        },
        "X3402":{
            "id": "X3402",
            "num": 1,
            "type":8,
            "question": "Requests are submitted using:  ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "the platform",
                "Jira or other ticketing system",
                "Sharepoint",
                "Google Docs",
                "manual emails",
                "forms sent via email",
                "Other",
            ]
        },
        "X3403":{
            "id": "X3403",
            "num": 1,
            "type":8,
            "question": "Intake methods are utilized for ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "all projects",
                "new projects only",
                "updates to existing projects",
                "versioning of existing assets"
            ]
        },

        "X3405":{
            "id": "X3405",
            "num": 1,
            "type":5,
            "question": "Is there a common form with options and selections to intake all marketing requests?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3406":{
            "id": "X3406",
            "num":29 ,
            "type":2,
            "question": "What percentage of marketing requests are supported by the form?",
            "caption": "%",
            "dataFormat":"%",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3407":{
            "id": "X3407",
            "num":29 ,
            "type":2,
            "question": "How many intake forms are used for different types of requests?",
            "caption": "#",
            "dataFormat":"#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3408":{
            "id": "X3408",
            "num":29 ,
            "type":3,
            "question": "What types of requests are these forms used for?",
            "caption": "",
            "dataFormat":"",
            "hint": null,
            "cols":[],
            "defaults":[]
        },


        "X3409":{
            "id": "X3409",
            "num": 1,
            "type":5,
            "question": "Are there concerns with the intake process? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3410":{
            "id": "X3410",
            "num":29 ,
            "type":3,
            "question": "What are the concerns?",
            "caption": "",
            "dataFormat":"",
            "hint": null,
            "cols":[],
            "defaults":[]
        },


        "X3501":{
            "id": "X3501",
            "num": 1,
            "type":6,
            "question": "Does the existing project management platform provide support for marketing specific workflows with a number of contributors, reviews and approvers?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3502":{
            "id": "X3502",
            "num": 1,
            "type":6,
            "question": "Can designers and graphic design teams work within the platform and access design tools (i.e. Adobe Creative Suite) from within the project management platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3503":{
            "id": "X3503",
            "num": 1,
            "type":6,
            "question": "Can video projects be developed, edited, commented or annotated within the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3503A":{
            "id": "X3503A",
            "num": 1,
            "type":8,
            "question": "Check all steps that are included in current PMP workflow:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Intake",
                "Plan/Assign",
                "Schedule/Track",
                "Design/Creative",
                "Collaboration/Approval",
                "Share/Distribute",
                "Report/Audit",
            ]
        },

        "X3504":{
            "id": "X3504",
            "num":29 ,
            "type":3,
            "question": "What workflows, steps in your process or creative work are not supported by your platform?",
            "caption": "",
            "dataFormat":"",
            "hint": null,
            "cols":[],
            "defaults":[]
        },

        "X3505":{
            "id": "X3505",
            "num": 1,
            "type":3,
            "question": "Provide addition comments on why?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
        },

        "X3506":{
            "id": "X3506",
            "num": 1,
            "type":5,
            "question": "Does your current platform provide all of the features that your team requires? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },



        "X3507":{
            "id": "X3507",
            "num": 1,
            "type":3,
            "question": "What are specific missing features? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[]
        },


        "X3601":{
            "id": "X3601",
            "num": 1,
            "type":5,
            "question": "Does the existing platform accommodate the organization’s requestors, collaborators and approvers?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "provides full support",
                "provides partial support",
                "does not provide support",
            ]
        },

        "X3602":{
            "id": "X3602",
            "num": 1,
            "type":6,
            "question": "Do senior executives the platform to review and approve content they are accountable for?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3603":{
            "id": "X3603",
            "num": 1,
            "type":6,
            "question": "Do legal or regulatory teams utilize the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3604":{
            "id": "X3604",
            "num": 1,
            "type":6,
            "question": "Do SMEs for Product, Segment or Industry specific projects utilize the platform? ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3605":{
            "id": "X3605",
            "num": 1,
            "type":5,
            "question": "Do collaborators and approvers work within the platform to make edits, comments, approve or reject content?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X3606":{
            "id": "X3606",
            "num": 1,
            "type":5,
            "question": "If collaborators and approvers respond outside of the system, are email replies and responses incorporated back into the workflow?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X3701":{
            "id": "X3701",
            "num": 1,
            "type":6,
            "question": "Can existing projects be easily replicated or updated with the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },



        "X3801":{
            "id": "X3801",
            "num": 1,
            "type":5,
            "question": "Marketing and creative team resource and time management feature are: ",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "available",
                "partially available",
                "not available",
            ]
        },

        "X3802":{
            "id": "X3802",
            "num": 1,
            "type":5,
            "question": "Resource management features are...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "robust",
                "adequate",
                "limited"
            ]
        },

        "X3803":{
            "id": "X3803",
            "num": 1,
            "type":6,
            "question": "Can project managers and project owners view resources across projects and calendars?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },
        "X3804":{
            "id": "X3804",
            "num": 1,
            "type":6,
            "question": "Can project managers and owners quickly determine where projects are stalled within the process and who is accountable for delays?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3901":{
            "id": "X3901",
            "num": 1,
            "type":6,
            "question": "Is there an end-to-end tracking capability for audit and senior leadership review of the production process and the participants, contributors, reviewers and approvers of content?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No"
            ]
        },

        "X3902":{
            "id": "X3902",
            "num": 1,
            "type":6,
            "question": "Are reports are available within the platform?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },
        "X31001":{
            "id": "X31001",
            "num": 1,
            "type":5,
            "question": "Cost tracking and accounting of projects ...",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "selectOne": true,
            "choices":[
                "is fully available for charge back or reporting based on project or task codes",
                "available on a limited basis",
                "must be done outside the platform",
            ]
        },

        "X31101":{
            "id": "X31101",
            "num": 1,
            "type":6,
            "question": "Does the platform have the capabilities required to address the specific needs of the creative, content and production process?",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": false,
            "choices":[
                "Yes",
                "No",
            ]
        },

        "X31103":{
            "id": "X31103",
            "num": 1,
            "type":8,
            "question": "Users have these concerns with the existing platform:",
            "caption": "#",
            "hint": null,
            "cols":[],
            "defaults":[],
            "allowOther": true,
            "choices":[
                "Too complex to use",
                "Lack of training",
                "No support to build workflows",
                "Tool doesn’t support how we work",
                "Other",
            ]
        },





    },

    "survey":{
        "S001":{
            "id": "S001",
            "num": 1,
            "type":1,
            "weight": 10.0,
            "question": "Project Management Platform",
            "shortLabel": "Platform",
            "description": "Do they have automated vs manual processes – what platform?",
            "caption": "CMO, Senior Marketing (Managers, Directors) in Marketing Ops, Marketing, Creative Ops, Brand, Product, Corporat Comms, etc.",
            "criteria5": "<b>Full marketing specific PMP deployment and build out </b>for all marketing campaign and creative projects including complex product or service launches.  Implementation was designed and executed based on marketing requirements and workflows specific to our business needs.",
            "criteria4": "<b>Customized Enterprise deployed PMP (Workfront, Monday.com, Asana, etc.) in use.</b>  Platform has been customized for marketing specific requriements.  System lacks some features required by marketing. ",
            "criteria3": "<b>Enterprise deployed PMP (Workfront, Monday.com, Asana, etc.) in use.</b>  No specific marketing customizations have been implemented. Utilizing standard features and functionality to manage the majority ot the creative process.",
            "criteria2": "<b>Shared folders, Teams, and/or spreadsheets used to manage creative projects and campaigns manually</b>",
            "criteria1": "<b>No marketing project management platform or system</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S002":{
            "id": "S002",
            "num": 2,
            "type":1,
            "weight": 10.0,
            "question": "Adoption and usage: Internal and External Teams ",
            "description": "Is the entire team using the platform? Why not?",
            "shortLabel": "Adoption & Usage",
            "caption": "Senior manager/directors noted above. Also, Traffic Managers/Coordinators, Coordinators, Project Managers",
            "criteria5": "<b>Full Adoption.</b>  Entire marketing team and cross functional teams use the system.  External agencies have access to and contribute to projects and campaigns. Same platform is used across different marketing teams within the organization",
            "criteria4": "<b>Full adoption - individual teams.</b>  Individual Marketing team(s) use the system.  External agencies do  have access . Cross functional teams aligned to a marketing team that uses the platform works in the platform.  Different platforms are used throughout the organization.",
            "criteria3": "<b>Marketing Only Adoption - individual teams.</b>  Individual Marketing team(s) use the system.  Approvals and colllaboration by cross functional teams is not done in the platform. External agencies do  not have access . ",
            "criteria2": "<b>Partial adoption</b> - less than 50% of the marketing team uses the platform.  Manually processes continue to be used by non-users due to concerns that the platform does not support their requirements. ",
            "criteria1": "<b>Minimal Adoption</b> - Only a handful of marketing team members use the system. Some teams continue to use a manual process for intake and management of projects.  They claim the system doesn't meet their needs or workflow.  ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },

        "S003":{
            "id": "S003",
            "num": 3,
            "type":1,
            "weight": 10.0,
            "question": "Team Satisfaction with PMP",
            "description": "Does the Marketing team like the current PM platform – did they choose the platform or did IT cascade?",
            "shortLabel": "Satisfaction w/ PMP",
            "caption": "Senior manager/directors noted above. Also, Traffic Managers/Coordinators, Coordinators, Project Managers. Team members (Designers, Writers, Producers, etc… think “End Users.”",
            "criteria5": "<b>Extremely satisfied</b> - Platform addresses all of our current needs and can be expanded/enhanced for future needs.  Marketing and cross functional teams value the platform and it's ability to increase efficiency, improve work quality and achieve deadlines.",
            "criteria4": "<b>Very Satisfied</b> - Platform is increasing productivity and quality of work and has eliminated most manual processes. There are still a few tasks that still need to be done outside the platform. ",
            "criteria3": "<b>Satisfied</b>  - Marketers generally like working in the platform and believe it helps them get their work done.  Some users complain that platform does not enable them to work efficiently or lacks marketing specific workflows or features",
            "criteria2": "<b>Somewhat satisfied</b> - Platform lacks features and capabilities necessary for marketing use and the creative process (see other category scorings).",
            "criteria1": "<b>Not satisfied with existing system.</b>  We are trying to use the system but it is not satisfying our marketing requirements.  Did not participate in selection of enterprise deployed system.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S004":{
            "id": "S004",
            "num": 4,
            "type":1,
            "weight": 5.0,
            "question": "Request and Intake",
            "description": "How do they process requests?",
            "shortLabel":"Request & Intake",
            "caption": "Managers and/or Directors, Coordinators, Schedulers, Project Managers. May be useful to interview internal requestors or their managers (key customers in org",
            "criteria5": "<b>Complete use by entire org - PMP provided intake forms are utilized to create new projects or updates to existing projects.</b>  Creative and campaign briefs are fully automated and all input is used to populate fields at initial build of project.  All projects are handled through PMP.  No other intake is available to teams.  ",
            "criteria4": "<b>Full use by Marketing org</b> - All Marketing teams use the intake process.  Some request come from cross functional teams and leadership but the marketing team uses the PMP intake process to complete the requests to start projects on behalf of the requestors.",
            "criteria3": "<b>Partial use of intake process</b> -PMP intake forms are used to start campaign and projects but updates and versioning of assets is done outside of the system.  Some adhoc requests come from cross functional team or leadership that's not comfortable using the system. Intake forms may not be used to start these projects.",
            "criteria2": "<b>Email submission or IT ticketing system</b> (Jira, Asana, Sharepoint, Google Forms, etc.) is used for intake and routed to general inbox.  Limited ability to manage intake volume.",
            "criteria1": "<b>Manual process</b> used for intake.  Requestors send emails to marketing teams and requests are handled through email and phone calls.  ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S005":{
            "id": "S005",
            "num": 5,
            "type":1,
            "weight": 10.0,
            "question": "Requestor, Collaborator, Approver Enablement ",
            "description": "Can they handle a large number of requestors, collaborators and approvers?",
            "shortLabel":"Enablement",
            "caption": "Managers and/or Directors, Coordinators, Schedulers, Project Managers. May be useful to interview internal requestors or their managers (key customers in org)",
            "criteria5": "<b>Unlimited number of requestors, collaborators and approvers (internal and external)</b> utilize the platform and all feedback, edits and approvals are included/tracked by the PMP.",
            "criteria4": "<b>Marketing teams and direct collaborators (SMEs for Product, Segment, Industry) use the system to request and manage projects.</b>  Legal teams and senior leaders do not use the approval process in the PMP.  They review assets outside of the system and provide feedback/approval directly or offline.",
            "criteria3": "<b>Over half of Requestors are using the system,</b> but approvals,  rejections, changes are often still managed and communicated outside.",
            "criteria2": "<b>A small percentage of requestors are using the PMP system.</b> Most requests are submitted outside of the system and a resource has to enter them.",
            "criteria1": "<b>Only marketing team members use the platform.</b>  Requests, collaboration and approvals are done outside the PMP.  Access by all collaborators and approvers is limited by license costs.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S006":{
            "id": "S006",
            "num": 6,
            "type":1,
            "weight": 10.0,
            "question": "Project or Campaign Complexity",
            "description": "Can they manage multi-channel/tactic programs and campaigns?",
            "shortLabel":"Project Complexity",
            "caption": "Mid to Sr. Level Managers/Directors, Coordinators and Project Managers. ",
            "criteria5": "<b>Complex and Multi-threaded campaigns, projects and launches/events are managed using the platform.  Partitions are available if required for internal clients or requestor requirements.</b>",
            "criteria4": "<b>All campaigns and projects are managed using the plaform.</b>  Some complex launches with multiple workflows and components are managed using the platform.  Others are managed outside the system or are broken into separate projects.",
            "criteria3": "<b>Majority of projects are managed using the platform.</b>  Complex launches with multiple workflows and components are not managed using the platform.  These are managed outside the system or are broken into separate projects.",
            "criteria2": "<b>Simple campaigns with multiple tactics or projects with multiple assets are managed.</b>  Complex projects with multple branches or sub workflow are not managed in the platform - or are broken into separate projects.  ",
            "criteria1": "<b>Single threaded projects only.</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S007":{
            "id": "S007",
            "num": 7,
            "type":1,
            "weight": 10.0,
            "question": "Marketing Specific Workflow Support - Creative App Integration",
            "shortLabel": "Marketing Support",
            "description": "What best describes your marketing specific workflow support?",
            "caption": "Mid to Sr. Level Managers/Directors, Coordinators and Project Managers. Would also be good to talk with end users about how those integrations help/hurt/don't matter.",
            "criteria5": "<b>PMP is specifically designed and configured to support marketing specific workflows</b> throughout the creative, production, approval and distribution process.  Full ACS integration utilized. ",
            "criteria4": "<b>Enterprise platform has been customized for marketing workflows</b> but some creative tasks are still managed outside the platform.",
            "criteria3": "<b>Some marketing specific workflow support.</b> System was deployed by IT for enterprise use and a number of customized workflows have been created for marketing. ",
            "criteria2": "<b>Few marketing specific workflows are supported.</b> System was deployed by IT for enterprise use and a few critical workflows have been created for marketing. ",
            "criteria1": "<b>No marketing specific workflow support.</b> System was deployed by IT for enterprise use.  No customization for marketing.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S008":{
            "id": "S008",
            "num": 8,
            "type":1,
            "weight": 5.0,
            "question": "Project Replication or Update",
            "description": "Are there repeated updates or reuse of materials?",
            "shortLabel": "Project Replication",
            "caption": "Coordinators, Project Managers, maybe high-volume requestors",
            "criteria5": "<b>Easy update or replication of existing projects</b> for revisions, brand updates, copy corrections, publish date refreshes, etc.  All participants, creative assets and materials are available. ",
            "criteria4": "<b>Update or replication of existing projects with assets and participants.</b>  Some manual updates are required to fully manual a new or copied project ",
            "criteria3": "<b>Replication or update of simple campaigns and projects.</b> Assets must be added manually to be available in a copied project.",
            "criteria2": "<b>Limited replication or update.</b> Assets must be added manually to be available in a copied project.",
            "criteria1": "<b>No replication or update.</b> All projects must be started new.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S009":{
            "id": "S009",
            "num": 9,
            "type":1,
            "weight": 5.0,
            "question": "Auditing and Tracking of Approvals by SMEs, Execs and for Regulatory Requirements",
            "description": "Do stakeholders and sr leaders need access to review process and approvals? Are there a large number of approvers to finalize assets – legal, product, industry, etc.?",
            "shortLabel":"Auditing",
            "caption": "Coordinators or Designers/Creatives who manage the feedback process.",
            "criteria5": "<b>Full tracking and audit trail.</b>  All stakeholders and Sr Leaders have access to project/campaign/content creation, review and approval details to ensure proper review has been completed.  Reports are available if required for audit. ",
            "criteria4": "<b>Tracking is available and viewable by a marketing teams.</b>  Marketing team must pull records or reports to provide approval history to leaders.",
            "criteria3": "<b>Tracking is available but not viewable by a stakeholder of Sr Leader within a project that they need to approve.</b> Marketing team must pull records or reports to provide approval history.",
            "criteria2": "<b>All approval is manual and tracking through job routing docs, meeting notes or email.</b>",
            "criteria1": "<b>No approval tracking or audit capability.</b> ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S010":{
            "id": "S010",
            "num": 10,
            "type":1,
            "weight": 5.0,
            "question": "Cost tracking for client or internal billing. ",
            "description": "Full tracking and accounting of projects and campaigns for charge back or reporting using project and task codes.",
            "shortLabel": "Cost Tracking",
            "caption": "Coordinators or Designers/Creatives who manage the feedback process.",
            "criteria5": "<b>Full tracking and accounting of projects and campaigns for charge back or reporting using project and task codes.</b>",
            "criteria4": "<b>Tracking and accounting of projects and campaigns with limited reports.</b>",
            "criteria3": "<b>Limited tracking and accounting of projects and campaigns.</b>",
            "criteria2": "<b>Tracking and accounting of projects and campaigns is done outside of the platform.</b>",
            "criteria1": "<b>No cost tracking</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S011":{
            "id": "S011",
            "num": 11,
            "type":1,
            "weight":5.0,
            "question": "Integration with Marketing and Sales Systems",
            "description": "Can final assets be delivered to Print Production, Web2Print, DAM, MAP/CRM, Website, Sales without manual steps?",
            "shortLabel": "Integration",
            "caption": "CMO, Senior Marketing (Managers, Directors) in Marketing Ops, Marketing, Creative Ops, Brand, Product, Corporat Comms, etc.",
            "criteria5": "<b>Fully integration with MarTech stack:</b> Creative and media agency systems, Web2Print and Print vendor systems, DAM, Website management, Sales Enablement/CRM, Digital ad platforms, ",
            "criteria4": "<b>Partial integration with select MarTech apps.</b>",
            "criteria3": "<b>Automated filing via APIs or integration with other systems.</b>",
            "criteria2": "<b>Automated filing in shared folders</b>",
            "criteria1": "<b>No integration</b> - platform is standalone and final assets are manual taken from platform and stored in shared folders or sent by email.",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S012":{
            "id": "S012",
            "num": 12,
            "type":1,
            "weight": 5.0,
            "question": "Marketing and Creative Resource Availability and Management.",
            "description": "Can internal resources be managed effectively?",
            "shortLabel": "Resource Management",
            "caption": "Sr. Manager/Directors, Traffic",
            "criteria5": "<b>Full resource tracking with views across projects, campaigns and partitions. Calendar and time tracking is available.  Resource management features are robust.</b>",
            "criteria4": "<b>Resource management and tracking are available but viewing and reporting functionality is limited. </b>",
            "criteria3": "<b>Limited resource management and tracking are available.  Reports are not available.</b>",
            "criteria2": "<b>Managing resources outside of the platform.</b>",
            "criteria1": "<b>No resource management.</b>",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        },
        "S013":{
            "id": "S013",
            "num": 13,
            "type":1,
            "weight": 10.0,
            "question": "Vendor Support Satisfaction",
            "description": "How satisfied s the marketing team with the support provided by the platform vendor?",
            "shortLabel": "Vendor Support",
            "caption": "All users with a focus on day-to-day users (coordinators, creatives, system admins, etc.)",
            "criteria5": "<b>Extremely satisfied.</b> Vendor provides excellent support, assists with training and shares best practies and advises on expanded/enhanced use to handle future needs.  Issues and problems are addressed in a timely manner.  Marketing considers the provider a strategic partner. ",
            "criteria4": "<b>Very Satisfied.</b> Vendor makes resources available on request to the support our ongoing needs.  Some guidance and consulting to continue to improve our use of the platform is available without  paid engagements.  Issues and problems are resolved over time.",
            "criteria3": "<b>Satisfied.</b> Vendor provides the support required to utilize the platform effectively.  Consulting is available on a paid basis to continue to improve our use of the platform.  Issues and problems are resolved if other clients have similar problems.  ",
            "criteria2": "<b>Somewhat Satisfied.</b> Vendor provides limited support to address and resolve issues.  No commitment from vendor to help utilize the platform more effectively or efficiently to accomplish our goals. We are required to research and find answers online or via knowledge bases.",
            "criteria1": "<b>Not Satisfied.</b> Vendor does not provide support necessary to utilize the platform to manage our marketing projects.  Vendor does not consider us an important client. ",
            "criteriaNA": "Not Applicable",
            "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
        }
    }
}















