export default {
  "groups":{
    "Organization Data":{ "label":"Organization Data", "id":"orgdata","questions":[

      "X107",
      "X108",
      "X109",
      "X110",
      "X111",
      "X112",
      "X113",
      "X114",
      "X115",
      "X116",
      //"X117", Missing question in spreadsheet
      "X118",
      "X119"
    ]},
    "Software & Platforms":{ "label":"Software & Platforms", "id":"software","questions":[
      "X120",
      "X121"
    ]},
    "Design Requests": { "label": "Design Requests", "id":"designreq", "questions": [
        "X122",
        "X123"
      ]},
    "Customer Journey Mapping":{ "label":"Customer Journey Mapping", "id":"journey","questions":[
      "X124",
        "X124A",
      "X125"
    ]},
    "Communication Development":{ "label":"Communication Development", "id":"communication","questions":[
      "X126",
      "X127",
      "X128",
      "X129",
      "X130",
      "X131"
    ]},
    "Content and Data Management":{ "label":"Content and Data Management", "id":"contentdata","questions":[
      "X132",
      "X133",
      "X134",
      "X135",
      "X136",
      "X137"
    ]},
    "Business User Enablement":{ "label":"Business User Enablement", "id":"businessuser","questions":[
      "X138",
      "X139",
      "X140"
    ]},
    "Approval Processes":{ "label":"Approval Processes", "id":"approvals","questions":[
      "X141",
      "X142"
    ]},
    "Communications Output Processing and Delivery":{ "label":"Communications Output Processing and Delivery", "id":"commoutput","questions":[
      "X143",
      "X144",
      "X145",
      "X146"
    ]},
    "Communication Auditing/Tracking":{ "label":"Communication Auditing/Tracking", "id":"tracking","questions":[
      "X147",
      "X148",
      "X149",
      "X149A"
    ]},
    "Automation Processes and Platforms":{ "label":"Automation Processes and Platforms", "id":"automation","questions":[
      "X150",
      "X151",
      "X152",
      "X153"
    ]},
    "Preference Management":{ "label":"Preference Management", "id":"prefs","questions":[
      "X154",
      "X155",
      "X156",
      "X157"
    ]},
    "Campaign Management":{ "label":"Campaign Management", "id":"campaigns","questions":[
      "X158",
      "X159",
      "X160"
    ]},
    "Integration and Cloud Strategies":{ "label":"Integration and Cloud Strategies", "id":"cloud","questions":[
      "X161",
      "X162",
      "X163",
      "X164"
    ]},
    "Disaster Recovery":{ "label":"Disaster Recovery", "id":"disaster","questions":[
      "X165",
      "X166",
      "X167",
      "X168",
      "X169",
      "X170",
      "X171"
    ]}
  },

  "stages":{
    "Organization Data":{ "id": "orgdata", "markerLabel": "Organization<br>Data", "label": "Organization Data", "sections":["orgdata"],
      "instructions": "Please fill out the forms below from information provided by the Customer."
    },
    "Software & Systems":{ "id": "platforms", "markerLabel": "Software &<br>Systems", "label": "Software and Platforms", "sections":["software", "cloud"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Customer Journey":{ "id": "customerjourney", "markerLabel": "Customer<br>Journey", "label": "Customer Journey", "sections":["journey","prefs","campaigns"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Communication Development":{ "id": "communication", "markerLabel": "Communication Development", "label": "Communication Development", "sections":["communication","designreq","approvals","businessuser"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Content Management":{ "id": "contentmgmt", "markerLabel": "Content &<br>Management", "label": "Content Management", "sections":["contentdata"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Communications Delivery":{ "id": "commdelivery", "markerLabel": "Communications Delivery", "label": "Communications Delivery", "sections":["commoutput","tracking","automation"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Disaster Recovery":{ "id": "disaster", "markerLabel": "Disaster Recovery", "label": "Disaster Recovery", "sections":["disaster"], "instructions": "Please fill out the forms below from information provided by the Customer."}
  },


  "questions":{
    "X106":{
      "id": "X106",
      "num": 1,
      "type":8,
      "question": "Departments Reviewed",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
          "Executive Management (CXO/CMO/CEO)",
        "Business Development",
        "Marketing",
        "Customer Service",
        "Legal/Compliance",
        "IT",
        "Operations/Output Services",
        "Department/Business Unit Head(s)"
      ]
    },

    "X107":{
      "id": "X107",
      "num": 1,
      "type":2,
      "question": "# of Templates Developed Annually",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X108":{
      "id": "X108",
      "num": 1,
      "type":2,
      "question": "# of Template Changes Annually",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X109":{
      "id": "X109",
      "num": 1,
      "type":2,
      "question": "# of Page Images per Template",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X110":{
      "id": "X110",
      "num": 1,
      "type":2,
      "question": "# of Monthly Print Communications Processed thru Templates",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X111":{
      "id": "X111",
      "num": 1,
      "type":2,
      "question": "# of Monthly Email Communications Processed thru Templates",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X112":{
      "id": "X112",
      "num": 1,
      "type":2,
      "question": "Total # of Employees who Process Communications",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X113":{
      "id": "X113",
      "num": 1,
      "type":2,
      "question": "Print Communications Developers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X114":{
      "id": "X114",
      "num": 1,
      "type":2,
      "question": "Email Communications Developers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X115":{
      "id": "X115",
      "num": 1,
      "type":2,
      "question": "Production Staff",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X116":{
      "id": "X116",
      "num": 1,
      "type":2,
      "question": "Administrative/Project Managers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X117":{
      "id": "X117",
      "num": 1,
      "type":2,
      "question": "",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X118":{
      "id": "X118",
      "num": 1,
      "type":2,
      "question": "# of Impressions Produced on Digital Cut-Sheet Equipment - Color",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X119":{
      "id": "X119",
      "num": 1,
      "type":2,
      "question": "# of Impressions Produced on Digital Cut-Sheet Equipment - Monochrome",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X120":{
      "id": "X120",
      "num": 1,
      "type":8,
      "question": "Software",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire",
        "OpenText Exstream",
        "PlanetPress",
        "Documaker",
        "Documerge",
        "Adobe",
        "Uluro",
        "Isis Papyrus",
        "Elixir Tango",
        "MessagePoint",
        "SmartComm Smart Communications",
        "CinCom",
        "EngageOne",
        "DOC1",
        "MS Office Suite",
        "XMPIE",
        "Other Module or Details"
      ]
    },
    "X121":{
      "id": "X121",
      "num": 1,
      "type":8,
      "question": "Platforms",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Salesforce",
        "Oracle",
        "Guidewire",
        "WyPro",
        "SAP",
        "NCR",
        "IBM",
        "Other"
      ]
    },
    "X122":{
      "id": "X122",
      "num": 1,
      "type":8,
      "question": "Design Request Software",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
          "Adobe Creative Cloud",
        "Homegrown System",
        "Excel",
        "Monday",
        "Hive",
        "Wryke",
        "Smart Sheet",
        "Quadient Inspire Interactive",
        "Workgroups DaVinci",
        "Workfront Marketing",
        "Other"
      ]
    },
    "X123":{
      "id": "X123",
      "num": 1,
      "type":8,
      "question": "Prioritization and daily status are managed actively by management using:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "SLA",
        "FIFO",
        "Other"
      ]
    },
    "X124":{
      "id": "X124",
      "num": 1,
      "type":8,
      "question": "Customer Journey Mapping",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "trigger" : {
        "target": "X124A",
        "condition": "has been implemented"
      },
      "choices": [
        "has been implemented",
        "is being investigated",
        "is not being investigated"
      ]
    },
    "X124A":{
      "id": "X124A",
      "num": 1,
      "type":8,
      "question": "Customer Journey Mapping is processed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "enabled": false,
      "hidden":true,
      "selectOne": true,
      "allowOther": true,
      "otherPlaceholder": "Enter mapping software here",
      "choices": [
        "Manual Processes",
        "Software"
      ]
    },
    "X125":{
      "id": "X125",
      "num": 1,
      "type":3,
      "question": "Additional Customer Journey Mapping Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X126":{
      "id": "X126",
      "num": 1,
      "type":8,
      "question": "Communications are designed for",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "print",
        "electronic/digital distribution",
        "both print and electronic distribution"
      ]
    },
    "X127":{
      "id": "X127",
      "num": 1,
      "type":8,
      "question": "Print pieces are programmed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire",
        "OpenText Exstream",
        "PlanetPress",
        "Documaker",
        "Documerge",
        "Adobe",
        "Uluro",
        "Isis Papyrus",
        "Elixir Tango",
        "MessagePoint",
        "SmartComm Smart Communications",
        "CinCom",
        "EngageOne",
        "DOC1",
        "MS Office Suite",
        "XMPIE",
        "Adobe Creative Suite (including Dreamweaver and XD)",
        "Other"
      ]
    },
    "X128":{
      "id": "X128",
      "num": 1,
      "type":8,
      "question": "Electronic pieces are programmed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Mosaico",
        "Email",
        "Monster",
        "BEE",
        "Quadient Inspire",
        "Open Text Exstream",
        "Smart Comm",
        "Other"
      ]
    },
    "X129":{
      "id": "X129",
      "num": 1,
      "type":3,
      "question": "Please provide any detail on requirements to redesign your statements or rebrand in the near future.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X130":{
      "id": "X130",
      "num": 1,
      "type":3,
      "question": "Provide information on your organization's sustainability goals, green initiatives or digital transformation.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X131":{
      "id": "X131",
      "num": 1,
      "type":3,
      "question": "Additional Communications Development Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X132":{
      "id": "X132",
      "num": 1,
      "type":8,
      "question": "Content is stored",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "File Server",
        "ECM System",
        "Other"
      ]
    },
    "X133":{
      "id": "X133",
      "num": 1,
      "type":8,
      "question": "ECM Software Platform is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "selectOne": true,
      "choices": [
        "Open Text",
        "OnBase",
        "SharePoint",
        "FileNet",
        "Confluence",
        "LaserFiche",
        "Egnyte",
        "Alfresco",
        "DocuShare",
        "Iworks",
        "Doxim",
        "Inspire Content Manager",
          "Other"
      ]
    },
    "X134":{
      "id": "X134",
      "num": 1,
      "type":8,
      "question": "Content is categorized, tagged, and organized via a consistent methodology.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X135":{
      "id": "X135",
      "num": 1,
      "type":8,
      "question": "Data is stored in",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "a single database",
        "multiple databases"
      ]
    },
    "X136":{
      "id": "X136",
      "num": 1,
      "type":8,
      "question": "Data is stored using",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Microsoft SQL Server",
        "Oracle RDMS",
        "My SQL",
        "PostgreSQL",
        "IBM DB2",
        "Google Big Table",
        "Amazon RDS",
        "Other"
      ]
    },
    "X137":{
      "id": "X137",
      "num": 1,
      "type":3,
      "question": "Additional Content and Data Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X138":{
      "id": "X138",
      "num": 1,
      "type":8,
      "question": "Ways for line-of-business user to directly modify content in communications",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "one way",
        "multiple ways",
        "no way"
      ]
    },
    "X139":{
      "id": "X139",
      "num": 1,
      "type":8,
      "question": "The platform for Business User enablement is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "selectOne":true,
      "choices": [
        "Planet Press",
        "Quadient Inspire",
        "Open Text Empower",
        "Smart Comm",
          "Other"
      ]
    },
    "X140":{
      "id": "X140",
      "num": 1,
      "type":3,
      "question": "Additional Business User Enablement Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X141":{
      "id": "X141",
      "num": 1,
      "type":8,
      "question": "Approvals are managed via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire Approval",
        "Quadient Inspire Interactive",
          "Workgroups",
          "Workfront",
        "Open Text",
        "Homegrown",
        "Other"
      ]
    },
    "X142":{
      "id": "X142",
      "num": 1,
      "type":3,
      "question": "Additional Approval Processes Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X143":{
      "id": "X143",
      "num": 1,
      "type":8,
      "question": "Communications are produced for",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "print",
        "electronic/digital distribution",
        "both print and electronic/digital distribution"
      ]
    },
    "X144":{
      "id": "X144",
      "num": 1,
      "type":8,
      "question": "Production is managed via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "PlanetPress Connect",
        "Quadient Inspire Scaler",
        "Quadient Inspire Automation",
        "Exstream Communications Center",
        "Command Center",
        "Racami",
        "Ricoh Process Director",
        "Crawford Conductor",
        "Homegrown Systems"
      ]
    },
    "X145":{
      "id": "X145",
      "num": 1,
      "type":8,
      "question": "Communications are produced via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "batch",
        "on demand",
        "both batch and on demand"
      ]
    },
    "X146":{
      "id": "X146",
      "num": 1,
      "type":3,
      "question": "Additional Communications Processing and Delivery Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X147":{
      "id": "X147",
      "num": 1,
      "type":8,
      "question": "End to end tracking system in place",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "trigger": {
        "target": "X148:X149",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X148":{
      "id": "X148",
      "num": 1,
      "type":8,
      "question": "If there is an end to end tracking system in place (Yes above), what is the system used for tracking?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "hidden": false,
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Ricoh Process Director",
        "Crawford Conductor",
        "Sefas Producer",
        "Canon PRISMA Production",
        "BlueCrest Output Manager",
        "Other"
      ]
    },
    "X149":{
      "id": "X149",
      "num": 1,
      "type":8,
      "question": "There is no comprehensive tracking system in place. The tracking that is available is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "hidden": true,
      "allowOther": true,
      "selectOne": true,
      "choices": [
        "some databases with some log files",
        "mostly log files",
        "manual spreadsheets",
        "manual processes",
        "a homegrown system",
        "other"
      ]
    },
    "X149A":{
      "id": "X149A",
      "num": 1,
      "type":3,
      "question": "Additional Communication Auditing/Tracking Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X150":{
      "id": "X150",
      "num": 1,
      "type":8,
      "question": "Is workflow automation in place?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "trigger": {
        "target": "X151",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X151":{
      "id": "X151",
      "num": 1,
      "type":8,
      "question": "The system that is used is:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Ricoh Process Director",
        "Crawford Conductor",
        "Sefas Producer",
        "Canon PRISMA Production Workflow",
        "Blue Crest Output Manager",
        "Quadient Inspire Automation",
        "Quadient Inspire Scaler",
        "Racami",
        "Planet Press Connect",
        "Exstream Communications Center",
        "HP Command Center",
        "Homegrown",
        "Other"
      ],
      "hidden": true
    },
    "X152":{
      "id": "X152",
      "num": 1,
      "type":8,
      "question": "Is this workflow automation system used as a single platform for all automation?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X153":{
      "id": "X153",
      "num": 1,
      "type":3,
      "question": "Additional Automation Processes and Platforms Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X154":{
      "id": "X154",
      "num": 1,
      "type":8,
      "question": "Is there a Preference Management database?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "trigger": {
        "target": "X155:X156",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X155":{
      "id": "X155",
      "num": 1,
      "type":8,
      "question": "If so, is it self-service through a customer portal? manually managed internally? Other?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Customer self-service portal with a backend database",
        "Customer Service/CRM system with backend database",
        "Homegrown system with a backend database",
        "Manual entry into spreadsheets",
        "Other"
      ]
    },
    "X156":{
      "id": "X156",
      "num": 1,
      "type":8,
      "question": "Is Preference Management planned to be implemented in the next 12 months",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X157":{
      "id": "X157",
      "num": 1,
      "type":3,
      "question": "Additional Preference Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X158":{
      "id": "X158",
      "num": 1,
      "type":8,
      "question": "Campaigns are tracked and triggered",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "manually via Project Managers",
        "manually via Marketing Staff",
        "manually via other Staff",
        "through Quadient Inspire OCC",
        "through PlanetPress Connect",
        "through OpenText Campaign Manager",
        "through EFI Market Direct",
        "through Marcom Central",
        "through Adobe Campaign Manager",
        "through a Homegrown System",
        "Other"
      ]
    },
    "X159":{
      "id": "X159",
      "num": 1,
      "type":8,
      "question": "Is there capability to produce reports or dashboards based on campaign data",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X160":{
      "id": "X160",
      "num": 1,
      "type":3,
      "question": "Additional Campaign Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X161":{
      "id": "X161",
      "num": 1,
      "type":8,
      "question": "Management views cloud platforms",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "openly",
        "cautiously",
        "negatively"
      ]
    },
    "X162":{
      "id": "X162",
      "num": 1,
      "type":8,
      "question": "CCM cloud integration is viewed as",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "necessary",
        "not necessary"
      ]
    },
    "X163":{
      "id": "X163",
      "num": 1,
      "type":8,
      "question": "Cloud platform integration is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "complete",
        "in progress",
        "planned in next 12 months",
        "not planned in next 12 months"
      ]
    },
    "X164":{
      "id": "X164",
      "num": 1,
      "type":3,
      "question": "Additional Integration and Cloud Strategy Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X165":{
      "id": "X165",
      "num": 1,
      "type":3,
      "question": "The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X166":{
      "id": "X166",
      "num": 1,
      "type":2,
      "question": "The Disaster Recovery production site is located xx miles from your current operation.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X167":{
      "id": "X167",
      "num": 1,
      "type":3,
      "question": "Describe the SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X168":{
      "id": "X168",
      "num": 1,
      "type":3,
      "question": "How do you track SLA’s to ensure they are met? SLAs are tracked through...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X169":{
      "id": "X169",
      "num": 1,
      "type":8,
      "question": "Is your current outbound mail regulated?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X170":{
      "id": "X170",
      "num": 1,
      "type":3,
      "question": "Requirements to track and report outbound mail from production completion through delivery include ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X171":{
      "id": "X171",
      "num": 1,
      "type":2,
      "question": "The % of printed materials that contain Personal Health Information/HIPAA is...",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    }
  },

}















