export default {
  "groups":{
    "Organization Statistics":{ "label":"Organization Statistics", "id":"org","questions":["I1001","I1010","X3050"]},
    "Production Volume":{ "label":"Production Volume", "id":"volume","questions":["I1003", "X3002B", "X3002C", "X3065A", "X3065C", "X3065E","X3065G"]},
    "Mail Stats":{ "label":"Mail Stats", "id":"mailstats","questions":["X3010", "X3011", "X3012"]},
    "Workflow Processes":{ "label":"Workflow Processes", "id":"workflow","questions":["X3016","X3017","X3025","X3026","X3027","X3028","X3038","X3039","X3040"]},
    "Returned Mail":{ "label":"Returned Mail", "id":"returned","questions":["X3087"]},
    "Production Operations":{ "label":"Production Operations", "id":"operations","questions":["X3067"]},

  },

  "stages":{
    "Organization Statistics":{ "id": "org", "markerLabel": "Organization<br>Statistics", "label": "Organization Statistics", "sections":["org"],
      "instructions": "Please fill out the forms below from information provided by the Customer."
    },
    "Production Volume":{ "id": "volume", "markerLabel": "Production<br>Volume", "label": "Production Volume", "sections":["volume"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Mail Stats":{ "id": "mailstats", "markerLabel": "Mail Stats", "label": "Mail Stats", "sections":["mailstats"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Workflow Processes":{ "id": "workflow", "markerLabel": "Workflow<br>Processes", "label": "Workflow Processes", "sections":["workflow"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Returned Mail":{ "id": "returned", "markerLabel": "Returned<br>Mail", "label": "Returned Mail", "sections":["returned"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Production Operations":{ "id": "operations", "markerLabel": "Production<br>Operations", "label": "Production Operations", "sections":["operations"], "instructions": "Please fill out the forms below from information provided by the Customer."}
  },


  "questions":{

    "X3000A":{
      "id": "X3000A",
      "num": 1,
      "type":3,
      "question": "Hours of Operation",
      "caption": "",
      "hint": "Open and Close Times",
      "cols":[],
      "defaults":[]
    },

    "X3000B":{
      "id": "X3000B",
      "num": 1,
      "type":3,
      "question": "Days of Operation",
      "caption": "#",
      "hint": "Days of the Week",
      "cols":[],
      "defaults":[]
    },

    "I1001":{
      "id": "I1001",
      "num": 1,
      "type":2,
      "question": "Annual Sales",
      "caption": "$",
      "dataFormat":"$",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "I1010":{
      "id": "I1010",
      "num": 10,
      "type":2,
      "question": "Total Number of Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "enabled":[false]
    },

    "I1010A":{
      "id": "I1010A",
      "num": 10,
      "type":2,
      "question": "Number of Management Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010B":{
      "id": "I1010B",
      "num": 10,
      "type":2,
      "question": "Number of Administrative Employees / Job Ticket Developers / CSRs",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1013.final"}
    },
    "I1014":{
      "id": "I1014",
      "num": 14,
      "type":2,
      "question": "Number of Sales People",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001D":{
      "id": "X3001D",
      "num": 14,
      "type":2,
      "question": "Number of Mail Piece Design and Development Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001E":{
      "id": "X3001E",
      "num": 14,
      "type":2,
      "question": "Number of Employees who Prep Mailing Lists",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001F":{
      "id": "X3001F",
      "num": 14,
      "type":2,
      "question": "Number of Insertion Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001G":{
      "id": "X3001G",
      "num": 14,
      "type":2,
      "question": "Number of Employees who Manage Inventory",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "I1010H":{
      "id": "I1010H",
      "num": 10,
      "type":2,
      "question": "Number of Shipping / Receiving / Delivery Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
    },
    "X3001H":{
      "id": "X3001H",
      "num": 17,
      "type":2,
      "question": "Average Hourly Wage of an Administrative/Job Ticket Developer/CSR and Mail Piece Design and Development Employee",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[18.00]
    },
    "X3001I":{
      "id": "X3001I",
      "num": 17,
      "type":2,
      "question": "Average Hourly Wage of an Insertion Employee",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[17.00]
    },
    "X3001J":{
      "id": "X3001J",
      "num": 19,
      "type":2,
      "question": "What is your Burden Rate Percentage for Employee Benefits?",
      "caption": "#",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3001K":{
      "id": "X3001K",
      "num": 14,
      "type":2,
      "question": "Total Employees in Administrative Roles (including customer service, management and sales)",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "enabled":[false],
      "defaults":[]
    },

    "X3001L":{
      "id": "X3001L",
      "num": 19,
      "type":2,
      "question": "Employees who process files and prepare mailing lists for production processing",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "enabled":[false],
      "cols":[],
      "defaults":[]
    },



    "X3002":{
      "id": "X3002",
      "num": 24,
      "type":3,
      "question": "Products and Services Include ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3002A":{
      "id": "X3002A",
      "num":29 ,
      "type":5,
      "question": "Do you provide printing services for the mailings that you process?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },




    "X3003":{
      "id": "X3003",
      "num":37 ,
      "type":3,
      "question": "USPS Postal Promotions that are utilized include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },

    "X3003A":{
      "id": "X3003A",
      "num": 1,
      "type":2,
      "question": "What volume of mail could be processed taking advantage of USPS Postal Promotions?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3003B":{
      "id": "X3003B",
      "num": 19,
      "type":2,
      "question": "What % of a discount could be gained with USPS Postal Promotions?",
      "caption": "#",
      "dataFormat":"%",
      "hint": "Generally 3-6%",
      "cols":[],
      "defaults":[]
    },
    "X3004":{
      "id": "X3004",
      "num":37 ,
      "type":3,
      "question": "Organizational software includes:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3005":{
      "id": "X3005",
      "num":37 ,
      "type":3,
      "question": "Equipment includes:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3006":{
      "id": "X3006",
      "num":37 ,
      "type":3,
      "question": "The main customers of the mail operation include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3021":{
      "id": "X3021",
      "num": 23,
      "type":8,
      "question": "The design of the mail piece is completed by...",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house", "Customer", "Other"]

    },
    "X3033":{
      "id": "X3033",
      "num": 1,
      "type":2,
      "question": "Turnaround time/SLAs are generally how many days?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3034":{
      "id": "X3034",
      "num": 1,
      "type":3,
      "question": "If days of operation fall on a holiday, the expectations for turnaround times are:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3035":{
      "id": "X3035",
      "num": 1,
      "type":3,
      "question": "Peak periods for processing include ...",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3046":{
      "id": "X3046",
      "num": 23,
      "type":8,
      "question": "The following types of postage meters are used:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Metered - IBI", "Metered - IMI (Intelligent Mail Indicia)", "Metered - IBIP (Information Based Indicia)","Other"]

    },
    "X3047":{
      "id": "X3047",
      "num": 23,
      "type":8,
      "question": "The following classes of mail are processed:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["First Class", "Marketing", "Periodicals","Packages","Priority Mail","Priority Mail Express","Non-Profit","Other"]

    },
    "X3048":{
      "id": "X3048",
      "num": 23,
      "type":8,
      "question": "Additional services required by customers include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Delivery confirmation", "Return receipt", "Certified mail processing","Registered mail","Business reply","Returned mail processing","Other"]

    },
    "X3049":{
      "id": "X3049",
      "num": 23,
      "type":8,
      "question": "The types of mail that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Letters", "Cards", "Flats","Parcels/Packages","Other"]

    },
    "X3050":{
      "id": "X3050",
      "num": 1,
      "type":2,
      "question": "How many mailing permits are managed by the organization?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3051":{
      "id": "X3051",
      "num": 23,
      "type":8,
      "question": "The types of mail that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Presort First Class", "Marketing Mail", "Bound Printed Matter","Non-Profit","Other"]

    },
    "X3056":{
      "id": "X3056",
      "num":29 ,
      "type":5,
      "question": "Do you utilize a presort bureau?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3057":{
      "id": "X3057",
      "num": 23,
      "type":8,
      "question": "The following mailing services are utilized:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Every Door Direct", "USPS Informed Delivery","Other"]

    },

    "X3108":{
      "id": "X3108",
      "num":25 ,
      "type":3,
      "question": "The organization has the following disaster recovery program in place for documents processed by the print and/or mail operation:",
      "caption": "",
      "hint": "(include the procedures to follow in the event of a disaster)",
      "cols":[],
      "defaults":[]
    },


    "X3007":{
      "id": "X3007",
      "num": 1,
      "type":2,
      "question": "Number of times mail is routed to the USPS each day:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3008":{
      "id": "X3008",
      "num":29 ,
      "type":5,
      "question": "Do you have a Mailer ID?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3009":{
      "id": "X3009",
      "num": 1,
      "type":3,
      "question": "Mailing addresses serviced include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },

    "X3010":{
      "id": "X3010",
      "num": 1,
      "type":2,
      "question": "The value of postage processed by the organization is estimated at:",
      "caption": "$",
      "dataFormat":"$",

      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3011":{
      "id": "X3011",
      "num": 23,
      "type":8,
      "question": "The classifications of mail include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Certified", "Priority",	"First-Class",	"Periodicals",	"Marketing Mail",	"Packages", "Non-Profit","Other"]

    },

    "X3012":{
      "id": "X3012",
      "num":29 ,
      "type":5,
      "question": "Is First Class Mail pre-sorted?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3012A":{
      "id": "X3012A",
      "num":29 ,
      "type":5,
      "question": "Do you use a pre-sort vendor for processing your mail?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3012B":{
      "id": "X3012B",
      "num": 1,
      "type":2,
      "question": "What does the pre-sort vendor charge per record for cleansing your mailing lists?",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3012C":{
      "id": "X3012C",
      "num": 1,
      "type":2,
      "question": "How many records per year does your pre-sort vendor process for cleansing?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3012D":{
      "id": "X3012D",
      "num": 1,
      "type":2,
      "question": "What is the estimated yearly cost for the pre-sort vendor to process your mail?",
      "caption": "$",
      "dataFormat":"$",
      "hint": "(not including the cost to cleanse mailing lists)",
      "cols":[],
      "defaults":[]
    },
    "X3012E":{
      "id": "X3012E",
      "num": 1,
      "type":2,
      "question": "Estimated hours it will take your operation to process mail in a year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "(job intake, file processing/routing and inserting)",
      "cols":[],
      "defaults":[]
    },

    "X3013":{
      "id": "X3013",
      "num": 23,
      "type":8,
      "question": "Postage is applied through:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Stamps Meter","Indicia/Postal Permit", "Permit","Other"]

    },
    "X3014A":{
      "id": "X3014A",
      "num": 1,
      "type":2,
      "question": "The average cost of Certified Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014B":{
      "id": "X3014B",
      "num": 1,
      "type":2,
      "question": "The average cost of Priority Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014C":{
      "id": "X3014C",
      "num": 1,
      "type":2,
      "question": "The average cost of First-Class Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014D":{
      "id": "X3014D",
      "num": 1,
      "type":2,
      "question": "The average cost of Periodical postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014E":{
      "id": "X3014E",
      "num": 1,
      "type":2,
      "question": "The average cost of Marketing Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014F":{
      "id": "X3014F",
      "num": 1,
      "type":2,
      "question": "The average cost of Packages postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014G":{
      "id": "X3014G",
      "num": 1,
      "type":2,
      "question": "The average cost of Non-Profit postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3015":{
      "id": "X3015",
      "num":29 ,
      "type":5,
      "question": "Are postal reports accessed through a Postal1 account?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3018":{
      "id": "X3018",
      "num": 23,
      "type":8,
      "question": "The following types of mail pieces are processed:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["#10 Envelopes",	"6 x 9 Flats",	"9 x 12 Flats",	"Direct Mail Post Cards","Other"]

    },

    "X3019A":{
      "id": "X3019A",
      "num": 1,
      "type":3,
      "question": "Low-end weight of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3019B":{
      "id": "X3019B",
      "num": 1,
      "type":3,
      "question": "High-end weight of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3020A":{
      "id": "X3020A",
      "num": 1,
      "type":3,
      "question": "Low-end thickness of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3020B":{
      "id": "X3020B",
      "num": 1,
      "type":3,
      "question": "High-end thickness of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3023":{
      "id": "X3023",
      "num": 23,
      "type":8,
      "question": "Mail optimization processes include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Address Optimization","Pre-sort",	"Bar Code Development","Other"]

    },

    "I1003":{
      "id": "I1003",
      "num": 3,
      "type":2,
      "question": "Total Number of Mail Pieces per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1157.final"}
    },

    "X3002B":{
      "id": "X3002B",
      "num": 1,
      "type":2,
      "question": "What is the volume of b/w 8.5 x 11 pages produced per year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3002C":{
      "id": "X3002C",
      "num": 1,
      "type":2,
      "question": "What is the volume of color 8.5 x 11 pages produced per year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3063":{
      "id": "X3063",
      "num": 1,
      "type":2,
      "question": "What is the average volume of envelopes per day?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3064":{
      "id": "X3064",
      "num": 1,
      "type":2,
      "question": "What is the maximum volume of envelopes per day?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065A":{
      "id": "X3065A",
      "num": 1,
      "type":2,
      "question": "The total volume of envelopes processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065B":{
      "id": "X3065B",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each mail piece is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065C":{
      "id": "X3065C",
      "num": 1,
      "type":2,
      "question": "The total volume of flats processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065D":{
      "id": "X3065D",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each flat is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065E":{
      "id": "X3065E",
      "num": 1,
      "type":2,
      "question": "The total volume of cards processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065F":{
      "id": "X3065F",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each card is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065G":{
      "id": "X3065G",
      "num": 1,
      "type":2,
      "question": "The total volume of parcels/packages processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065H":{
      "id": "X3065H",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each parcel/package is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3065":{
      "id": "X3065",
      "num": 1,
      "type":2,
      "question": "The total mailing pieces processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "X3065A + X3065C + X3065E + X3065G",
      "enabled": [false],
      "cols":[],
      "defaults":[]
    },

    "X3016":{
      "id": "X3016",
      "num": 24,
      "type":3,
      "question": "Orders are submitted through ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3017":{
      "id": "X3017",
      "num": 24,
      "type":3,
      "question": "Job tickets are developed by entering information received in ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3022":{
      "id": "X3022",
      "num": 23,
      "type":8,
      "question": "Personalization and Programming of the Conditional Logic is processed by:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house","Customer","Other"]

    },

    "X3022A":{
      "id": "X3022A",
      "num":29 ,
      "type":5,
      "question": "Is there at least one staff member certified by the USPS as a mail design professional?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3024":{
      "id": "X3024",
      "num": 24,
      "type":8,
      "question": "The following mailing list services are provided:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["NCOA", "CASS Certification", "PAVE",	"DPV",	"LACSLink",	"SUITELink",	"eLOT",	"RDI Service",	"PCOA",	"Death",	"Incarceration", "Other",]
    },
    "X3025":{
      "id": "X3025",
      "num":29 ,
      "type":5,
      "question": "Are you running any address resolution services?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3026":{
      "id": "X3026",
      "num": 23,
      "type":5,
      "question": "Address resolution is provided in the following frequency:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["by job","monthly","quarterly","every 6 months","yearly","Other"]

    },
    "X3027":{
      "id": "X3027",
      "num": 23,
      "type":8,
      "question": "The following software is used to provide address resolution:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["BCC",	"Lorton","Accuzip","Other"]

    },
    "X3028":{
      "id": "X3028",
      "num": 23,
      "type":8,
      "question": "The file types that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["PDF",	"Flat", "Text",	"XML",	"JSON",	"Postscript",	"PCL",	"CSV",	"IPDS","Other"]

    },
    "X3029":{
      "id": "X3029",
      "num": 23,
      "type":8,
      "question": "The origin of the mailing list file is?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Internal","Customer supplied","Sourced","Other"]

    },
    "X3030":{
      "id": "X3030",
      "num": 1,
      "type":2,
      "question": "The number of files that are processed monthly?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3031":{
      "id": "X3031",
      "num": 1,
      "type":2,
      "question": "The number of records/images/pages that are processed monthly?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3032":{
      "id": "X3032",
      "num": 1,
      "type":2,
      "question": "The highest volume of records/images/pages that is processed in one day is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3036":{
      "id": "X3036",
      "num": 23,
      "type":8,
      "question": "In addition to USPS mailing, other methods of communication that are processed by this organization include?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Email",	"Text",	"Online/Web","Other"]

    },
    "X3037":{
      "id": "X3037",
      "num": 23,
      "type":8,
      "question": "Customer communication and/or billing preferences are managed by:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house",	"Customer",	"Other"]

    },

    "X3038":{
      "id": "X3038",
      "num": 24,
      "type":3,
      "question": "The system in place for customers to indicate their preference of communications includes ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3039":{
      "id": "X3039",
      "num": 23,
      "type":8,
      "question": "The following methods of communication can be selected by customers:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Mail", "Email",	"Text Messaging", "Payment Methods - Credit Card, Direct Bill, Electronic Funds Transfer (EFT)",	"Other"]

    },
    "X3040":{
      "id": "X3040",
      "num": 23,
      "type":8,
      "question": "Communication content that customers can opt in for include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Events and webinars",	"Industry-related trends and thought leadership",	"New product information",	"Promotions","None","Other"]
    },
    "X3041":{
      "id": "X3041",
      "num":29 ,
      "type":5,
      "question": "Do your print projects require approvals?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3041A":{
      "id": "X3041A",
      "num":29 ,
      "type":3,
      "question": "Please explain the process for approvals:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
    },
    "X3052":{
      "id": "X3052",
      "num": 23,
      "type":8,
      "question": "Mail is processed in the following classifications:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Automation",	"Machinable",	"Nonmachinable","Other"]
    },
    "X3053":{
      "id": "X3053",
      "num": 23,
      "type":8,
      "question": "The types of barcodes incorporated into mailings include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Address block barcodes",	"iMB barcodes","Other"]
    },
    "X3054":{
      "id": "X3054",
      "num": 23,
      "type":5,
      "question": "How are barcodes added to mailing pieces?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["printed",	"labeled"]
    },

    "X3058":{
      "id": "X3058",
      "num":29 ,
      "type":5,
      "question": "Do you present an electronic manifest when presenting mail to the post office?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3066":{
      "id": "X3066",
      "num": 24,
      "type":2,
      "question": "How many types of outer envelopes are used?",
      "caption": "#",
      "dataFormat": "#",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3067":{
      "id": "X3067",
      "num": 24,
      "type":3,
      "question": "List the sizes of envelopes used...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3068":{
      "id": "X3068",
      "num": 24,
      "type":3,
      "question": "What would be required to reduce the number of Outer Envelopes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3069":{
      "id": "X3069",
      "num": 1,
      "type":2,
      "question": "What is the average number of pieces in a mailing?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3070":{
      "id": "X3070",
      "num": 23,
      "type":8,
      "question": "The substrates that are printed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["White Paper",	"Preprinted Forms","Other"]
    },
    "X3071":{
      "id": "X3071",
      "num":29 ,
      "type":5,
      "question": "Do you comingle several customer jobs into one mail stream or do you comingle one customers mailings with other customer mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3072":{
      "id": "X3072",
      "num":29 ,
      "type":5,
      "question": "Do you provide householding services for mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3073":{
      "id": "X3073",
      "num":29 ,
      "type":5,
      "question": "Are inserts included in customer mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3074":{
      "id": "X3074",
      "num": 24,
      "type":3,
      "question": "How many inserts can you accommodate on your inserters?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3075":{
      "id": "X3075",
      "num":29 ,
      "type":5,
      "question": "Can the inserts be selective (different inserts for each envelope's contents?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3076":{
      "id": "X3076",
      "num":29 ,
      "type":5,
      "question": "Is it a requirement to pull records from the data that needs to be handled separately from the mailing?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3077":{
      "id": "X3077",
      "num": 24,
      "type":3,
      "question": "The process for handling the pulling of records within a mailing includes...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3078":{
      "id": "X3078",
      "num": 19,
      "type":2,
      "question": "What % of work is hand inserted?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3079":{
      "id": "X3079",
      "num": 24,
      "type":3,
      "question": "Hand inserting vs. machine insertion is required due to...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3080":{
      "id": "X3080",
      "num":29 ,
      "type":5,
      "question": "Are you currently outsourcing mail processing due to capacity constraints?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3081":{
      "id": "X3081",
      "num": 19,
      "type":2,
      "question": "What percent of mail processing is outsourced due to capacity constraints?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3081A":{
      "id": "X3081A",
      "num":29 ,
      "type":5,
      "question": "Is the productivity of employees tracked?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3081B":{
      "id": "X3081B",
      "num": 19,
      "type":2,
      "question": "What is the current % of productive hours for production employees?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3082":{
      "id": "X3082",
      "num": 23,
      "type":8,
      "question": "Please indicate areas where there are documented Standard Operating Procedures for the operation:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Job intake/ticketing",	"Mail piece design",	"Prep/mailing list processing",	"Inserter production",	"Delivery and distribution","Other"]
    },
    "X3083":{
      "id": "X3083",
      "num": 24,
      "type":3,
      "question": "Job workflow and the software used in processing projects includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3084":{
      "id": "X3084",
      "num": 24,
      "type":3,
      "question": "The process for reprinting mail pieces that were damaged in the production process includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3085":{
      "id": "X3085",
      "num": 24,
      "type":3,
      "question": "The process for managing reprints for mailings processed by a presort bureau includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3092":{
      "id": "X3092",
      "num": 24,
      "type":3,
      "question": "The following software is used to manage inventory:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3093":{
      "id": "X3093",
      "num": 24,
      "type":3,
      "question": "The following types, sizes and estimated qty. of envelopes are held in inventory:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3094":{
      "id": "X3094",
      "num":29 ,
      "type":5,
      "question": "Are there automated notifications for triggers or alerts for stocking levels, reorder requirements or low inventory?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3095":{
      "id": "X3095",
      "num": 24,
      "type":3,
      "question": "Quality control procedure for monitoring mail processing includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3096":{
      "id": "X3096",
      "num": 24,
      "type":3,
      "question": "Compliance requirements for tracking mail include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3097":{
      "id": "X3097",
      "num": 24,
      "type":3,
      "question": "Software and methods for scheduling jobs include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3098":{
      "id": "X3098",
      "num": 24,
      "type":3,
      "question": "Production status reports are provided by:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3099":{
      "id": "X3099",
      "num":29 ,
      "type":5,
      "question": "Is dynamic scheduling provided through the software solution managing production processes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3100":{
      "id": "X3100",
      "num": 23,
      "type":8,
      "question": "Customers submit jobs through the following methods:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Email",	"Ecommerce/Storefront",	"Homegrown Job Intake System",	"Phone",	"In Person","Other"]
    },
    "X3101":{
      "id": "X3101",
      "num": 24,
      "type":3,
      "question": "The following reports are used to manage the production and financial aspects of the operation:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3102":{
      "id": "X3102",
      "num": 24,
      "type":3,
      "question": "Other reports that would be beneficial in or to monitor  production and report required data back to customers include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3103":{
      "id": "X3103",
      "num": 24,
      "type":3,
      "question": "The following preventative maintenance is performed by the operation's employees:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3104":{
      "id": "X3104",
      "num": 24,
      "type":3,
      "question": "The process for vendor provided equipment maintenance includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3105":{
      "id": "X3105",
      "num": 24,
      "type":3,
      "question": "The process for onboarding new customers includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3106":{
      "id": "X3106",
      "num": 24,
      "type":3,
      "question": "The process for onboarding new employees includes :",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3107":{
      "id": "X3107",
      "num": 24,
      "type":3,
      "question": "The following training and cross-training procedures are provided to employees:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3109":{
      "id": "X3109",
      "num": 1,
      "type":4,
      "question": "How much time is spent designing mail pieces in one month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3110":{
      "id": "X3110",
      "num": 1,
      "type":4,
      "question": "How much time is spent receiving orders and creating job tickets in one month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3111":{
      "id": "X3111",
      "num": 1,
      "type":4,
      "question": "How much time is spent cleansing mailing lists per month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3112":{
      "id": "X3112",
      "num": 1,
      "type":4,
      "question": "How much time is spent routing mailing files per month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },

    "X3042":{
      "id": "X3042",
      "num": 23,
      "type":8,
      "question": "Compliance controls are required to be in place for the following:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Regulatory compliance",	"HIPAA/PII","Other"]
    },
    "X3042A":{
      "id": "X3042A",
      "num": 1,
      "type":2,
      "question": "How many times has the organization been fined for non-compliance/regulatory issues in the past year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3042B":{
      "id": "X3042B",
      "num": 1,
      "type":2,
      "question": "What was the total value of fines for non-compliance?",
      "caption": "$",
      "dataFormat":"$",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3043B":{
      "id": "X3043B",
      "num": 19,
      "type":2,
      "question": "What % of the fines could be eliminated with the proposed solution?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3043":{
      "id": "X3043",
      "num": 24,
      "type":3,
      "question": "Please explain the requirements to ensure compliance.",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3044":{
      "id": "X3044",
      "num":29 ,
      "type":5,
      "question": "Do you require closed loop reporting to be ingested into another system?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3044B":{
      "id": "X3044B",
      "num":29 ,
      "type":3,
      "question": "What System is the mail data imported into?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3045":{
      "id": "X3045",
      "num":29 ,
      "type":5,
      "question": "Is data required to be deleted from your system?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3045B":{
      "id": "X3045B",
      "num":29 ,
      "type":2,
      "question": "How many days after a project is completed does data need to be deleted from your system?",
      "caption": "#",
      "dataFormat":"#",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3045A":{
      "id": "X3045A",
      "num": 24,
      "type":3,
      "question": "The following data needs to be deleted from our system:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3055A":{
      "id": "X3055A",
      "num": 23,
      "type":7,
      "question": "Presort first class mailings are processed with the following classifications and percentages:",
      "caption": "",
      "hint": "Each column must add up to 100%",
      "cols":[],
      "defaults":[],

    },

    "X3055A1":{
      "id": "X3055A1",
      "num": 19,
      "type":4,
      "question": "5-Digit - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A2":{
      "id": "X3055A2",
      "num": 19,
      "type":4,
      "question": "AADC - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A3":{
      "id": "X3055A3",
      "num": 19,
      "type":4,
      "question": "Mixed AADC - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A4":{
      "id": "X3055A4",
      "num": 19,
      "type":4,
      "question": "AADC - Nonautomation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A5":{
      "id": "X3055A5",
      "num": 19,
      "type":4,
      "question": "Mixed AADC - Nonautomation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A6":{
      "id": "X3055A6",
      "num": 19,
      "type":4,
      "question": "5-Digit Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A7":{
      "id": "X3055A7",
      "num": 19,
      "type":4,
      "question": "3-Digit Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A8":{
      "id": "X3055A8",
      "num": 19,
      "type":4,
      "question": "Mixed ADC Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A9":{
      "id": "X3055A9",
      "num": 19,
      "type":4,
      "question": "Meter",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A10":{
      "id": "X3055A10",
      "num": 19,
      "type":4,
      "question": "Stamp",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055B":{
      "id": "X3055B",
      "num": 23,
      "type":7,
      "question": "Current Postage Rates for different Classifications:",
      "caption": "",
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[],
    },

    "X3055B1":{
      "id": "X3055B1",
      "num": 19,
      "type":2,
      "question": "5-Digit - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.498]
    },
    "X3055B2":{
      "id": "X3055B2",
      "num": 19,
      "type":2,
      "question": "AADC - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.537]
    },
    "X3055B3":{
      "id": "X3055B3",
      "num": 19,
      "type":2,
      "question": "Mixed AADC - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.561]
    },
    "X3055B4":{
      "id": "X3055B4",
      "num": 19,
      "type":2,
      "question": "AADC - Nonautomation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.543]
    },
    "X3055B5":{
      "id": "X3055B5",
      "num": 19,
      "type":2,
      "question": "Mixed AADC - Nonautomation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.576]
    },
    "X3055B6":{
      "id": "X3055B6",
      "num": 19,
      "type":2,
      "question": "5-Digit Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.666]
    },
    "X3055B7":{
      "id": "X3055B7",
      "num": 19,
      "type":2,
      "question": "3-Digit Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.773]
    },
    "X3055B8":{
      "id": "X3055B8",
      "num": 19,
      "type":2,
      "question": "Mixed ADC Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.893]
    },
    "X3055B9":{
      "id": "X3055B9",
      "num": 19,
      "type":2,
      "question": "Meter",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.63]
    },
    "X3055B10":{
      "id": "X3055B10",
      "num": 19,
      "type":2,
      "question": "Stamp",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.66]
    },

    "X3059":{
      "id": "X3059",
      "num": 24,
      "type":3,
      "question": "The process for billing includes ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3060":{
      "id": "X3060",
      "num":29 ,
      "type":5,
      "question": "Do you bill back to account codes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3060A":{
      "id": "X3060A",
      "num": 24,
      "type":3,
      "question": "What is the format of the characters for account codes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3061":{
      "id": "X3061",
      "num": 24,
      "type":3,
      "question": "Postage costs that are processed on meters is billed back to customers by ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3062":{
      "id": "X3062",
      "num":29 ,
      "type":5,
      "question": "Are you billing back actual postage costs to customers?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3062A":{
      "id": "X3062A",
      "num": 24,
      "type":3,
      "question": "The barrier to billing back actual postage costs is  ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3086":{
      "id": "X3086",
      "num": 19,
      "type":2,
      "question": "What % of mailing pieces processed are returned?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3087":{
      "id": "X3087",
      "num": 1,
      "type":2,
      "question": "What is the monthly volume (# of mail pieces) that are Returned Mail?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3088":{
      "id": "X3088",
      "num": 1,
      "type":2,
      "question": "The average postage cost (per piece) for returned mail is estimated at...",
      "caption": "$",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3089":{
      "id": "X3089",
      "num": 1,
      "type":2,
      "question": "The average print/material costs (per piece) for the envelope and contents for each piece of returned mail is estimated at (include cost of envelope and all contents including inserts)...",
      "caption": "$",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090":{
      "id": "X3090",
      "num": 1,
      "type":2,
      "question": "The administrative time for processing returned mail on a monthly basis is estimated at...",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090A":{
      "id": "X3090A",
      "num": 19,
      "type":2,
      "question": "What % of returned mail is reprinted?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090B":{
      "id": "X3090B",
      "num": 19,
      "type":2,
      "question": "What % of returned mail is resent to recipients",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090C":{
      "id": "X3090C",
      "num": 1,
      "type":2,
      "question": "How many times do you need to resend mail in order to reach recipients?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090D":{
      "id": "X3090D",
      "num": 19,
      "type":2,
      "question": "What is the estimated % of returned mail could be reduced by cleansing mailing lists",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090E":{
      "id": "X3090E",
      "num": 1,
      "type":2,
      "question": "The administrative time to update the CRM to fix bad addresses on a monthly basis?",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3091":{
      "id": "X3091",
      "num": 23,
      "type":8,
      "question": "Returned mail is processed within the operation by:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["returning to the lines of business/customer for processing",	"entering the returned mail information into a tracking system and reporting to originator","reviewing the address in the originating system and updating to ensure addresses are correct for the next mailing","secure destruction","Other"]
    }
  }
}















