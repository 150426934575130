<template>
  <div class="question-form pb-16">
      <h2 class="font-bold text-3xl md:text-4xl mb-4">Tell us a little bit about your print business.</h2>
      <p class="text-xl mb-4">This form was generated by {{ q.admin.firstname }} {{ q.admin.last }} from Ricoh Consulting Services. If you need any assistance regarding this form, feel free to forward your questions to {{ q.admin.email }}. For your security, this form will expire in 14 days.</p>
      <p class="text-xl mb-4">Please provide as much detail as possible. It is not necessary to fill in all of the data but the more details that you provide will allow us to perform a more thorough analysis.</p>
      <p class="text-xl mb-12">For areas that are not applicable, please enter a number 0 or leave blank</p>

      <section>
        <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
          <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">1</span></div>
          <h2>Organization Profile</h2>
        </div>
        <div class="group">
            <div class="question">
              <label>Organization Name</label>
              <input type="text" v-model="q.customerProfile.organizationName" />
            </div>

          <div class="question">
            <label>Contact Name</label>
            <input type="text" v-model="q.customerProfile.customerContact" />
          </div>

          <div class="question">
            <label>Street Address</label>
            <input type="text" v-model="q.customerProfile.address" />
          </div>

          <div class="question flex">
            <div class="w-1/2 pr-4">
              <label>City</label>
              <input type="text" v-model="q.customerProfile.city" />
            </div>
            <div class="w-1/2 pl-4">
              <label>State</label>
              <input type="text" v-model="q.customerProfile.state" />
            </div>

          </div>


          <div class="question row flex">
            <div class="w-1/2 pr-4">
              <label>Postal Code</label>
              <input type="text" v-model="q.customerProfile.postalcode" />
            </div>
            <div class="w-1/2 pl-4">
              <label>Country</label>
              <input type="text" v-model="q.customerProfile.country" />
            </div>

          </div>

        </div>

      </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">2</span></div>
        <h2>Organization Statistics</h2>
      </div>
      <div class="group">
        <div class="question right-input">
          <label>Annual Sales</label>
          <number-input format="$" precision="0" v-model="q.interview.I1001.final"></number-input>
        </div>

        <div class="question right-input">
          <label>Current Print $s Outsourced to Commercial Print Companies</label>
          <number-input format="$" precision="0" v-model="q.interview.I1002.final"></number-input>
        </div>

        <div class="question right-input border-b pb-6 mb-4">
          <label>Total Number of Print Jobs per Year</label>
          <number-input precision="0" v-model="q.interview.I1003.final"></number-input>
        </div>


        <div class="question">
          <label># of Impressions for Offset Print per Year:</label>
          <div class="row flex">
            <div class="w-48 pr-4">
              <span class="text-lg text-gray-500">Color</span>
              <number-input precision="0" v-model="q.interview.I1004.final"></number-input>
            </div>
            <div class="w-48 pl-4">
              <span class="text-lg text-gray-500">Monochrome</span>
              <number-input precision="0" v-model="q.interview.I1005.final"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label># of Impressions for Digital Print per Year:</label>
          <div class="row flex">
            <div class="w-48 pr-4">
              <span class="text-lg text-gray-500">Color</span>
              <number-input precision="0" v-model="q.interview.I1006.final"></number-input>
            </div>
            <div class="w-48 pl-4">
              <span class="text-lg text-gray-500">Monochrome</span>
              <number-input precision="0" v-model="q.interview.I1007.final"></number-input>
            </div>
          </div>
        </div>

        <div class="question single-line">
          <label>Square Feet of Wide Format Materials Produced per Year:</label>
          <number-input precision="0" v-model="q.interview.I1007A.final"></number-input>
        </div>

        <div class="question">
          <label>Main products and services include:</label>
          <textarea v-model="q.interview.I1024.notes"/>
        </div>

        <div class="question">
          <label>Top 5 customers are:</label>
          <textarea v-model="q.interview.I1025.notes"/>
        </div>

        <div class="question">
          <label>Digital production printing equipment includes:</label>
          <textarea v-model="q.interview.I1025A.notes"></textarea>
        </div>
        <div class="question">
          <label>Offset production printing equipment includes:</label>
          <textarea v-model="q.interview.I1025B.notes"></textarea>
        </div>
        <div class="question">
          <label>Finishing equipment includes:</label>
          <textarea v-model="q.interview.I1025C.notes"></textarea>
        </div>
        <div class="question">
          <label>Software includes:</label>
          <textarea v-model="q.interview.I1025E.notes"></textarea>
        </div>

        <div class="question right-input">
          <label>Monthly Volume for Transactional Print<br>(Bills, Statements, EOBs, etc.)</label>
          <input type="text" v-model="q.interview.I1009.final" />
        </div>



      </div>

    </section>
    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">3</span></div>
        <h2>Employees <small class="text-base tracking-wide">  (Account for All Employees in the Following Categories)</small></h2>
      </div>
      <div class="group">
        <div class="question right-input">
          <label>Number of Management Employees</label>
          <number-input precision="2" v-model="q.interview.I1010A.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Estimators</label>
          <number-input precision="2" v-model="q.interview.I1011.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Administrative Employees/Job Ticket Developers/CSRs</label>
          <number-input precision="2" v-model="q.interview.I1011A.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Copyright Clearance Employees</label>
          <number-input precision="2" v-model="q.interview.I1010C.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Pre-Press Employees</label>
          <number-input precision="2" v-model="q.interview.I1010D.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Production Print Employees</label>
          <number-input precision="2" v-model="q.interview.I1010E.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Bindery/Finishing Employees</label>
          <number-input precision="2" v-model="q.interview.I1010F.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Fullfillment/Kitting Employees</label>
          <number-input precision="2" v-model="q.interview.I1010G.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Number of Shipping/Receiving Employees</label>
          <number-input precision="2" v-model="q.interview.I1010H.final" ></number-input>
        </div>



        <div class="question right-input">
          <label>Number of Sales People</label>
          <number-input precision="2" v-model="q.interview.I1014.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>Annual Sales per Salesperson</label>
          <number-input v-model="q.interview.I1015.final" format="$"></number-input>
        </div>
        <div class="question right-input">
          <label>Budgeted Hourly Rate for Graphic Designers</label>
          <number-input precision="2" v-model="q.interview.I1016.final" format="$"></number-input>
        </div>
        <div class="question right-input">
          <label>Hourly Administration/Estimator/CSR Wage</label>
          <number-input precision="2" v-model="q.interview.I1017.final" format="$"></number-input>
        </div>
        <div class="question right-input">
          <label>Hourly Production Management/Supervisory Wage</label>
          <number-input precision="2" v-model="q.interview.I1018.final" format="$"></number-input>
        </div>
        <div class="question right-input">
          <label>What is your Burden Rate Percentage for Employee Benefits?</label>
          <number-input precision="2" v-model="q.interview.I1019.final" format="%"></number-input>
        </div>
        <div class="question right-input">
          <label>Overtime (Total Annual $$$'s)</label>
          <number-input v-model="q.interview.I1021.final" format="$"></number-input>
        </div>

      </div>

    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">4</span></div>
        <h2>Estimate Development</h2>
      </div>
      <div class="group">

        <div class="question">
          <label>How many Estimates are created Each Month for:</label>
          <div class="qcol3">
            <div class="qcol">
              <label>Offset Printing</label>
              <number-input v-model="q.interview.I1038.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Digital Printing</label>
              <number-input v-model="q.interview.I1039.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Large Format Printing</label>
              <number-input v-model="q.interview.I1040.Current"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label>How many Minutes does it take to Develop an Estimate for :</label>
          <div class="qcol3">
            <div class="qcol">
              <label>Offset Printing</label>
              <number-input v-model="q.interview.I1042.Current" precision="2"></number-input>
            </div>
            <div class="qcol">
              <label>Digital Printing</label>
              <number-input v-model="q.interview.I1043.Current" precision="2"></number-input>
            </div>
            <div class="qcol">
              <label>Large Format Printing</label>
              <number-input v-model="q.interview.I1044.Current" precision="2"></number-input>
            </div>
          </div>
        </div>
        <div class="question right-input">
          <label>What percentage of estimates created are converted into jobs?</label>
          <number-input v-model="q.interview.I1045.final" format="%" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many Quote Letters are produced each Month?</label>
          <number-input v-model="q.interview.I1046.Current"></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does it take to create a Quote Letter?</label>
          <number-input v-model="q.interview.I1047.Current" precision="2"></number-input>
        </div>

      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">5</span></div>
        <h2>Job Ticket Development - Qty. and Time</h2>
      </div>

      <h4 class="mt-6 mb-4 mx-8 font-bold text-2xl">Job Tickets that originate from an Online Storefront Order:</h4>
      <div class="group">
        <div class="question">
          <label>Job Tickets created each month from an Online Storefront Order - <br>where Print Production is Required</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1050.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1061.Current" precision="2"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label>Job Tickets created each month from an Online Storefront Order - <br>for Products that are in Inventory</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1051.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1062.Current" precision="2"></number-input>
            </div>
          </div>
        </div>

      </div>

      <h4 class="mt-8 mb-4 mx-8 font-bold text-2xl">Job Tickets that originate from Email, Phone, Mail:</h4>
      <div class="group">
        <div class="question">
          <label>Job Tickets created after an estimate was developed</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1053.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1063.Current" precision="2"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label>Job Tickets created where customer has specified all job specs</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1054.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1064.Current" precision="2"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label>Job Tickets created where customer provided minimal job specs - you determine the job specs</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1055.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1065.Current" precision="2"></number-input>
            </div>
          </div>
        </div>
        <div class="question">
          <label>Job Tickets created that are re-orders - have all of the same job specs as a previous job</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1056.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1066.Current" precision="2"></number-input>
            </div>
          </div>
        </div>

        <div class="question">
          <label>Job Tickets created for items that are just pulled from inventory</label>
          <div class="qcol2">
            <div class="qcol">
              <label>Qty. per Month</label>
              <number-input v-model="q.interview.I1057.Current"></number-input>
            </div>
            <div class="qcol">
              <label>Minutes to Produce Each Job Ticket</label>
              <number-input v-model="q.interview.I1067.Current" precision="2"></number-input>
            </div>
          </div>
        </div>



      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">6</span></div>
        <h2>File Prep / Automation</h2>
      </div>

      <div class="group">
        <div class="question right-input">
          <label>How many minutes does it take to Pre-Flight a file (Review Content, Add Bleeds, Make Minor Changes)</label>
          <number-input v-model="q.interview.V1010.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many minutes does it take to Impose a file</label>
          <number-input v-model="q.interview.V1011.Current" precision="2"></number-input>
        </div>

      </div>

      <h4 class="mt-8 mb-4 mx-8 font-bold text-2xl">Provide details for Variable Data/Personalization Projects:</h4>
      <div class="group">
        <div class="question">
          <label>What types of jobs contain Variable Data/Personalization?</label>
          <input type="text" v-model="q.interview.V1000.final" />
        </div>

        <div class="question right-input">
          <label>Number of Orders per Month for Variable Data Project</label>
          <number-input v-model="q.interview.V1001.final" precision="0"></number-input>
        </div>

        <div class="question right-input">
          <label>Minutes to Develop/Design Base Template</label>
          <number-input v-model="q.interview.V1002.Current" precision="2"></number-input>
        </div>

        <div class="question right-input">
          <label>Minutes to Customize Document with Variable Data</label>
          <number-input v-model="q.interview.V1004.Current" precision="2"></number-input>
        </div>

        <div class="question right-input">
          <label>Minutes required to send a Proof and Make Any Required Changes with Variable Data</label>
          <number-input v-model="q.interview.V1005.Current" precision="2"></number-input>
        </div>

        <div class="question right-input">
          <label>Minutes to Process file for production with Variable Data once approved</label>
          <number-input v-model="q.interview.V1006.Current" precision="2"></number-input>
        </div>



      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">7</span></div>
        <h2>Accounting</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>How many Minutes does it take to Track Job Labor per Employee per Day?</label>
          <number-input v-model="q.interview.I1127.Current" precision="2" ></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does it take to Create a PO?</label>
          <number-input v-model="q.interview.I1084.Current" precision="2" ></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does it take to Reconcile a PO?</label>
          <number-input v-model="q.interview.I1085.Current" precision="2" ></number-input>
        </div>
        <div class="question right-input">
          <label>How many POs do you Create per Month?</label>
          <number-input v-model="q.interview.I1086.Current" ></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does it take to Process an Invoice?</label>
          <number-input v-model="q.interview.I1087.Current" precision="2" ></number-input>
        </div>
        <div class="question right-input">
          <label>Comparing Internally Produced vs. External Purchased Printed Materials What is the % Cost Savings?</label>
          <number-input v-model="q.interview.I1089.Current" precision="2" format="%"></number-input>
        </div>
        <div class="question right-input">
          <label>How Many Days After Project Completion Do You Invoice Jobs?</label>
          <number-input v-model="q.interview.I1123.Current"  ></number-input>
        </div>
        <div class="question right-input">
          <label>Generally, How Many Days Does it Take to Receive Payment After Your Customers are Invoiced?</label>
          <number-input v-model="q.interview.I1124.Current"  ></number-input>
        </div>

        <div class="question right-input">
          <label>How many Minutes does it take to Log Billing Info into an Accounting System per Job?</label>
          <number-input v-model="q.interview.I1126.Current" precision="2" ></number-input>
        </div>
      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">8</span></div>
        <h2>Job Tracking & Scheduling</h2>
      </div>
      <div class="group">

        <div class="question right-input">
          <label>Do you charge for change orders?</label>
          <div class="w-48 flex items-center">
            <input class="h-6" type="radio" value="Yes" v-model="q.interview.I1095.final" /><label>YES</label>
            <input class="h-6" type="radio" value="No" v-model="q.interview.I1095.final"/><label>NO</label>
          </div>
        </div>
        <div class="question right-input">
          <label>Average Cost of Billable Changes per Job</label>
          <number-input v-model="q.interview.I1095A.final" format="$" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many Employees are involved in Tracking Jobs per Day?</label>
          <number-input v-model="q.interview.I1012.final" ></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does each Admin/CSR spend Tracking Jobs per Day</label>
          <number-input v-model="q.interview.I1096.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes does each Production Manager/Supervisor/Sales spend Tracking Jobs per Day</label>
          <number-input v-model="q.interview.I1097.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many Minutes per Day are Production Meetings</label>
          <number-input v-model="q.interview.I1098.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label># of Employees in Production Meetings</label>
          <number-input v-model="q.interview.I1099.Current"></number-input>
        </div>
        <div class="question right-input">
          <label>What % of jobs require UPS or FedEx tracking each month?</label>
          <number-input v-model="q.interview.I1114.Current" format="%" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many minutes does it take to track UPS or FedEx delivery information for a job?</label>
          <number-input v-model="q.interview.I1113.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>How many minutes does it take to create shipping documents for a job?</label>
          <number-input v-model="q.interview.I1115.Current" precision="2"></number-input>
        </div>
        <div class="question right-input">
          <label>What is the number of jobs where shipping costs are missed/not allocated to the job each month?</label>
          <number-input v-model="q.interview.I1116.Current" ></number-input>
        </div>
        <div class="question right-input">
          <label>What is the average cost of missed shipping charges per month?</label>
          <number-input format="$" precision="2" v-model="q.interview.I1117.Current" ></number-input>
        </div>
        <div class="question">
          <label>The process for pre-flighting files and prepping for production includes:</label>
          <textarea v-model="q.interview.I1100.notes"></textarea>
        </div>
        <div class="question">
          <label>The production area is notified of a project to plan their workload from:</label>
          <input type="text" v-model="q.interview.I1102.notes"/>
        </div>

      </div>
    </section>

    <section class="mt-12">
      <div class="flex items-center font-bold text-2xl md:text-3xl mb-4">
        <div class="flex items-center justify-center rounded-full bg-brand-blue-light text-white p-1 w-8 h-8 mr-2"><span class="font-mono font-bold text-xl">9</span></div>
        <h2>Inventory Management</h2>
      </div>
      <div class="group">
        <div class="question right-input">
          <label># of production print materials inventory items</label>
          <number-input v-model="q.interview.I1068.Current" ></number-input>
        </div>
        <div class="question right-input">
          <label># of finished goods/fulfillment inventory items</label>
          <number-input v-model="q.interview.I1069.Current" ></number-input>
        </div>
        <div class="question right-input">
          <label>Minutes spent each month managing each inventory item</label>
          <number-input v-model="q.interview.I1070.Current" precision="2"></number-input>
        </div>
      </div>
    </section>

    <div class="text-center py-12">
      <button class="text-xl font-bold button button-blue" @click="submitForm">SUBMIT QUESTIONNAIRE</button>
    </div>

  </div>
</template>

<script>
import NumberInput from "@/components/NumberInput";
import workflow from "@/schema/workflow";

export default {
  name: "question-form-workflow",
  components: {NumberInput},
  mounted: function(){
    this.$store.state.schema = workflow;
    this.$store.state.pageTitle = "Production Printing Workflow"
  },
  props: {},
  data: function () {
    return {

    }
  },
  methods: {
    submitForm: function(){
      this.$emit("submit");
    }
  },
  computed: {
    q: function(){
      return this.$store.state.q;
    }
  }
}
</script>

<style scoped>


.question-form {

}

textarea {
  @apply px-2 py-1 border border-gray-500 w-full block rounded text-lg font-bold font-input;
}

.group {
  @apply bg-gray-100 p-8 shadow;
}

.group label {
  @apply text-xl mb-2 block;
}

.question {
  @apply mb-4 pb-6 border-b;
}

.question:last-child {
  @apply mb-4 pb-0 border-none;
}


.row {

}

.right-input {
  @apply flex justify-between items-center;
}

.right-input label {
  @apply pr-4 mb-0;
}

.right-input input {
  @apply w-48;
}

.qcol3 {
  @apply flex -mx-4;
}

.qcol3 .qcol {
  @apply w-1/3 px-4;
}

.qcol3 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


.qcol2 {
  @apply flex;
}

.qcol2 .qcol {
  @apply mr-8;
}

.qcol2 .qcol input {
  @apply w-48;
}


.qcol2 .qcol label {
  @apply text-lg text-gray-500 mb-1;
}


</style>