


<template>
  <div class="relative h-full w-full min-h-screen">
    <header class="px-4 sm:px-12 py-4 flex justify-between items-center border-b">
      <img class="h-8 sm:h-8" src="../assets/ricoh-logo.svg" />
      <h1 class="sm:absolute sm:w-full sm:left-0 sm:text-center sm:text-xl font-bold tracking-wide" v-if="!q">RICOH CONSULTING SERVICES</h1>
      <h1 class="text-sm sm:text-lg lg:text-xl font-bold tracking-wide text-center" v-if="q">{{ pageTitle }}</h1>
      <div v-if="q">
        <button v-if="hasSubmitted" class="button button-blue font-bold tracking-wide text-sm sm:text-base" @click="reviewForm">REVIEW</button>
        <button v-else class="button button-blue font-bold tracking-wide text-sm sm:text-base" @click="submitForm">SUBMIT</button>
      </div>


    </header>
    <login v-if="!q"></login>
    <div v-else>
      <component :is="qForm" v-if="!hasSubmitted" class="w-full max-w-3xl mx-auto mt-8 px-8" @submit="submitForm"></component>
      <div v-if="hasSubmitted" class="w-full max-w-3xl mx-auto mt-8 px-8">
        <section>

          <h3 class="font-bold text-3xl md:text-4xl mb-4">Thank You!</h3>
          <div class="group bg-gray-100 shadow p-8">
          <p class="text-xl mb-4">Your information has been submitted. Your Ricoh representitive will review this questionnaire and contact you soon.</p>
          <button class="button button-blue text-xl font-bold" @click="reviewForm">Review Answers</button>
          </div>
        </section>
      </div>

    </div>



    <footer class="mt-8 w-full text-center absolute bottom-0 p-2 border-t bg-gray-50"><p>&copy;2024 Ricoh USA, Inc. All Rights Reserved.</p></footer>

  </div>
</template>

<script>
import Login from "@/views/Login";
import QuestionFormWorkflow from "@/views/QuestionFormWorkflow";
import QuestionFormCCM from "@/views/QuestionFormCCM";
import QuestionFormMail from "@/views/QuestionFormMail";
import QuestionFormMAP from "@/views/QuestionFormMAP";
import QuestionFormPMP from "@/views/QuestionFormPMP";


export default {
    name: "questionnaire",
  components: {QuestionFormPMP, QuestionFormMAP, QuestionFormCCM, QuestionFormMail, QuestionFormWorkflow, Login},
  mounted() {

    },
  props: {},
    data: function(){
        return {
          hasSubmitted: false,
          saving: false
        }
        },
    methods: {
      submitForm: async function(){

        let schema = this.$store.state.schema;

        let timestamp = Date.now();

        if(this.q){
          this.q.submitted = timestamp;
          this.q.id = this.$route.params.recid;

          let changeTable = [];

          if(schema){
            for(let qKey in this.q.interview){
              if(schema.questions[qKey]){
                let question = schema.questions[qKey].question;
                let responses = [];



                for (let prop in this.q.interview[qKey]){
                  if(this.q.interview[qKey][prop] !== ""){
                    responses.unshift(this.q.interview[qKey][prop]);
                  }
                }

                changeTable.push({
                  question:question,
                  responses: responses.join("--"),
                  id: qKey
                })
              }
            }
          }


          const url = "/api/updateRecord"
          const options = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(
                { questionnaire: this.q, changeTable: changeTable }
            )
          }

          this.saving = true;

          await fetch(url,options)
              .then(response => response.json())
              .then(data => {
                this.saving = false;
                this.hasSubmitted = true;
                console.log(data);
              })
              .catch((error) => {
                this.saving = false;
                alert("Something went wrong with this operation. Please try again.")
                console.error("Error", error);

              })


        }




      },


      reviewForm: function(){
        this.hasSubmitted = false;
      }
    },
    computed: {
      q: function(){
        return this.$store.state.q;
      },

      pageTitle: function(){
        return this.$store.state.pageTitle
      },

      qForm: function(){
        if(this.q){
          if(this.q.documentType === "Production Workflow Questionnaire"){
            return "QuestionFormWorkflow"
          }

          if(this.q.documentType === "CCM Questionnaire"){
            return "QuestionFormCCM"
          }

          if(this.q.documentType === "Mail Workflow Questionnaire"){
            return "QuestionFormMail"
          }

          if(this.q.documentType === "Marketing Automation Workflow Questionnaire"){
            return "QuestionFormMAP"
          }

          if(this.q.documentType === "Project Management Workflow Questionnaire"){
            return "QuestionFormPMP"
          }
        }

        return ""
      }
    }
}
</script>

<style scoped>


.questionnaire {

}

</style>