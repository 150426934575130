


<template>

  <input class="number-input text-right" type="text" ref="numberinput" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true" @change="$emit('change', value)" :placeholder="format" />

</template>

<script>
export default {
  name: "number-input",
  props: ['value','format','locale','precision',"int"],
  data: function(){
    return {
      isInputActive: false
    }
  },
  methods: {
  },
  computed: {



    decimals: function(){


      if(this.format == "0.0") {
        return 2.0;
      }

      if(this.precision){
        return this.precision
      }

      return 0;
    },

    currency: function(){
      if(this.format == "$"){
        return "USD"
      }

      return null;
    },

    isPercentage: function(){
      return (this.format == "%")
    },

    displayValue: {
      get: function() {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          if(this.value){
            return (this.value).toString()
          }else{
            return ""
          }

        } else {
          // User is not modifying now. Format display value for user interface
          if(parseInt(this.value) > -1){


            //
            //
            // let _num = (this.value).toFixed(this.decimals).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            let _num = parseFloat(parseFloat(this.value).toFixed(this.decimals)).toLocaleString('en', { minimumFractionDigits: this.decimals, maximumFractionDigits: this.decimals });
            if(this.int){
              _num = parseInt(_num);
            }
            if(this.currency){
              return "$"+_num;
            }else if(this.isPercentage){
              return _num+"%"
            }

            return _num;

          }else{
            return ""
          }
        }
      },
      set: function(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));

        if(this.int){
          newValue = parseInt(newValue);
        }
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped>


.percent-input {

}

</style>